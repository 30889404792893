import { Flex, Text } from "@chakra-ui/react";

import { AccordionHome } from "../components";
import { NewsletterSection } from "../components/newsletter-section";

export function HelpCenterPage() {
  return (
    <Flex
      flex={1}
      w="full"
      maxW="full"
      px={4}
      pt={{ base: 6, md: 10 }}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      gap={{ base: 6, md: 10 }}
    >
      <Flex
        w="full"
        maxW="768px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={3}
      >
        <Text
          fontSize={{ base: "14px", md: "16px" }}
          fontWeight="normal"
          color="brand.500"
        >
          FAQs
        </Text>
        <Text fontSize={{ base: "36px", md: "48px" }} fontWeight="600">
          Help Center
        </Text>
        <Text
          fontSize={{ base: "16px", md: "20px" }}
          fontWeight="normal"
          textAlign="center"
          color="gray.600"
        >
          Need something cleared up? Here are our most frequently asked
          questions.
        </Text>
      </Flex>
      <Flex
        as="section"
        w="full"
        py={{ base: 6, md: 10 }}
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={{ base: 6, md: 10 }}
      >
        <AccordionHome />
      </Flex>
      <NewsletterSection mt={40} />
    </Flex>
  );
}
