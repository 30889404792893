import { QueryClient } from "@tanstack/react-query";

import { PublicAPI } from "@/services";

export const getPublicServicesQuery = () => ({
  queryKey: ["public-services"],
  queryFn: PublicAPI.getPublicServices,
});

export const allServicesLoader = (queryClient: QueryClient) => async () => {
  const query = getPublicServicesQuery();

  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
