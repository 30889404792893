import { MutableRefObject } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Switch,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { MenteeAPI, successToast } from "@/services";
import { BackgroundType } from "@/types";

export function EditJobModal({
  initialRef,
  finalRef,
  background,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  background: BackgroundType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<MenteeAPI.EditBackgroundPayload["job"]>({
    defaultValues: background.job,
  });

  const handleSubmitBackground = handleSubmit(
    async ({ is_employed, ...rest }) => {
      try {
        const res = await MenteeAPI.editBackground({
          ...background,
          job: is_employed ? { is_employed, ...rest } : { is_employed },
        });
        if (res.status === 200) {
          successToast({
            title: `Your background job updated successfully!`,
          });
          onClose();

          await queryClient.invalidateQueries({
            queryKey: ["background"],
          });
        }
      } catch (error) {
        console.error({ error });
      }
    },
  );

  const { ref: helInputRef, ...helInputRegister } = register("is_employed");

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      // isCentered
      size={{ base: "full", sm: "md" }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmitBackground} noValidate>
        <ModalHeader>Edit Job Section</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <FormControl
            isRequired
            id="is_employed"
            isInvalid={Boolean(errors.is_employed)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormLabel htmlFor="is_employed" fontWeight="normal" mb={0}>
              Employed Status:
            </FormLabel>
            <Switch
              id="is_employed"
              isDisabled={isSubmitting}
              {...helInputRegister}
              ref={(e) => {
                helInputRef(e);

                // it need to support focus on first input
                if (e) {
                  initialRef.current = e;
                }
              }}
              colorScheme="brand"
            />
          </FormControl>
          <FormControl
            isRequired={watch("is_employed")}
            display={watch("is_employed") ? "initial" : "none"}
            id="title"
            isInvalid={Boolean(errors.title)}
          >
            <FormLabel htmlFor="title" fontWeight="normal">
              Job Title:
            </FormLabel>
            <Input
              placeholder="Software Engineer"
              isDisabled={isSubmitting}
              {...register("title", {
                validate: {
                  required: (value) => {
                    if (!value && getValues("is_employed"))
                      return "This field is required";
                    return true;
                  },
                },
              })}
            />
            {errors.title && (
              <FormErrorMessage>{errors.title.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired={watch("is_employed")}
            display={watch("is_employed") ? "initial" : "none"}
            id="current_employer"
            isInvalid={Boolean(errors.current_employer)}
          >
            <FormLabel htmlFor="current_employer" fontWeight="normal">
              Current Employer:
            </FormLabel>
            <Input
              placeholder="Google"
              isDisabled={isSubmitting}
              {...register("current_employer", {
                validate: {
                  required: (value) => {
                    if (!value && getValues("is_employed"))
                      return "This field is required";
                    return true;
                  },
                },
              })}
            />
            {errors.current_employer && (
              <FormErrorMessage>
                {errors.current_employer.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            colorScheme="brand"
            w="full"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
