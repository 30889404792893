import { LoaderFunctionArgs, redirect } from "react-router";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { PublicAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

export const getSinglePublicMentorQuery = (mentor_id: string) => ({
  queryKey: ["public-mentor", mentor_id],
  queryFn: () => PublicAPI.getSinglePublicMentor(mentor_id),
});

export const mentorPublicProfileLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      const mentorId = args.params.mentorId as string;

      if (!mentorId) {
        return redirect("/404");
      }

      await protectedLoader(args);

      const query = getSinglePublicMentorQuery(mentorId);

      const cachedData = queryClient.getQueryData(query.queryKey);

      if (cachedData) {
        return cachedData;
      }

      return await queryClient.fetchQuery(query);
    } catch (error) {
      captureNetworkError(error);
    }
  };
