import { useNavigate } from "react-router";
import {
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  useColorModeValue,
  Wrap,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { EmptyState, LoadingState } from "@/components";
import { notificationTypeMapping } from "@/constants/mappings";
import { NotificationAPI } from "@/services";
import { NotificationType } from "@/types";

import { getNotificationListQuery } from "./loading";
import { SingleNotification } from "./single-notification";

export function NotificationPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: initialData, isFetching } =
    useQuery<NotificationAPI.GetNotificationList>(getNotificationListQuery());

  const { mutateAsync: seenNotification } = useMutation({
    mutationKey: [`seen-notification`],
    mutationFn: (
      notification_id: Parameters<typeof NotificationAPI.seenNotification>[0],
    ) => NotificationAPI.seenNotification(notification_id),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["notifications"] }),
  });

  const handleNotification = async (notification: NotificationType) => {
    navigate(notificationTypeMapping()[notification.type].link);
    if (notification.status === "unread") {
      await seenNotification(notification.id);
    }
  };

  return (
    <Flex
      boxSize="full"
      px={{ base: 0, md: 8 }}
      py={{ base: 0, md: 10 }}
      direction="column"
      align="flex-start"
      justify="center"
      bg={useColorModeValue("white", "gray.900")}
      color={useColorModeValue("black", "gray.200")}
    >
      <Flex
        w="full"
        px={{ base: 8, md: 0 }}
        py={{ base: 4, md: 0 }}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        gap={6}
        display={{ base: "none", md: "block" }}
      >
        <Heading fontSize="3xl">Notifications</Heading>
      </Flex>
      <Tabs
        // defaultIndex={queryParams.archived ? 1 : 0}
        // onChange={(index) => setQueryParams({ archived: Boolean(index) })}
        boxSize="full"
        px={{ base: 8, md: 0 }}
        py={{ base: 3, md: 6 }}
      >
        <TabList fontWeight="bold" color="#717680" gap={5}>
          <Tab _selected={{ color: "brand.500" }} px={2}>
            New
          </Tab>
          <Tab isDisabled _selected={{ color: "brand.500" }} px={2}>
            Archive
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="brand.500"
          borderRadius="1px"
        />
        <TabPanels boxSize="full">
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : initialData?.length ? (
              <Wrap
                shouldWrapChildren
                direction={{ base: "column" }}
                spacing={6}
                py={10}
              >
                {initialData.map((notification) => (
                  <SingleNotification
                    key={notification.id}
                    notification={notification}
                    onClick={handleNotification}
                  />
                ))}
              </Wrap>
            ) : (
              <EmptyState name="notification" />
            )}
          </Flex>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
