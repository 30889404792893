import { LoaderFunctionArgs } from "react-router";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { SharedAPI } from "@/services";
import { GetApplicationsFilters } from "@/services/api/shared-api";
import { captureNetworkError } from "@/services/error-handler";

export const getApplicationsQuery = (filters?: GetApplicationsFilters) => ({
  queryKey: ["applications", filters],
  queryFn: () => SharedAPI.getApplications(filters),
});

export const applicationsLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const query = getApplicationsQuery({
        archived: false,
      });

      const res =
        queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query));

      return res;
    } catch (error) {
      captureNetworkError(error);
    }
  };
