import { LoaderFunctionArgs, redirect } from "react-router";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { PublicAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

export const getSingleMenteeQuery = (mentee_id: string) => ({
  queryKey: ["mentee", mentee_id],
  queryFn: () => PublicAPI.getSingleMentee(mentee_id),
});

export const menteeProfileLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      const menteeId = args.params.menteeId as string;

      if (!menteeId) {
        return redirect("/404");
      }

      await protectedLoader(args);

      const query = getSingleMenteeQuery(menteeId);

      const cachedData = queryClient.getQueryData(query.queryKey);

      if (cachedData) {
        return cachedData;
      }

      return await queryClient.fetchQuery(query);
    } catch (error) {
      captureNetworkError(error);
    }
  };
