import { ElementRef, useRef } from "react";
import { Flex, FlexProps, Text, useDisclosure } from "@chakra-ui/react";

import { Job } from "@/pages/dashboard/background/job";
import { EditJobModal } from "@/pages/dashboard/background/job/edit-job-modal";
import { BackgroundType } from "@/types";

interface ProfileJobExperienceProps extends Omit<FlexProps, "background"> {
  background: BackgroundType;
  type?: "own" | "mentor" | "mentee";
}

export function ProfileJobExperience({
  background,
  type = "own",
  ...rest
}: ProfileJobExperienceProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef<ElementRef<"input">>(null);

  const isOwnProfile = type === "own";

  return (
    <Flex
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Job experience
        </Text>
        <Text fontSize="14px" color="gray.600">
          {isOwnProfile
            ? "Update your job experience history"
            : `See the ${type}’s job experience history`}
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        <Job
          background={background}
          onClick={isOwnProfile ? onOpen : undefined}
          showTitle={false}
          border="none"
          p={0}
        />
        {/* <Flex alignItems="center" justify="space-between">
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Role / Company
          </FormLabel>
          <IconButton
            aria-label="1"
            icon={<FaPlus />}
            colorScheme="brand"
            borderRadius="full"
          />
        </Flex>
        <Flex direction="column" gap={4}>
          <Flex alignItems="flex-start" justify="space-between">
            <Flex direction="column" align="start" gap={0.5}>
              <Text fontSize="14px" fontWeight="medium" color="gray.900">
                Lead Data Scientist
              </Text>
              <Text fontSize="14px" fontWeight="normal" color="gray.600">
                Google
              </Text>
            </Flex>
            <IconButton
              aria-label="1"
              icon={<FaEllipsisV />}
              colorScheme="gray"
              bgColor="white"
              color="gray.300"
            />
          </Flex>
          <Flex alignItems="flex-start" justify="space-between">
            <Flex direction="column" align="start" gap={0.5}>
              <Text fontSize="14px" fontWeight="medium" color="gray.900">
                Sr. Data Scientist
              </Text>
              <Text fontSize="14px" fontWeight="normal" color="gray.600">
                Apple
              </Text>
            </Flex>
            <IconButton
              aria-label="1"
              icon={<FaEllipsisV />}
              colorScheme="gray"
              bgColor="white"
              color="gray.300"
            />
          </Flex>
          <Flex alignItems="flex-start" justify="space-between">
            <Flex direction="column" align="start" gap={0.5}>
              <Text fontSize="14px" fontWeight="medium" color="gray.900">
                Data Scientist
              </Text>
              <Text fontSize="14px" fontWeight="normal" color="gray.600">
                Pet Smart
              </Text>
            </Flex>
            <IconButton
              aria-label="1"
              icon={<FaEllipsisV />}
              colorScheme="gray"
              bgColor="white"
              color="gray.300"
            />
          </Flex>
        </Flex> */}
      </Flex>
      <EditJobModal
        key={+isOpen}
        initialRef={initialRef}
        background={background}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
}
