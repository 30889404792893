import { Flex, FlexProps, useColorModeValue } from "@chakra-ui/react";

import { ACTION_BAR_HEIGHT } from "@/constants/config";
import { useAuthStore } from "@/services";

import { MenteeLinkItems, MentorLinkItems } from "./config";
import { NavItem } from "./nav-item";

interface MobileActionBarProps extends FlexProps {}

export function MobileActionBar({ ...rest }: MobileActionBarProps) {
  const role = useAuthStore((state) => state.user?.role);

  return (
    <Flex
      w="full"
      position="fixed"
      h={ACTION_BAR_HEIGHT}
      zIndex={10}
      bottom={0}
      left={0}
      right={0}
      bg={useColorModeValue("white", "gray.900")}
      borderTopWidth={1}
      borderTopColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="space-between"
      {...rest}
    >
      {(role === "mentee" ? MenteeLinkItems : MentorLinkItems).map((item) => (
        <NavItem
          key={item.label}
          href={item.href}
          icon={item.icon}
          variant="mobile"
          isExternal={item.isExternal}
        >
          {item.label}
        </NavItem>
      ))}
    </Flex>
  );
}
