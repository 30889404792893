import {
  Button,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Edit, Trash } from "iconsax-react";

import { GoalType } from "@/types";
import { dateFormatter } from "@/utils/date-formatter";

interface SingleGoalProps extends FlexProps {
  index: number;
  goal: GoalType;
  onEdit?: (selected: GoalType) => void;
  onDelete?: (selected: GoalType) => void;
}

export function SingleGoal({
  index,
  goal,
  onEdit,
  onDelete,
  ...rest
}: SingleGoalProps) {
  return (
    <Flex
      w="full"
      bg={useColorModeValue("white", "gray.900")}
      rounded="md"
      p={6}
      overflow="hidden"
      borderWidth="1px"
      borderRadius="md"
      gap={4}
      direction="column"
      flexShrink={0}
      {...rest}
    >
      <HStack justify="space-between" align="center">
        <Text
          color="brand.500"
          textTransform="uppercase"
          fontWeight={800}
          fontSize="sm"
          letterSpacing={1.1}
        >
          Goal {index}
        </Text>
        {onEdit && onDelete && (
          <Flex gap={3}>
            <Button
              variant="ghost"
              colorScheme="gray"
              size="sm"
              onClick={() => onEdit(goal)}
            >
              <Edit size={20} />
            </Button>
            <Button
              variant="ghost"
              colorScheme="red"
              size="sm"
              onClick={() => onDelete(goal)}
            >
              <Trash size={20} />
            </Button>
          </Flex>
        )}
      </HStack>
      <Stack>
        <Heading
          color={useColorModeValue("gray.700", "white")}
          fontSize="2xl"
          fontFamily="body"
          textAlign="start"
        >
          {goal.title}
        </Heading>
        <Text color="gray.500" textAlign="start">
          {goal.description}
        </Text>
      </Stack>
      <Stack>
        <Text
          color={useColorModeValue("gray.700", "white")}
          fontFamily="body"
          textAlign="start"
        >
          Expectations:
        </Text>
        <Text color="gray.500" textAlign="start">
          {goal.expectations}
        </Text>
      </Stack>
      <Stack>
        <Text
          color={useColorModeValue("gray.700", "white")}
          fontFamily="body"
          textAlign="start"
        >
          Expected Timeline:
        </Text>
        <Text color="gray.500" textAlign="start">
          {goal.expected_timeline}
        </Text>
      </Stack>
      <Flex
        direction={{ base: "column", lg: "row" }}
        mt={6}
        gap={4}
        align={{ base: "flex-start", lg: "center" }}
        justify="space-between"
      >
        <Stack direction="column" spacing={0} fontSize="sm">
          <Text fontWeight={600} textAlign="start">
            Frequency of Meetings:
          </Text>
          <Text color="gray.500" textAlign="start">
            {goal.meeting_frequency}
          </Text>
        </Stack>
        <Stack direction="column" spacing={0} fontSize="sm">
          <Text fontWeight={600} textAlign="start">
            Focus Hours per Week:
          </Text>
          <Text color="gray.500" textAlign="start">
            {goal.focus_hpw}
          </Text>
        </Stack>
        {goal?.created_at && (
          <Stack direction="column" spacing={0} fontSize="sm">
            <Text fontWeight={600}>Created at: </Text>
            <Text color="gray.500">
              {dateFormatter(goal.created_at, {
                dateStyle: "medium",
                timeStyle: "short",
              })}
            </Text>
          </Stack>
        )}
      </Flex>
    </Flex>
  );
}
