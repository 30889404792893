/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Image, Text } from "@chakra-ui/react";
import axios from "axios";

import { config } from "@/constants";

interface GoogleLoginButtonProps {
  mode: "login" | "signup";
  checkValidation?: () => boolean;
}

export function GoogleLoginButton({
  mode,
  checkValidation,
}: GoogleLoginButtonProps) {
  const [loginUri, setLoginUri] = useState<string>();

  useEffect(() => {
    if (!loginUri) {
      axios.get(`${config.BACKEND_API_URL}google/redirect`).then((response) => {
        setLoginUri(response.data.url);
      });
    }
  }, []);

  const openGoogleLogin = () => {
    if ((!checkValidation || checkValidation()) && loginUri) {
      window.open(loginUri, "_self");
    }
  };

  return (
    <Button
      isDisabled={!loginUri}
      isLoading={!loginUri}
      onClick={openGoogleLogin}
      variant="outline"
      alignItems="center"
      justifyContent="center"
      gap={3}
    >
      <Image src="/assets/google.svg" boxSize={5} />
      <Text fontSize="16px" fontWeight="bold" color="gray.700">
        {mode === "signup" ? "Sign up with Google" : "Sign in with Google"}
      </Text>
    </Button>
  );
}
