import { Link } from "react-router";
import {
  Avatar,
  AvatarBadge,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";

import { AUTH_ROUTES, DASHBOARD_ROUTES } from "@/constants/routes";
import { useAuthStore } from "@/services";

export function UserMenu() {
  const user = useAuthStore((state) => state.user);

  return (
    <Menu>
      <MenuButton transition="all 0.3s" _focus={{ boxShadow: "none" }}>
        <Avatar
          src={user?.avatar}
          name={user?.full_name}
          bgColor="brand.300"
          boxSize={6}
          ms={4}
          cursor="pointer"
        >
          <AvatarBadge
            boxSize={3}
            bg="green.500"
            borderColor={useColorModeValue("white", "gray.900")}
            right={0}
            bottom={0}
            border="3px solid white"
          />
        </Avatar>
      </MenuButton>
      <MenuList
        bg={useColorModeValue("white", "gray.900")}
        color={useColorModeValue("black", "white")}
        borderColor={useColorModeValue("gray.200", "gray.900")}
      >
        <MenuItem
          as={Link}
          to={DASHBOARD_ROUTES.SETTINGS}
          bg={useColorModeValue("white", "gray.900")}
          _hover={{
            bgColor: useColorModeValue("gray.100", "gray.700"),
          }}
        >
          Settings
        </MenuItem>
        <MenuDivider />
        <MenuItem
          as={Link}
          to={AUTH_ROUTES.LOGOUT}
          bg={useColorModeValue("white", "gray.900")}
          _hover={{
            bgColor: useColorModeValue("gray.100", "gray.700"),
          }}
        >
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
