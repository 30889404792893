import { useLayoutEffect } from "react";
import { useSearchParams } from "react-router";
import {
  Divider,
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { EmptyState, LoadingState } from "@/components";
import { ProfileBooking } from "@/components/user-profile/components/profile-booking";
import { ProfileDelete } from "@/components/user-profile/components/profile-delete";
import { ProfileDetailsInfo } from "@/components/user-profile/components/profile-details-info";
import { ProfileEducation } from "@/components/user-profile/components/profile-education";
import { ProfileGoal } from "@/components/user-profile/components/profile-goal";
import { ProfileHeader } from "@/components/user-profile/components/profile-header";
import { ProfileJobExperience } from "@/components/user-profile/components/profile-job-experience";
import { ProfilePasswords } from "@/components/user-profile/components/profile-passwords";
import { ProfilePayment } from "@/components/user-profile/components/profile-payment";
import { ProfilePersonalInfo } from "@/components/user-profile/components/profile-personal-info";
import { ProfileSkills } from "@/components/user-profile/components/profile-skills";
import { failedToast, successToast } from "@/services";
import {
  BackgroundType,
  GoalType,
  MenteeType,
  MentorType,
  Prettify,
} from "@/types";

import { getBackgroundQuery } from "../background";
import { getGoalsQuery } from "../goals";

import { getProfileQuery } from "./loading";

export function SettingsPage() {
  const { data: initialProfile, isFetching: profileFetching } =
    useQuery<Prettify<MentorType & MenteeType>>(getProfileQuery());

  const { data: initialBackground, isFetching: backgroundFetching } =
    useQuery<BackgroundType>({
      ...(getBackgroundQuery() as UseQueryOptions<BackgroundType>),
      enabled: initialProfile?.role === "mentee",
    });

  const { data: initialGoals, isFetching: goalsFetching } = useQuery<
    Array<GoalType>
  >({
    ...(getGoalsQuery() as UseQueryOptions<Array<GoalType>>),
    enabled: initialProfile?.role === "mentee",
  });

  const [searchParams] = useSearchParams();
  const isConnectFailed = searchParams.get("success") === "false";

  useLayoutEffect(() => {
    if (isConnectFailed) {
      failedToast({
        title: "Account connection failed",
        description: "Please try again",
      });
    } else if (searchParams.get("success") === "true") {
      successToast({
        title: "Account connected",
        description: "Your account has been successfully connected.",
      });
    }
  }, [isConnectFailed, searchParams]);

  const fullname = `${initialProfile?.first_name} ${initialProfile?.last_name}`;

  return (
    <Flex
      boxSize="full"
      px={{ base: 0, md: 8 }}
      py={{ base: 0, md: 10 }}
      direction="column"
      align="flex-start"
      justify="center"
      bg={useColorModeValue("white", "gray.900")}
      color={useColorModeValue("black", "gray.200")}
      mb={20} // Add margin bottom to avoid the footer
    >
      <Flex
        w="full"
        px={{ base: 8, md: 0 }}
        py={{ base: 4, md: 0 }}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        gap={6}
        display={{ base: "none", md: "block" }}
      >
        <Heading fontSize="3xl">Settings</Heading>
      </Flex>
      <Tabs
        // defaultIndex={queryParams.archived ? 1 : 0}
        // onChange={(index) => i}
        boxSize="full"
        px={{ base: 4, md: 0 }}
        py={{ base: 3, md: 6 }}
      >
        <TabList
          fontWeight="bold"
          color="#717680"
          gap={5}
          overflowX="auto"
          overflowY="hidden"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
          display={{ base: "none", md: "flex" }}
        >
          <Tab whiteSpace="nowrap" _selected={{ color: "brand.500" }} px={2}>
            Personal info
          </Tab>
          <Tab
            isDisabled
            whiteSpace="nowrap"
            _selected={{ color: "brand.500" }}
            px={2}
          >
            Profile
          </Tab>
          {initialBackground && (
            <>
              <Tab
                isDisabled
                whiteSpace="nowrap"
                _selected={{ color: "brand.500" }}
                px={2}
              >
                Education
              </Tab>
              <Tab
                isDisabled
                whiteSpace="nowrap"
                _selected={{ color: "brand.500" }}
                px={2}
              >
                Job Experience
              </Tab>
              <Tab
                isDisabled
                whiteSpace="nowrap"
                _selected={{ color: "brand.500" }}
                px={2}
              >
                Skills and tools
              </Tab>
            </>
          )}

          {initialGoals && (
            <>
              <Tab
                isDisabled
                whiteSpace="nowrap"
                _selected={{ color: "brand.500" }}
                px={2}
              >
                Goals
              </Tab>
            </>
          )}
          <Tab
            isDisabled
            whiteSpace="nowrap"
            _selected={{ color: "brand.500" }}
            px={2}
          >
            Payment
          </Tab>
          <Tab
            isDisabled
            whiteSpace="nowrap"
            _selected={{ color: "brand.500" }}
            px={2}
          >
            Booking
          </Tab>
          <Tab
            isDisabled
            whiteSpace="nowrap"
            _selected={{ color: "brand.500" }}
            px={2}
          >
            Delete account
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="brand.500"
          borderRadius="1px"
        />
        <TabPanels boxSize="full">
          <Flex direction="column" gap={6}>
            {profileFetching || backgroundFetching || goalsFetching ? (
              <LoadingState />
            ) : initialProfile ? (
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={{ base: 5, md: 10 }}
              >
                <ProfileHeader
                  slug={initialProfile.id}
                  avatar={initialProfile.avatar}
                  fullname={fullname}
                  email={initialProfile.email}
                />
                {/* Personal Info Section */}
                <ProfilePersonalInfo
                  firstName={initialProfile.first_name}
                  lastName={initialProfile.last_name}
                  email={initialProfile.email}
                  id="personal-info"
                />
                <Divider />

                {/* Profile Section */}
                <ProfileDetailsInfo
                  externalLink={initialProfile.external_link || ""}
                  tagline={initialProfile.tagline || ""}
                  country={initialProfile.country || ""}
                  id="details-info"
                />
                <Divider />

                {initialBackground && (
                  <>
                    {/* Education Section */}
                    <ProfileEducation
                      background={initialBackground}
                      id="education-info"
                    />
                    <Divider />

                    {/* Job Experience Section */}
                    <ProfileJobExperience
                      background={initialBackground}
                      id="job-experience-info"
                    />
                    <Divider />

                    {/* Skills and Tools Section */}
                    <ProfileSkills
                      background={initialBackground}
                      id="skills-info"
                    />
                    <Divider />
                  </>
                )}

                {initialGoals && (
                  <>
                    {/* Goal Section */}
                    <ProfileGoal goals={initialGoals} id="goals-info" />
                    <Divider />
                  </>
                )}

                {initialProfile.role === "mentor" && (
                  <>
                    {/* Payment Section */}
                    <ProfilePayment
                      verifiedStripe={initialProfile.verified_stripe}
                      id="payment-info"
                    />
                    <Divider />
                    {/* Booking Section */}
                    <ProfileBooking
                      verifiedCalendly={initialProfile.verified_calendly}
                      id="booking-info"
                    />
                    <Divider />
                  </>
                )}

                {/* Password Section */}
                <ProfilePasswords id="password-info" />
                <Divider />

                {/* Delete Account Section */}
                <ProfileDelete id="delete-info" />
              </Flex>
            ) : (
              <EmptyState name="notification" />
            )}
          </Flex>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
