export type RoleTypes = "mentor" | "mentee";

export type UserType = {
  id: string;
  email: string;
  role: RoleTypes;
  avatar?: string;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  full_name?: string;
  city?: string | null;
  country?: string | null;
  external_link?: string | null;

  // TODO: need to be added
  bio?: string;
  languages?: string[];
};

export type GoalType = {
  title: string;
  description: string;
  expectations: string;
  expected_timeline: string;
  meeting_frequency: number;
  focus_hpw: number;
  id: number;
  mentee_id?: string;
  created_at?: string;
  updated_at?: string;
};

export type BackgroundType = {
  education: BackgroundEducationType;
  job: BackgroundJobType;
  skills: BackgroundSkillType[];
  tools: BackgroundToolType[];
  id?: number;
  mentee_id?: string;
  created_at?: string;
  updated_at?: string;
};

export type BackgroundEducationType = {
  field_of_study: string;
  graduation_year: string;
  graduation_institute: string;
  highest_education_level: string;
};

export type BackgroundJobType = {
  is_employed: false;
  title?: string;
  current_employer?: string;
};

export type BackgroundSkillType = {
  name: string;
  rating: number;
};

export type BackgroundToolType = {
  name: string;
  rating: number;
};

export type ServiceTypeTypes = "session" | "subscription";

export type ServiceType = {
  title: string;
  description: string;
  qualifications: string;
  id: number;
  mentor_id?: string;
  created_at?: string;
  updated_at?: string;
  price: number;
  type: ServiceTypeTypes;
  calendly_event?: string;
  need_approval: boolean;

  // TODO: need to be added
  mentor?: MentorType;
};

export type MenteeType = UserType & {
  background: Pick<BackgroundType, "education" | "job" | "skills" | "tools">;
  goals: Array<
    Pick<
      GoalType,
      | "id"
      | "title"
      | "description"
      | "expectations"
      | "expected_timeline"
      | "meeting_frequency"
      | "focus_hpw"
    >
  >;
};
export type MentorType = UserType & {
  services: Array<
    Pick<
      ServiceType,
      | "description"
      | "id"
      | "need_approval"
      | "price"
      | "qualifications"
      | "title"
      | "type"
    >
  >;
  booking_link?: string | null;
  tagline?: string | null;
  verified_stripe?: boolean;
  verified_calendly?: boolean;

  // TODO: need to be added
  avatar?: string;
  slug?: string;
  bio?: string;
  rating?: number;
  number_of_reviews?: number;
  languages?: string[];
  is_verified?: boolean; // all mentors are verified
  is_top_mentor?: boolean; // mentors with 10+ completed sessions while maintaining average review rate of 4.5+ will have this badge
  is_experienced_mentor?: boolean; // mentors for those 6 months or more
  // is_new_mentor?: boolean; // mentors with 6 months or less on the platform
};

export type PublicMentorType = Pick<
  MentorType,
  "id" | "created_at" | "first_name" | "last_name" | "external_link"
>;

export const ApplicationResponseStatusEnum = {
  ACCEPTED: "accepted",
  DECLINED: "declined",
} as const;

export type ApplicationResponseStatusTypes =
  (typeof ApplicationResponseStatusEnum)[keyof typeof ApplicationResponseStatusEnum];

export const ApplicationStatusEnum = {
  // pending => new application and waiting for accept by mentor
  PENDING: "pending",
  // accepted (mentor) => waiting for payment by mentee
  ACCEPTED: "accepted",

  // done => after payment and create new mentorship => archive
  DONE: "done",
  // declined (mentor) => rejected by mentor => archive
  DECLINED: "declined",
  // withdrawn (mentee) => canceled sent application by mentee => archive
  WITHDRAWN: "withdrawn",
  // expired => expire the outdated status => archive
  EXPIRED: "expired",
} as const;

export type ApplicationStatusTypes =
  (typeof ApplicationStatusEnum)[keyof typeof ApplicationStatusEnum];

export type ApplicationType = {
  id: number;
  type: ServiceTypeTypes;
  status: ApplicationStatusTypes;
  created_at: string;
  updated_at: string;
  request_note?: string | null;
  response_message?: string | null;
  mentor?: MentorType | null;
  mentee?: MenteeType | null;
  service?: ServiceType | null;

  // TODO: need to be added
  is_expired?: boolean;
};

export const MentorshipActionStatusEnum = {
  COMPLETED: "completed",
  QUITTED: "quitted",
  CANCELED: "canceled",
  // specific action for mentorship progress
  BOOKED: "booked",
  NEXT: "next",
} as const;

export type MentorshipActionStatusTypes =
  (typeof MentorshipActionStatusEnum)[keyof typeof MentorshipActionStatusEnum];

export const MentorshipStatusEnum = {
  // paid => waiting for booking by mentor
  PAID: "paid",
  // booked (mentee) => waiting for finish by mentor
  BOOKED: "booked",

  // completed (mentor) => complete the mentorship by mentor => archive
  COMPLETED: "completed",
  // quitted (mentor) => quit from mentorship by mentor => archive
  QUITTED: "quitted",
  // canceled (mentee) => cancel the mentorship by mentee => archive
  CANCELED: "canceled",
  // expired => expire the outdated status => archive
  EXPIRED: "expired",
} as const;

export type MentorshipStatusTypes =
  (typeof MentorshipStatusEnum)[keyof typeof MentorshipStatusEnum];

export type MentorshipProgressTypes = "phase1" | "phase2" | "phase3";

export type MentorshipType = {
  id: number;
  type: ServiceTypeTypes;
  status: MentorshipStatusTypes;
  progress?: MentorshipProgressTypes | null;
  created_at: string;
  updated_at: string;
  ended_at?: string | null;
  booking_url?: string | null;
  mentor?: MentorType | null;
  mentee?: MenteeType | null;

  // TODO: need to be added
  is_expired?: boolean;
  service?: ServiceType;
};

export type NotificationTypes =
  | "incomplete_user"
  | "new_user"
  | "new_application"
  | "respond_application"
  | "start_mentorship"
  | "update_mentorship";

export type NotificationStatusTypes = "read" | "unread";

export type NotificationType = {
  content?: string | null;
  id: number;
  notifier?: string | null;
  status: NotificationStatusTypes;
  timestamp: string;
  type: NotificationTypes;
};

export type CalendlyEventType = {
  active: boolean;
  admin_managed: boolean;
  booking_method: "instant";
  color: string;
  created_at: string;
  custom_questions: [
    {
      answer_choices: [];
      enabled: boolean;
      include_other: boolean;
      name: string;
      position: number;
      required: boolean;
      type: "text";
    },
  ];
  deleted_at: null;
  description_html: null;
  description_plain: null;
  duration: number;
  internal_note: null;
  kind: "solo";
  locations: [
    {
      kind: "google_conference";
    },
  ];
  name: string;
  pooling_type: null;
  position: number;
  profile: {
    name: string;
    owner: string;
    type: "User";
  };
  scheduling_url: string;
  secret: boolean;
  slug: string;
  type: "StandardEventType";
  updated_at: string;
  uri: string;
};

// utility functions
export type Prettify<T> = {
  [K in keyof T]: T[K];
} & unknown;
