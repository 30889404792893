import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router/dom";
import { ChakraProvider } from "@chakra-ui/react";

import { config } from "./constants";
import { router } from "./router";
import { theme } from "./theme";

import "./styles/index.scss";

if (config.isProduction) {
  if (config.ENABLE_SENTRY) {
    import("./services/sentry").then((sentry) => {
      sentry.initSentry();
    });
  }

  if (config.ENABLE_STATCOUNTER_ANALYTICS) {
    import("./services/analytics").then((analytics) => {
      analytics.initStatCounter();
    });
  }

  if (config.ENABLE_VERCEL_ANALYTICS) {
    import("@vercel/analytics").then((analytics) => {
      analytics.inject();
    });
  }
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>,
);
