import { ElementRef, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  useColorModeValue,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Add } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { ServiceCard } from "@/components/service-card";
import { MentorAPI, useAuthStore } from "@/services";
import { MentorType, ServiceType } from "@/types";

import { DeleteServiceModal } from "./delete-service-modal";
import { getServicesQuery } from "./loading";
import { ModifyServiceModal } from "./modify-service-modal";

export function ServicesPage() {
  const mentor = useAuthStore((state) => state.user as MentorType);

  const { data: initialData, isFetching } =
    useQuery<MentorAPI.GetServicesResponse>({
      ...getServicesQuery(),
      enabled: mentor.verified_calendly,
    });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef<ElementRef<"input">>(null);

  const [mode, setMode] = useState<"create" | "edit" | "delete">();
  const [selected, setSelected] = useState<ServiceType | undefined>();

  const handleEditService = (selectedService: ServiceType) => {
    setSelected(selectedService);
    setMode("edit");
    onOpen();
  };

  const handleDeleteService = (selectedService: ServiceType) => {
    setSelected(selectedService);
    setMode("delete");
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    setMode("create");
    onClose();
  };

  return (
    <Flex
      boxSize="full"
      px={{ base: 0, md: 8 }}
      py={{ base: 0, md: 10 }}
      direction="column"
      align="flex-start"
      justify="center"
      bg={useColorModeValue("white", "gray.900")}
      color={useColorModeValue("black", "gray.200")}
    >
      <Flex
        w="full"
        px={{ base: 2, md: 0 }}
        py={{ base: 4, md: 0 }}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        gap={6}
      >
        <Heading fontSize="3xl" display={{ base: "none", md: "block" }}>
          Services
        </Heading>
        <Stack w={{ base: "full", md: "auto" }} direction="row">
          {Boolean(initialData?.length) && (
            <Button
              onClick={onOpen}
              w={{ base: 12, md: "auto" }}
              aspectRatio={{ base: 1, md: "unset" }}
              borderRadius="full"
              colorScheme="brand"
              size={{ base: undefined, md: "sm" }}
              position={{ base: "fixed", md: "relative" }}
              bottom={{ base: 24, md: "unset" }}
              right={{ base: 4, md: "unset" }}
              zIndex={2}
              boxShadow={{ base: "lg", md: "none" }}
              p={{ base: 2, md: 3 }}
            >
              <Icon
                as={Add}
                boxSize={{ base: 6, md: "fit-content" }}
                ms={{ base: 0, md: -1 }}
              />
              <Box display={{ base: "none", md: "block" }}>Add New</Box>
            </Button>
          )}
        </Stack>
      </Flex>
      <Tabs
        // defaultIndex={queryParams.archived ? 1 : 0}
        // onChange={(index) => setQueryParams({ archived: Boolean(index) })}
        boxSize="full"
        px={{ base: 2, md: 0 }}
        py={{ base: 3, md: 6 }}
      >
        <TabList fontWeight="bold" color="#717680" gap={5}>
          <Tab _selected={{ color: "brand.500" }} px={2}>
            My Services
          </Tab>
          <Tab isDisabled _selected={{ color: "brand.500" }} px={2}>
            All Services
          </Tab>
          <Tab isDisabled _selected={{ color: "brand.500" }} px={2}>
            All Mentors
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="brand.500"
          borderRadius="1px"
        />
        <TabPanels boxSize="full">
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : initialData?.length ? (
              <Wrap
                shouldWrapChildren
                direction={{ base: "column", md: "row" }}
                spacing={6}
                py={10}
              >
                {initialData.map((service) => (
                  <ServiceCard
                    key={service.id}
                    w="full"
                    isOwn
                    service={service}
                    mentor={mentor}
                    onEdit={handleEditService}
                    onDelete={handleDeleteService}
                  />
                ))}
              </Wrap>
            ) : (
              <EmptyState
                name="service"
                onClick={
                  mentor.verified_stripe && mentor.verified_calendly
                    ? onOpen
                    : undefined
                }
                description={
                  mentor.verified_stripe && mentor.verified_calendly
                    ? undefined
                    : "You should connect your account first"
                }
              />
            )}
          </Flex>
        </TabPanels>
      </Tabs>
      {mode === "delete" && selected ? (
        <DeleteServiceModal
          key={+isOpen}
          initialRef={initialRef}
          selectedService={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      ) : (
        <ModifyServiceModal
          key={+isOpen}
          initialRef={initialRef}
          selectedService={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}
    </Flex>
  );
}
