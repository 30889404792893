import { ElementRef, useRef } from "react";
import { Link } from "react-router";
import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";

import { PUBLIC_ROUTES } from "@/constants/routes";
import { CreateApplicationModal } from "@/pages/public/all-mentors/components/create-application-modal";
import {
  ApplicationResponseStatusEnum,
  ApplicationResponseStatusTypes,
  ApplicationStatusEnum,
  ApplicationType,
} from "@/types";

interface ApplicationActionButtonProps extends ButtonProps {
  isMentee: boolean;
  application: ApplicationType;
  onPay: (application: ApplicationType) => void;
  onRespond: (
    response: ApplicationResponseStatusTypes,
    application: ApplicationType,
  ) => void;
  isLoading?: boolean;
}

export function ApplicationActionButton({
  isMentee,
  application,
  onPay,
  onRespond,
  isLoading,
  ...rest
}: ApplicationActionButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef<ElementRef<"textarea">>(null);

  switch (application.status) {
    case ApplicationStatusEnum.PENDING:
      if (isMentee) {
        return (
          <Button isDisabled variant="outline" colorScheme="brand" {...rest}>
            Buzz
          </Button>
        );
      }
      return (
        <>
          <Button
            colorScheme="red"
            onClick={() =>
              onRespond(ApplicationResponseStatusEnum.DECLINED, application)
            }
            {...rest}
          >
            Decline
          </Button>
          <Button
            colorScheme="green"
            onClick={() =>
              onRespond(ApplicationResponseStatusEnum.ACCEPTED, application)
            }
            {...rest}
          >
            Accept
          </Button>
        </>
      );

    case ApplicationStatusEnum.ACCEPTED:
      if (isMentee) {
        return (
          <Button
            onClick={() => onPay(application)}
            isLoading={isLoading}
            colorScheme="brand"
            {...rest}
          >
            Pay Now
          </Button>
        );
      }
      return (
        <Button isDisabled colorScheme="brand" {...rest}>
          Contact
        </Button>
      );

    case ApplicationStatusEnum.DONE:
    case ApplicationStatusEnum.WITHDRAWN:
      if (isMentee) {
        return (
          <>
            <Button onClick={onOpen} colorScheme="brand" {...rest}>
              Re-Apply
            </Button>
            {application.service && application.mentor && (
              <CreateApplicationModal
                key={+isOpen}
                initialRef={initialRef}
                selectedService={application.service}
                selectedMentor={application.mentor}
                isOpen={isOpen}
                onClose={onClose}
                showServices={false}
              />
            )}
          </>
        );
      }
      return (
        <Button isDisabled colorScheme="brand" {...rest}>
          Contact
        </Button>
      );

    case ApplicationStatusEnum.DECLINED:
    case ApplicationStatusEnum.EXPIRED:
      if (isMentee) {
        return (
          <Button
            as={Link}
            to={PUBLIC_ROUTES.ALL_MENTORS}
            colorScheme="brand"
            {...rest}
          >
            Try Others
          </Button>
        );
      }

      return (
        <Button isDisabled colorScheme="brand" {...rest}>
          Contact
        </Button>
      );

    default:
      return (
        <Button isDisabled {...rest}>
          {isMentee ? "Application Sent" : "Done"}
        </Button>
      );
  }
}
