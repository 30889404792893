import { MutableRefObject } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Textarea,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { SharedAPI, successToast } from "@/services";
import {
  ApplicationResponseStatusEnum,
  ApplicationResponseStatusTypes,
  ApplicationType,
} from "@/types";

const respondActionTextMap: Record<
  ApplicationResponseStatusTypes,
  {
    message: string;
    modalTitle: string;
  }
> = {
  [ApplicationResponseStatusEnum.ACCEPTED]: {
    message: ApplicationResponseStatusEnum.ACCEPTED,
    modalTitle: "Accept the Application",
  },
  [ApplicationResponseStatusEnum.DECLINED]: {
    message: ApplicationResponseStatusEnum.DECLINED,
    modalTitle: "Decline the Application",
  },
};

export function RespondApplicationModal({
  initialRef,
  finalRef,
  selectedApplication,
  isOpen,
  onClose,
  action,
  ...props
}: {
  action: ApplicationResponseStatusTypes;
  initialRef: MutableRefObject<HTMLTextAreaElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedApplication: ApplicationType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SharedAPI.RespondApplicationPayload>({
    defaultValues: { action, response_message: null },
  });

  const handleSubmitApplication = handleSubmit(
    async ({ action, response_message }) => {
      try {
        const res = await SharedAPI.respondApplication(selectedApplication.id, {
          action,
          response_message: response_message || null,
        });

        if (res.status === 200) {
          successToast({
            title: `The application ${respondActionTextMap[action].message} successfully!`,
          });
          onClose();

          await queryClient.invalidateQueries({
            queryKey: ["applications"],
          });
        }
      } catch (error) {
        console.error({ error });
      }
    },
  );

  const { ref: resMsgInputRef, ...resMsgInputRegister } =
    register("response_message");

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      // isCentered
      size={{ base: "full", sm: "md" }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmitApplication} noValidate>
        <ModalHeader>{respondActionTextMap[action].modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <FormControl
            id="response_message"
            isInvalid={Boolean(errors.response_message)}
          >
            <FormLabel htmlFor="response_message" fontWeight="normal">
              Response Message
            </FormLabel>
            <Textarea
              isDisabled={isSubmitting}
              {...resMsgInputRegister}
              ref={(e) => {
                resMsgInputRef(e);

                // it need to support focus on first input
                if (e) {
                  initialRef.current = e;
                }
              }}
            />
            {errors.response_message && (
              <FormErrorMessage>
                {errors.response_message.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          {action === ApplicationResponseStatusEnum.ACCEPTED ? (
            <Button
              type="submit"
              isLoading={isSubmitting}
              colorScheme="green"
              w="full"
            >
              Accept
            </Button>
          ) : (
            <Button
              type="submit"
              isLoading={isSubmitting}
              colorScheme="red"
              w="full"
            >
              Decline
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
