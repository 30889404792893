import { MutableRefObject } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { MenteeAPI, successToast } from "@/services";
import { BackgroundType } from "@/types";

export function EditEducationModal({
  initialRef,
  finalRef,
  background,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  background: BackgroundType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<MenteeAPI.EditBackgroundPayload["education"]>({
    defaultValues: background.education,
  });

  const handleSubmitBackground = handleSubmit(async (edited_education) => {
    try {
      const res = await MenteeAPI.editBackground({
        ...background,
        education: edited_education,
      });
      if (res.status === 200) {
        successToast({
          title: `Your background education updated successfully!`,
        });
        onClose();

        await queryClient.invalidateQueries({
          queryKey: ["background"],
        });
      }
    } catch (error) {
      console.error({ error });
    }
  });

  const { ref: helInputRef, ...helInputRegister } = register(
    "highest_education_level",
    {
      required: "This field is required",
    },
  );

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      // isCentered
      size={{ base: "full", sm: "md" }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmitBackground} noValidate>
        <ModalHeader>Edit Education Section</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <FormControl
            isRequired
            id="highest_education_level"
            isInvalid={Boolean(errors.highest_education_level)}
          >
            <FormLabel htmlFor="highest_education_level" fontWeight="normal">
              Highest Education Level:
            </FormLabel>
            <Input
              placeholder="Master"
              isDisabled={isSubmitting}
              {...helInputRegister}
              ref={(e) => {
                helInputRef(e);

                // it need to support focus on first input
                if (e) {
                  initialRef.current = e;
                }
              }}
            />
            {errors.highest_education_level && (
              <FormErrorMessage>
                {errors.highest_education_level.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="field_of_study"
            isInvalid={Boolean(errors.field_of_study)}
          >
            <FormLabel htmlFor="field_of_study" fontWeight="normal">
              Field of Study:
            </FormLabel>
            <Input
              placeholder="Computer Science"
              isDisabled={isSubmitting}
              {...register("field_of_study", {
                required: "This field is required",
              })}
            />
            {errors.field_of_study && (
              <FormErrorMessage>
                {errors.field_of_study.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="graduation_year"
            isInvalid={Boolean(errors.graduation_year)}
          >
            <FormLabel htmlFor="graduation_year" fontWeight="normal">
              Graduation Year:
            </FormLabel>
            <Input
              type="number"
              min="1900"
              max="2099"
              step="1"
              placeholder="2024"
              isDisabled={isSubmitting}
              {...register("graduation_year", {
                required: "This field is required",
                min: { value: 1900, message: "The year must grater than 1900" },
                max: { value: 2099, message: "The year must lower than 2099" },
              })}
            />
            {errors.graduation_year && (
              <FormErrorMessage>
                {errors.graduation_year.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="graduation_institute"
            isInvalid={Boolean(errors.graduation_institute)}
          >
            <FormLabel htmlFor="graduation_institute" fontWeight="normal">
              Graduation Institute:
            </FormLabel>
            <Input
              placeholder="MIT"
              isDisabled={isSubmitting}
              {...register("graduation_institute", {
                required: "This field is required",
              })}
            />
            {errors.graduation_institute && (
              <FormErrorMessage>
                {errors.graduation_institute.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            colorScheme="brand"
            w="full"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
