import { DASHBOARD_ROUTES, PUBLIC_ROUTES } from "@/constants/routes";

export interface LinkItem {
  label: string;
  icon: string;
  href?: string;
  isExternal?: boolean;
}

export const MenteeLinkItems: Array<LinkItem> = [
  { label: "Home", icon: "Home", href: DASHBOARD_ROUTES.HOME },
  { label: "Mentorships", icon: "Teacher", href: DASHBOARD_ROUTES.MENTORSHIPS },
  {
    label: "Applications",
    icon: "DocumentText",
    href: DASHBOARD_ROUTES.APPLICATIONS,
  },
  { label: "Services", icon: "Diamonds", href: PUBLIC_ROUTES.ALL_SERVICES },
  { label: "Settings", icon: "Setting2", href: DASHBOARD_ROUTES.SETTINGS },
];

export const MentorLinkItems: Array<LinkItem> = [
  { label: "Home", icon: "Home", href: DASHBOARD_ROUTES.HOME },
  { label: "Mentorships", icon: "Teacher", href: DASHBOARD_ROUTES.MENTORSHIPS },
  {
    label: "Applications",
    icon: "DocumentText",
    href: DASHBOARD_ROUTES.APPLICATIONS,
  },
  { label: "Services", icon: "Diamonds", href: DASHBOARD_ROUTES.SERVICES },
  { label: "Settings", icon: "Setting2", href: DASHBOARD_ROUTES.SETTINGS },
];
