import { LoaderFunctionArgs } from "react-router";
import { QueryClient } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { useAuthStore, UserAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

export const getProfileQuery = () => ({
  queryKey: ["profile"],
  queryFn: async () => {
    const userInfo = await UserAPI.getMe();
    useAuthStore.getState().setUser({
      ...userInfo,
      full_name: `${userInfo.first_name} ${userInfo.last_name}`,
    });
    return userInfo;
  },
});

export const settingsLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const query = getProfileQuery();

      return (
        queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query))
      );
    } catch (error) {
      captureNetworkError(error);
    }
  };
