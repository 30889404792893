import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { GoogleLoginButton } from "@/components/google-login-button";
import { HEADER_HEIGHT } from "@/constants/config";
import { EMAIL_REGEX } from "@/constants/regex";
import { AUTH_ROUTES, PUBLIC_ROUTES } from "@/constants/routes";
import { AuthAPI, failedToast, successToast } from "@/services";
import { ErrorServerResponse } from "@/services/api/axios";
import { RoleTypes } from "@/types";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: RoleTypes;
  terms: boolean;
};

export function RegisterPage() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Replace direct API call with useMutation
  const { mutate: register, isPending: isRegistering } = useMutation({
    mutationKey: ["register"],
    mutationFn: async (data: Omit<FormValues, "terms">) =>
      await AuthAPI.register(data),
    onSuccess: () => {
      successToast({
        title: "Your account created successfully!",
        description:
          "Please check your email to verify your account before login",
      });
      navigate(AUTH_ROUTES.LOGIN);
    },
    onError: (error: AxiosError<ErrorServerResponse>) => {
      failedToast({
        title: "Registration failed",
        description:
          error?.response?.data?.message ||
          "There was an error creating your account",
      });
      console.error(error);
    },
  });

  const {
    register: registerField,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      role: "mentee",
    },
  });

  const handleSubmitRegister = handleSubmit(
    ({ first_name, last_name, email, password, role }) => {
      register({ first_name, last_name, email, password, role });
    },
  );

  const checkTermsValidation = () => {
    if (!getValues("terms")) {
      setError("terms", {
        type: "manual",
        message: "You must agree with our privacy policy and terms",
      });
      return false;
    }
    return true;
  };

  return (
    <Flex
      as="form"
      onSubmit={handleSubmitRegister}
      boxSize="full"
      px={{ base: 4, lg: 0 }}
      py={{ base: 12, lg: 24 }}
      justifyContent="center"
      alignItems="start"
    >
      <Flex flex={1} justifyContent="center">
        <Flex
          w="full"
          maxW="360px"
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={8}
        >
          <Flex w="full" direction="column" alignItems="start" gap={4}>
            <Flex direction="column" alignItems="start" gap={2}>
              <Text fontSize="24px" fontWeight="bold">
                Sign up
              </Text>
              <Text fontSize="16px" fontWeight="normal" color="gray.600">
                Start your journey today
              </Text>
            </Flex>
          </Flex>
          <Flex w="full" direction="column" alignItems="start" gap={5}>
            <RadioGroup
              defaultValue="mentee"
              onChange={(value) => setValue("role", value as RoleTypes)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="brand" value="mentee">
                  Mentee
                </Radio>
                <Radio colorScheme="brand" value="mentor">
                  Mentor
                </Radio>
              </Stack>
            </RadioGroup>
            <FormControl id="first_name" isInvalid={Boolean(errors.first_name)}>
              <FormLabel fontSize="14px">First Name</FormLabel>
              <Input
                required
                type="first_name"
                placeholder="Enter your first name"
                {...registerField("first_name", {
                  required: "You must provide your first name to register",
                })}
              />
              {errors.first_name && (
                <FormErrorMessage>{errors.first_name.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl id="last_name" isInvalid={Boolean(errors.last_name)}>
              <FormLabel fontSize="14px">Last Name</FormLabel>
              <Input
                required
                type="last_name"
                placeholder="Enter your last name"
                {...registerField("last_name", {
                  required: "You must provide your last name to register",
                })}
              />
              {errors.last_name && (
                <FormErrorMessage>{errors.last_name.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl id="email" isInvalid={Boolean(errors.email)}>
              <FormLabel fontSize="14px">Email Address</FormLabel>
              <Input
                required
                type="email"
                placeholder="Enter your email"
                {...registerField("email", {
                  required: "You must provide your email to register",
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl id="password" isInvalid={Boolean(errors.password)}>
              <FormLabel fontSize="14px">Password</FormLabel>
              <InputGroup>
                <Input
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="Create a password"
                  {...registerField("password", {
                    required: "You must provide a password to register",
                    minLength: {
                      value: 8,
                      message: "Password should be at least 8 characters",
                    },
                  })}
                />
                <InputRightElement h="full">
                  <Button
                    variant="ghost"
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.password ? (
                <FormErrorMessage>{errors.password.message}</FormErrorMessage>
              ) : (
                <FormHelperText>Must be at least 8 characters.</FormHelperText>
              )}
            </FormControl>
            <FormControl id="terms" isInvalid={Boolean(errors.terms)}>
              <Checkbox
                type="checkbox"
                display="flex"
                alignItems="start"
                fontSize="14px"
                {...registerField("terms", {
                  required: "You must agree with our privacy policy and terms",
                })}
              >
                I agree with{" "}
                <Button
                  variant="link"
                  as={Link}
                  to={PUBLIC_ROUTES.PRIVACY_POLICY}
                  className="brand.600"
                >
                  privacy policy
                </Button>
                {" and "}
                <Button
                  variant="link"
                  as={Link}
                  to={PUBLIC_ROUTES.TERMS_OF_SERVICE}
                  className="brand.600"
                >
                  terms of services
                </Button>
              </Checkbox>
              {errors.terms && (
                <FormErrorMessage>{errors.terms.message}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>

          <Flex w="full" direction="column" gap={4}>
            <Button
              type="submit"
              isLoading={isRegistering}
              loadingText="Creating account..."
              w="full"
              borderRadius="lg"
              colorScheme="brand"
            >
              Create account
            </Button>
            <GoogleLoginButton
              mode="signup"
              checkValidation={checkTermsValidation}
            />
          </Flex>
          <Text fontSize="14px" fontWeight="normal" color="gray.600">
            Already a user?{" "}
            <Button
              variant="link"
              as={Link}
              to={AUTH_ROUTES.LOGIN}
              fontSize="inherit"
              color="brand.600"
            >
              Log in
            </Button>
          </Text>
        </Flex>
      </Flex>

      <Flex
        flex={1}
        display={{ base: "none", lg: "flex" }}
        backgroundImage={`url("/assets/images/register/register_bg.png")`}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        position="relative"
        boxSize="full"
        h="full"
        minH={`calc(100vh - 96px - ${HEADER_HEIGHT * 4}px)`}
        overflow="hidden"
        px={4}
        justifyContent="center"
        alignItems="start"
      >
        <Text
          whiteSpace="nowrap"
          fontSize="clamp(28px, 2vw, 2rem)"
          fontFamily="monospace"
          style={{ WebkitTextStroke: "1px white" }}
          overflow="hidden"
        >
          1:1 mentorship is a journey...
        </Text>
      </Flex>
    </Flex>
  );
}
