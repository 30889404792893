import { useMemo, useState } from "react";
import { Link } from "react-router";
import {
  Badge,
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { CloseCircle, Tag } from "iconsax-react";

import { mentorshipStatusColorMapping } from "@/constants/mappings";
import { DASHBOARD_ROUTES } from "@/constants/routes";
import { MentorshipStatusEnum, MentorshipType, RoleTypes } from "@/types";
import { dateFormatter } from "@/utils/date-formatter";
import { getRelativeTimeString } from "@/utils/relative-date";

import { MentorshipActionButton } from "./mentorship-action-button";

interface MentorshipCardProps extends FlexProps {
  mentorship?: MentorshipType;
  isOwn?: boolean;

  role?: RoleTypes;
  onDetail?: (mentorship: MentorshipType) => void;
  onNext?: (mentorship: MentorshipType) => void;
  onQuit?: (mentorship: MentorshipType) => void;
  onFinish?: (mentorship: MentorshipType) => void;
  onCancel?: (mentorship: MentorshipType) => void;
  onBook?: (mentorship: MentorshipType) => void;
  bookingLoading?: boolean;
}

export function MentorshipCard({
  mentorship,
  isOwn,
  role,
  onDetail,
  onNext,
  onQuit,
  onFinish,
  onCancel,
  onBook,
  bookingLoading,
  ...rest
}: MentorshipCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleToggleExpand = () => {
    if (isMobile) {
      setIsExpanded((prev) => !prev);
    }
  };

  const daysLeft = useMemo(() => {
    const date = mentorship?.updated_at || mentorship?.created_at;
    if (!date) return null;

    const currentDate = new Date();
    const mentorshipDate = new Date(date);

    // 30 days is typically the expiration window for booking
    const expirationDays = 30;
    const daysPassed = Math.floor(
      (currentDate.getTime() - mentorshipDate.getTime()) / (1000 * 3600 * 24),
    );

    const remainingDays = Math.max(0, expirationDays - daysPassed);
    return remainingDays > 0 ? remainingDays : null;
  }, [mentorship]);

  const showFullContent = isExpanded || !isMobile;

  const isMentee = role === "mentee";
  const authorInfo = isMentee ? mentorship?.mentor : mentorship?.mentee;
  const fullName = `${authorInfo?.first_name} ${authorInfo?.last_name}`;

  const isExpired = mentorship?.is_expired;
  const isInProgress =
    mentorship?.status === MentorshipStatusEnum.PAID ||
    mentorship?.status === MentorshipStatusEnum.BOOKED;

  const showCancelButton = isMentee && isInProgress;

  const showQuitButton = !isMentee && isInProgress;

  const isSubscription = mentorship?.type === "subscription";

  return (
    <Flex
      minW={{ base: "300px", md: "375px" }}
      w="full"
      maxW={{ base: "full", md: "375px" }}
      p={{ base: 3, md: 5 }}
      bg={useColorModeValue("white", "gray.900")}
      rounded="lg"
      direction={showFullContent ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      gap={6}
      borderWidth="1px"
      borderRadius="lg"
      cursor={isMobile ? "pointer" : "default"}
      onClick={handleToggleExpand}
      transition="all 0.3s ease"
      {...rest}
    >
      <Box
        w={showFullContent ? "full" : "auto"}
        position="relative"
        borderRadius="lg"
        boxShadow="0px 6px 20px -4px rgba(55, 74, 89, 0.36)"
        transition="all 0.3s ease"
      >
        <Image
          w={showFullContent ? "full" : "98px"}
          h={showFullContent ? "232px" : "138px"}
          // aspectRatio={showFullContent ? "327 / 232" : "98 / 138"}
          objectFit="cover"
          borderRadius="lg"
          src={authorInfo?.avatar}
          alt={fullName}
          filter={isExpired || !isInProgress ? "grayscale(100%)" : "none"}
        />
        {/* Icons overlay */}
        {!isOwn && showFullContent && (
          <Flex
            w="full"
            px={2}
            position="absolute"
            top={2}
            zIndex={1}
            alignItems="start"
            direction="row-reverse"
            justifyContent="space-between"
            gap={2}
            transition="all 0.3s ease"
          >
            <Badge
              colorScheme={
                mentorship
                  ? mentorshipStatusColorMapping[mentorship.status]
                  : "gray"
              }
              boxSize="fit-content"
              borderRadius="lg"
              py={1}
              px={2}
            >
              <Text fontSize={["10px", "14px"]}>
                {mentorship?.status || "unknown"}
              </Text>
            </Badge>
            {showCancelButton && (
              <Tooltip hasArrow label="Cancel the mentorship">
                <IconButton
                  icon={<CloseCircle />}
                  onClick={() => mentorship && onCancel?.(mentorship)}
                  color="white"
                  bg="red"
                  size="sm"
                  borderRadius="full"
                  aria-label="Cancel the mentorship"
                  _hover={{
                    bgColor: "unset",
                  }}
                />
              </Tooltip>
            )}
            {showQuitButton && (
              <Tooltip hasArrow label="Quit from mentorship">
                <IconButton
                  icon={<CloseCircle />}
                  onClick={() => mentorship && onQuit?.(mentorship)}
                  color="white"
                  bg="red"
                  size="sm"
                  borderRadius="full"
                  aria-label="Quit from mentorship"
                  _hover={{
                    bgColor: "unset",
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        )}
        {!isOwn && (
          <Flex
            w="full"
            px={2}
            position="absolute"
            bottom={2}
            zIndex={1}
            alignItems="end"
            justifyContent="space-between"
            gap={2}
            transition="all 0.3s ease"
          >
            <Icon
              as={Tag}
              color="brand.400"
              variant="Bold"
              boxSize={showFullContent ? 6 : 4}
            />
          </Flex>
        )}
      </Box>
      <Flex
        flex={1}
        w="full"
        direction="column"
        justifyContent="space-between"
        gap={showFullContent ? 4 : 1}
        transition="all 0.3s ease"
      >
        <Flex w="full" direction="column" order={showFullContent ? 1 : 0}>
          <Text
            fontSize={["14px", "20px", "24px"]}
            fontWeight="bold"
            noOfLines={1}
            transition="all 0.3s ease"
          >
            {mentorship?.service?.title}
          </Text>
          <Text
            fontSize={["10px", "14px", "16px"]}
            fontWeight="normal"
            color="#374A59"
          >
            With{" "}
            <Button
              as={Link}
              to={`${
                isMentee ? DASHBOARD_ROUTES.MENTORS : DASHBOARD_ROUTES.MENTEES
              }/${authorInfo?.id}`}
              variant="link"
              color="brand.600"
            >
              {fullName}
            </Button>
          </Text>
        </Flex>
        <Flex
          direction="column"
          alignItems="start"
          order={showFullContent ? 0 : 1}
        >
          <Text
            fontSize={["10px", "12px", "14px"]}
            fontWeight="normal"
            color="#6E798C"
          >
            {`${
              isInProgress
                ? "Started from"
                : mentorship?.status === MentorshipStatusEnum.COMPLETED
                  ? "Ended"
                  : mentorship?.status === MentorshipStatusEnum.CANCELED
                    ? "Canceled"
                    : "Quitted"
            }`}{" "}
            <Tooltip
              label={dateFormatter(
                mentorship?.ended_at ||
                  mentorship?.created_at ||
                  new Date().toISOString(),
                {
                  dateStyle: "full",
                },
              )}
              aria-label="Mentorship Date Tooltip"
              placement="top"
            >
              <Text as="span" fontWeight="bold" color="brand.500">
                {mentorship
                  ? getRelativeTimeString(
                      mentorship?.ended_at || mentorship?.created_at,
                    )
                  : new Date().toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
              </Text>
            </Tooltip>
          </Text>
          {!isExpired && daysLeft && isInProgress && (
            <Text
              as="span"
              fontSize={["10px", "12px", "14px"]}
              fontWeight="bold"
              color="yellow.500"
            >
              ({daysLeft} days left to{" "}
              {mentorship.status === "paid" ? "book" : "finish"})
            </Text>
          )}
        </Flex>
        <Flex
          w="full"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          order={showFullContent ? 3 : 2}
        >
          {!showFullContent && (
            <Badge
              colorScheme={
                mentorship
                  ? mentorshipStatusColorMapping[mentorship.status]
                  : "gray"
              }
              boxSize="fit-content"
              borderRadius="lg"
              py={1}
              px={2}
            >
              <Text fontSize={["10px", "14px"]}>
                {mentorship?.status || "unknown"}
              </Text>
            </Badge>
          )}
          <Flex
            w="full"
            direction="row-reverse"
            justifyContent={showFullContent ? "start" : "end"}
            gap={showFullContent ? 2 : 0}
            order={showFullContent ? 2 : 3}
            transition="all 0.3s ease"
          >
            <Tooltip
              label="Need Approval"
              aria-label="Approval Tooltip"
              placement="top"
            >
              <Image
                src="/assets/images/icons/envelope.svg"
                borderRadius="lg"
                p={showFullContent ? 2.5 : 1.5}
                boxSize={showFullContent ? "48px" : "32px"}
                bgColor={showFullContent ? "brand.50" : "transparent"}
                transition="all 0.3s ease"
              />
            </Tooltip>
            <Tooltip
              label={isSubscription ? "Subscription" : "One Session"}
              aria-label={
                isSubscription ? "Subscription Tooltip" : "One Session Tooltip"
              }
              placement="top"
            >
              {isSubscription ? (
                <Image
                  src="/assets/images/icons/refresh.svg"
                  borderRadius="lg"
                  boxSize={showFullContent ? "48px" : "32px"}
                  bgColor={showFullContent ? "brand.50" : "transparent"}
                  transition="all 0.3s ease"
                />
              ) : (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="lg"
                  boxSize={showFullContent ? "48px" : "32px"}
                  bgColor={showFullContent ? "brand.50" : "transparent"}
                  transition="all 0.3s ease"
                >
                  <Text
                    fontSize={{ base: "16px", md: "20px" }}
                    fontWeight="bold"
                    color="#7AB738"
                  >
                    +1
                  </Text>
                </Flex>
              )}
            </Tooltip>
          </Flex>
        </Flex>
        {isOwn ? (
          <Button
            as={Link}
            to={DASHBOARD_ROUTES.MENTORSHIPS}
            display={showFullContent ? "flex" : "none"}
            w="full"
            mt={4}
            colorScheme="brand"
            order={4}
          >
            Edit
          </Button>
        ) : (
          <Stack
            direction="row"
            spacing={4}
            w="full"
            mt={4}
            order={4}
            display={showFullContent ? "flex" : "none"}
          >
            {mentorship ? (
              <MentorshipActionButton
                isMentee={isMentee}
                mentorship={mentorship}
                onBook={onBook}
                onNext={onNext}
                onFinish={onFinish}
                isLoading={bookingLoading}
                flex={1}
              />
            ) : (
              <Button
                as={Link}
                to={DASHBOARD_ROUTES.APPLICATIONS}
                w="full"
                colorScheme="brand"
              >
                BOOK 1:1 MEETING
              </Button>
            )}
          </Stack>
        )}
      </Flex>
    </Flex>
  );
}
