import { Link, useNavigate } from "react-router";
import {
  Avatar,
  AvatarBadge,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notification } from "iconsax-react";

import { EmptyState } from "@/components";
import { notificationTypeMapping } from "@/constants/mappings";
import { DASHBOARD_ROUTES } from "@/constants/routes";
import { getNotificationListQuery } from "@/pages/dashboard/notifications";
import { NotificationAPI } from "@/services";
import { NotificationType } from "@/types";
import { getRelativeTimeString } from "@/utils/relative-date";

interface SingleMenuNotificationProps {
  notification: NotificationType;
  onClick: (selected: NotificationType) => void;
}

function SingleMenuNotification({
  notification,
  onClick,
}: SingleMenuNotificationProps) {
  const notificationMap = notificationTypeMapping(notification.notifier)[
    notification.type
  ];

  return (
    <MenuItem
      onClick={() => onClick(notification)}
      bg={useColorModeValue(
        notification.status === "unread" ? "white" : "gray.50",
        notification.status === "unread" ? "gray.900" : "gray.800",
      )}
      _hover={{
        bgColor: useColorModeValue("gray.100", "gray.700"),
      }}
      p={4}
      gap={4}
      alignItems="center"
      justifyContent="space-between"
      opacity={useColorModeValue(
        notification.status === "unread" ? "100%" : "70%",
        notification.status === "unread" ? "100%" : "60%",
      )}
    >
      <Box
        boxSize={35}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={useColorModeValue("brand.50", "gray.700")}
        borderRadius={100}
      >
        {notificationMap?.icon}
      </Box>
      <Box flex={1}>
        <Text _firstLetter={{ textTransform: "uppercase" }}>
          {notificationMap?.label}
        </Text>
        <Text fontSize="xs">
          {getRelativeTimeString(notification.timestamp)}
        </Text>
      </Box>
    </MenuItem>
  );
}

function MoreNotifications({ length }: { length: number }) {
  return (
    <>
      <MenuDivider m={0} />
      <MenuItem
        as={Link}
        to={DASHBOARD_ROUTES.NOTIFICATIONS}
        p={4}
        bg={useColorModeValue("white", "gray.900")}
        _hover={{
          bgColor: useColorModeValue("gray.100", "gray.700"),
        }}
        justifyContent="center"
      >
        See all notifications {`(${length})`}
      </MenuItem>
    </>
  );
}

export function NotificationMenu() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: initialData, isLoading } =
    useQuery<NotificationAPI.GetNotificationList>(getNotificationListQuery());

  const { mutateAsync: seenNotification } = useMutation({
    mutationKey: [`seen-notification`],
    mutationFn: (
      notification_id: Parameters<typeof NotificationAPI.seenNotification>[0],
    ) => NotificationAPI.seenNotification(notification_id),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["notifications"] }),
  });

  const handleNotification = async (notification: NotificationType) => {
    navigate(notificationTypeMapping()[notification.type].link);
    if (notification.status === "unread") {
      await seenNotification(notification.id);
    }
  };

  const unreadCount =
    initialData?.filter((item) => item.status === "unread").length || 0;
  const moreThanNine = unreadCount > 9;

  return (
    <Menu>
      <MenuButton transition="all 0.3s" _focus={{ boxShadow: "none" }}>
        <Avatar
          bg="none"
          position="relative"
          icon={
            <IconButton
              as={Notification}
              boxSize={10}
              p={2}
              color="#717680"
              variant="ghost"
              aria-label="open notifications menu"
            />
          }
        >
          {isLoading ? (
            <AvatarBadge
              boxSize="1.25em"
              right={0}
              top="50%"
              transform="translateY(-50%)"
              border={0}
            >
              <Spinner
                size="sm"
                speed="0.65s"
                emptyColor="gray.200"
                color="red.500"
              />
            </AvatarBadge>
          ) : (
            unreadCount && (
              <AvatarBadge
                h={4}
                px={1}
                bg="red.500"
                right={moreThanNine ? "-2px" : "5px"}
                top="50%"
                transform="translateY(-50%)"
                border={0}
              >
                <Text fontSize="xs">{moreThanNine ? "9+" : unreadCount}</Text>
              </AvatarBadge>
            )
          )}
        </Avatar>
      </MenuButton>
      <MenuList
        bg={useColorModeValue("white", "gray.900")}
        color={useColorModeValue("black", "white")}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        p={0}
        zIndex={20}
      >
        {initialData
          ?.slice(0, 5)
          .map((notification) => (
            <SingleMenuNotification
              key={notification.id}
              notification={notification}
              onClick={handleNotification}
            />
          ))}
        {initialData?.length ? (
          <MoreNotifications length={initialData.length} />
        ) : (
          <EmptyState emptyText="No notifications" />
        )}
      </MenuList>
    </Menu>
  );
}
