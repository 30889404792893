import { captureNetworkError } from "@/services/error-handler";
import { PublicMentorType, ServiceType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getPublicMentors request
 */

export type GetPublicMentorsResponse = PublicMentorType[];

export const getPublicMentors = async <T extends GetPublicMentorsResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("/public/mentors", options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * getSinglePublicMentor request
 */

export type GetSinglePublicMentorResponse = PublicMentorType;

export const getSinglePublicMentor = async <
  T extends GetSinglePublicMentorResponse,
>(
  mentorId: string,
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`/public/mentors/${mentorId}`, options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * getPublicServices request
 */

export type GetPublicServicesResponse = ServiceType[];

export const getPublicServices = async <T extends GetPublicServicesResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("/public/services", options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};
