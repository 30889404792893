import { extendTheme, StyleFunctionProps } from "@chakra-ui/react";

import { colors } from "./colors";
import { containerTheme } from "./components";
import { headingStyles, textStyles } from "./text-styles";
import { breakpoints } from "@/theme/components/breakpoints";
export const theme = extendTheme({
  colors,
  styles: {
    global: {
      ...headingStyles,
    },
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  textStyles,
  breakpoints,
  components: {
    Container: containerTheme,
    Input: {
      baseStyle: (props: StyleFunctionProps) => ({
        field: {
          _disabled: {
            bg: props.colorMode === "light" ? "gray.50" : "gray.700",
            color: props.colorMode === "light" ? "black" : "gray.500",
            pointerEvents: "none",
          },
          _placeholder: { color: "gray.400" },
        },
      }),
    },
    Button: {
      variants: {
        primary: (_props: StyleFunctionProps) => ({
          bg: "brand.400",
          color: "white",
          _hover: {
            bg: "brand.500",
          },
          _active: {
            bg: "brand.600",
            transform: "scale(0.95)",
          },
        }),
      },
    },
  },
});
