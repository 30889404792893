import { useState } from "react";
import { Outlet } from "react-router";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";

import {
  ACTION_BAR_HEIGHT,
  HEADER_HEIGHT,
  SIDE_BAR_WIDTH,
} from "@/constants/config";
import { queryClient } from "@/index";

import { MobileActionBar } from "./components/mobile-action-bar";
import { MobileNav } from "./components/mobile-nav";
import { SidebarContent } from "./components/sidebar-content";

export function DashboardLayout() {
  const [queryClientState] = useState(() => queryClient);

  return (
    <QueryClientProvider client={queryClientState}>
      <MobileNav
        display={{ base: "flex", md: "none" }}
        position="fixed"
        top={0}
        zIndex={10}
      />
      <Box
        minH="full"
        bg={useColorModeValue("white", "gray.800")}
        overflowX="hidden"
        pt={{ base: HEADER_HEIGHT, md: 0 }}
        pb={{ base: ACTION_BAR_HEIGHT, md: 0 }}
      >
        <SidebarContent display={{ base: "none", md: "flex" }} />
        <Flex
          w="auto"
          h="full"
          direction="column"
          justify="center"
          align="center"
          ml={{ base: 0, md: SIDE_BAR_WIDTH }}
          transition="all 0.3s ease"
        >
          <Flex
            as="main"
            direction="column"
            boxSize="full"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Outlet />
          </Flex>
        </Flex>
        <MobileActionBar
          display={{ base: "flex", md: "none" }}
          position="fixed"
          bottom={0}
          zIndex={10}
        />
      </Box>
    </QueryClientProvider>
  );
}
