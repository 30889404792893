import { MutableRefObject } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { failedToast, SharedAPI, successToast } from "@/services";
import { MentorshipType } from "@/types";

export function CancelMentorshipModal({
  initialRef,
  finalRef,
  selectedMentorship,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedMentorship: MentorshipType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending: isSubmitting } = useMutation({
    mutationKey: [`cancel-mentorship`, selectedMentorship.id],
    mutationFn: (
      mentorship_id: Parameters<typeof SharedAPI.cancelMentorship>[0],
    ) => SharedAPI.cancelMentorship(mentorship_id),
    onSuccess: () => {
      successToast({
        title: `Your canceled from the mentorship successfully!`,
      });
      onClose();

      queryClient.invalidateQueries({
        queryKey: ["mentorships"],
      });
    },
    onError: (err) => {
      if (isAxiosError(err) && err.response) {
        failedToast({
          title: err.response.data.message,
        });
      } else {
        failedToast({
          title: err.message,
        });
      }
      onClose();
    },
  });

  const handleCancelMentorship = async () => {
    try {
      await mutateAsync(selectedMentorship.id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel the Mentorship</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <Text>Do you want to cancel this mentorship?</Text>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={handleCancelMentorship}
            isLoading={isSubmitting}
            colorScheme="red"
            w="full"
          >
            Cancel the mentorship
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
