import { Link } from "react-router";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Button,
  Collapse,
  ContainerProps,
  Flex,
  IconButton,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { LoginCurve } from "iconsax-react";

import { HEADER_HEIGHT } from "@/constants/config";
import {
  AUTH_ROUTES,
  DASHBOARD_ROUTES,
  PUBLIC_ROUTES,
} from "@/constants/routes";
import { useAuthStore } from "@/services";

import { DesktopNav, MobileCollapsableNav } from "./components";

interface HeaderProps extends ContainerProps {}

export function Header({ ...rest }: HeaderProps) {
  const { isOpen, onToggle } = useDisclosure();
  const { isAuthenticated } = useAuthStore();

  const user = useAuthStore((state) => state.user);

  return (
    <Flex
      as="header"
      variant="banner"
      maxW="full"
      w="full"
      h="auto"
      minH={HEADER_HEIGHT}
      px={{ base: 18, lg: 28 }}
      py={{ base: 4, lg: 4 }}
      direction="column"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      {...rest}
    >
      <Flex w="full" alignItems="center">
        <Flex w="full" justify={{ base: "space-between" }} alignItems="center">
          <Flex as={Link} to={PUBLIC_ROUTES.HOME} alignItems="center" gap={2}>
            <Image src="/assets/images/logo_full.svg" alt="O'Mentors" h={8} />
          </Flex>
          <Flex flex={1} display={{ base: "none", lg: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Flex display={{ base: "none", lg: "flex" }}>
          {isAuthenticated ? (
            <Stack
              flex={{ base: 1, lg: 0 }}
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Button as={Link} to={DASHBOARD_ROUTES.HOME} variant="link">
                <Text>{user?.first_name}</Text>
              </Button>
              <Button
                as={Link}
                to={AUTH_ROUTES.LOGOUT}
                variant="ghost"
                colorScheme="brand"
                p={0}
              >
                <LoginCurve size={20} />
              </Button>
            </Stack>
          ) : (
            <Stack
              flex={{ base: 1, lg: 0 }}
              justify="flex-end"
              direction="row"
              spacing={6}
            >
              <Button
                as={Link}
                to={AUTH_ROUTES.LOGIN}
                fontSize="sm"
                fontWeight={400}
                variant="link"
                color="gray.600"
              >
                Log in
              </Button>
              <Button
                as={Link}
                to={AUTH_ROUTES.REGISTER}
                display={{ base: "none", lg: "inline-flex" }}
                fontSize="sm"
                fontWeight={600}
                color="white"
                bg="brand.400"
                _hover={{
                  bg: "brand.300",
                }}
              >
                Sign up
              </Button>
            </Stack>
          )}
        </Flex>

        <Flex display={{ base: "flex", lg: "none" }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileCollapsableNav onClick={onToggle} />
      </Collapse>
    </Flex>
  );
}
