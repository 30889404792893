import type { IconType } from "react-icons";

import { PUBLIC_ROUTES } from "@/constants/routes";

export interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  icon?: IconType;
  isExternal?: boolean;
}

export const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Home",
    href: PUBLIC_ROUTES.HOME,
  },
  {
    label: "Blog",
    href: "https://blog.omentors.com",
    isExternal: true,
  },
  {
    label: "About",
    href: PUBLIC_ROUTES.ABOUT,
  },
  {
    label: "Help",
    href: PUBLIC_ROUTES.HELP,
  },
];
