import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import { Clock, DollarCircle } from "iconsax-react";

export function AboutPage() {
  return (
    <Container maxW="container.xl" px={{ base: "4" }}>
      <Flex flexDirection="column" w="full">
        <Flex
          py={{ base: 6, lg: "40px" }}
          px={4}
          flexDirection="column"
          alignItems="center"
        >
          <Text
            mb={3}
            fontWeight="semibold"
            fontSize={{ base: "sm", lg: "md" }}
            color="#98C045"
            lineHeight={{ base: "20px", lg: "24px" }}
          >
            About us
          </Text>
          <Text
            mb={{ base: 4, lg: 6 }}
            fontWeight="600"
            fontSize={{ base: "36px", lg: "48px" }}
            lineHeight={{ base: "44px", lg: "60px" }}
          >
            About Us
          </Text>
          <Text
            textAlign="center"
            color="#5F5D5A"
            fontWeight="400"
            lineHeight="28px"
            fontSize={{ base: "18px", lg: "20px" }}
          >
            Learn more about the company and the team behind it.
          </Text>
        </Flex>
        <Box
          w="full"
          px={{ base: 4, lg: 8 }}
          py={{ base: 6, lg: "46px" }}
          display="grid"
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gridTemplateAreas={{
            base: `"banner"
        "bannerImage"
        "bannerNumbers"`,
            lg: `"bannerImage banner"
        "bannerImage bannerNumbers"`,
          }}
        >
          <Flex gridArea="banner" flexDirection="column">
            <Text
              color="#619D1F"
              fontWeight="600"
              fontSize={{ base: "14px", lg: "16px" }}
              lineHeight={{ base: "20px", lg: "24px" }}
            >
              We’ve helped hundreds of recent graduates and professionals
            </Text>
            <Text
              fontWeight="600"
              fontSize={{ base: "30px", lg: "48px" }}
              lineHeight={{ base: "38px", lg: "60px" }}
              color="#231509"
            >
              Empowering Data Science Careers, One Connection at a Time
            </Text>
          </Flex>
          <Image
            gridArea="bannerImage"
            aspectRatio={1 / 1}
            objectFit="scale-down"
            src="/assets/images/about/about1.png"
            w={{ base: "full", lg: "560px" }}
            boxSize={{ lg: "560px" }}
          />
          <Box
            gridArea="bannerNumbers"
            display="grid"
            gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
            gap={4}
          >
            <Flex
              alignItems="flex-start"
              flex={{ lg: "1 50%" }}
              flexDirection="column"
            >
              <Text
                color="#98C045"
                fontWeight="600"
                fontSize="60px"
                lineHeight="72px"
              >
                600+
              </Text>
              <Text
                color="#181D27"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="500"
              >
                Scheduled 1-on-1 Meetings
              </Text>
            </Flex>
            <Flex
              alignItems="flex-start"
              flex={{ lg: "1 50%" }}
              flexDirection="column"
            >
              <Text
                color="#98C045"
                fontWeight="600"
                fontSize="60px"
                lineHeight="72px"
              >
                100+
              </Text>
              <Text
                color="#181D27"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="500"
              >
                Registered Mentees
              </Text>
            </Flex>
            <Flex
              alignItems="flex-start"
              flex={{ lg: "1 50%" }}
              flexDirection="column"
            >
              <Text
                color="#98C045"
                fontWeight="600"
                fontSize="60px"
                lineHeight="72px"
              >
                20k
              </Text>
              <Text
                color="#181D27"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="500"
              >
                Global Website Visitors
              </Text>
            </Flex>
            <Flex
              alignItems="flex-start"
              flex={{ lg: "1 50%" }}
              flexDirection="column"
            >
              <Text
                color="#98C045"
                fontWeight="600"
                fontSize="60px"
                lineHeight="72px"
              >
                50+
              </Text>
              <Text
                color="#181D27"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="500"
              >
                5-star reviews
              </Text>
            </Flex>
          </Box>
        </Box>
        <Box
          py={{ base: 6, lg: 5 }}
          px={{ lg: 8 }}
          display="grid"
          gridTemplateAreas={{
            base: `"mentorTitle"
          "mentorImage"
          "mentorText"
          `,
            lg: `"mentorTitle mentorImage"
          "mentorText mentorImage"
          `,
          }}
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gridTemplateRows={{ lg: "96px auto" }}
          columnGap={{ lg: "70px" }}
          rowGap="0"
        >
          <Flex gridArea="mentorTitle" flexDirection="column">
            <Text
              color="#619D1F"
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
            >
              The power of one-on-one mentorship in Data Science careers
            </Text>
            <Text
              mt={3}
              fontWeight="600"
              lineHeight={{ base: "38px", lg: "60x" }}
              fontSize={{ base: "30px", lg: "48px" }}
              color="#231509"
            >
              About O’Mentors
            </Text>
          </Flex>
          <Flex gridArea="mentorText" flexDirection="column">
            <Text>
              Welcome to O&apos;Mentors, the premier platform for connecting
              aspiring professionals with top mentors in the field of Data
              Science. Our mission is simple: to bridge the gap between ambition
              and expertise.
            </Text>
            <Text mt={3}>
              Whether you&apos;re a recent graduate, a career changer, or an
              experienced professional aiming for the next level, we provide
              tailored mentorship that aligns with your career goals. With a
              focus on 1-on-1 mentoring, we ensure that every mentee receives
              personalized guidance, actionable advice, and industry insights to
              accelerate their career.
            </Text>
            <Text mt={3}>
              At O&apos;Mentors, we specialize in the following areas:
            </Text>
            <Text my={3} as="ul" pl={7}>
              <li>Data Science</li>
              <li>Data Engineering</li>
            </Text>
            <Text>
              Our mentors are seasoned industry experts with real-world
              experience who are dedicated to helping you succeed. Together,
              we’ll navigate challenges, build skills, and achieve milestones
              that matter to you.
            </Text>
          </Flex>
          <Image
            gridArea="mentorImage"
            aspectRatio={1 / 1}
            objectFit="scale-down"
            src="/assets/images/about/about2.jpg"
            w={{ base: "auto", lg: "560px" }}
            boxSize={{ lg: "560px" }}
          />
        </Box>
        <Box
          py={{ base: 6, lg: 5 }}
          px={{ lg: 8 }}
          display="grid"
          gridTemplateAreas={{
            base: `"SaeedTitle"
          "SaeedImage"
          "SaeedText"
          `,
            lg: `"SaeedImage SaeedTitle"
          "SaeedImage SaeedText"
          `,
          }}
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gridTemplateRows={{ lg: "96px auto" }}
          columnGap={{ lg: "70px" }}
          rowGap="0"
        >
          <Flex gridArea="SaeedTitle" flexDirection="column">
            <Text
              color="#619D1F"
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
            >
              Empowering connections, transforming careers
            </Text>
            <Text
              mt={3}
              fontWeight="600"
              lineHeight={{ base: "38px", lg: "60x" }}
              fontSize={{ base: "30px", lg: "48px" }}
              color="#231509"
            >
              O&apos;Mentors Story
            </Text>
          </Flex>
          <Flex gridArea="SaeedText" flexDirection="column">
            <Text>
              O’Mentors was founded by Saeed Mirshekari, inspired by his journey
              through challenges and his passion for mentorship. With over two
              years of 1-on-1 mentoring experience, he witnessed firsthand the
              transformative impact of personalized guidance and sought to
              create a platform that connects mentees with top mentors to
              achieve their career goals.{" "}
            </Text>
            <Text mt={3}>
              The concept of O’Mentors emerged during a pivotal period in
              Saeed’s life. In 2015, after leaving a postdoc in Brazil, he
              returned to the USA, facing significant personal and professional
              challenges. Despite feeling isolated and lacking access to
              resources and connections, he persevered through the transition
              into industry.{" "}
            </Text>
            <Text mt={3}>
              Motivated by his experiences, Saeed envisioned O’Mentors as a
              platform to help others navigate similar transitions more easily,
              especially those from academia or with limited industry networks.
              Today, O’Mentors stands as a professional and impactful solution,
              bridging the gap between ambition and opportunity.{" "}
            </Text>
          </Flex>
          <Image
            gridArea="SaeedImage"
            filter="brightness(0.9) contrast(1.1) sepia(0.5) saturate(1.5) hue-rotate(90deg)"
            aspectRatio={1 / 1}
            objectFit="cover"
            src="/assets/images/about/about3.jpg"
            w="full"
          />
        </Box>
        <Divider my={{ base: "24px", lg: "42px" }} />
        <Flex flexDirection="column" py={{ base: 6, lg: 10 }} gap={8}>
          <Text
            w="full"
            textAlign="center"
            fontWeight="500"
            lineHeight="24px"
            fontSize="16px"
            color="#5F5D5A"
          >
            Our Mentors are from small startups to the world’s largest companies
          </Text>
          <Flex
            w="full"
            gap={4}
            justifyContent="space-between"
            flexDirection={{ base: "column", lg: "row" }}
            alignItems="center"
          >
            <Image
              h="45px"
              objectFit="scale-down"
              src="/assets/images/about/brand1.png"
            />
            <Image
              h="45px"
              objectFit="scale-down"
              src="/assets/images/about/brand2.png"
            />
            <Image
              h="45px"
              objectFit="scale-down"
              src="/assets/images/about/brand3.png"
            />
            <Image
              h="45px"
              objectFit="scale-down"
              src="/assets/images/about/brand4.png"
            />
            <Image
              h="45px"
              objectFit="scale-down"
              src="/assets/images/about/brand5.jpg"
            />
            <Image
              h="45px"
              objectFit="scale-down"
              src="/assets/images/about/brand6.png"
            />
          </Flex>
        </Flex>
        <Box
          py={{ base: 6, lg: "42px" }}
          px={{ lg: 8 }}
          display="grid"
          gridTemplateAreas={{
            base: `"knowUsTitle"
          "knowUsText"
          "knowUsImage"
          `,
            lg: `"knowUsTitle knowUsImage"
          "knowUsText knowUsImage"
          `,
          }}
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gridTemplateRows={{ lg: "96px auto" }}
          columnGap={{ lg: "70px" }}
          rowGap="0"
        >
          <Flex gridArea="knowUsTitle" flexDirection="column">
            <Text
              color="#619D1F"
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
            >
              Get to know us
            </Text>
            <Text
              mt={3}
              fontWeight="600"
              lineHeight={{ base: "38px", lg: "60x" }}
              fontSize={{ base: "30px", lg: "48px" }}
              color="#231509"
            >
              We’re just getting started
            </Text>
          </Flex>
          <Flex gridArea="knowUsText" flexDirection="column">
            <Text>
              O&apos;Mentors brings value by connecting you with top industry
              experts who provide personalized, one-on-one guidance tailored to
              your unique career goals.
            </Text>
            <Text mt={3}>
              Whether you&apos;re transitioning into Data Science or looking to
              advance, our platform offers a flexible, secure, and efficient way
              to gain insights, build skills, and accelerate your professional
              growth.
            </Text>
            <Flex
              flexDirection={{ base: "column", lg: "row" }}
              gap={3}
              mt={{ base: 8, lg: 10 }}
            >
              <Button
                w={{ base: "full", lg: "176px" }}
                h="48px"
                variant="outline"
                colorScheme="black"
                borderColor="#D5D7DA"
                color="#414651"
              >
                Explore all mentors
              </Button>
              <Button
                w={{ base: "full", lg: "176px" }}
                h="48px"
                colorScheme="brand"
              >
                Become a mentor
              </Button>
            </Flex>
          </Flex>
          <Image
            gridArea="knowUsImage"
            aspectRatio={1 / 1}
            objectFit="cover"
            src="/assets/images/about/about4.jpg"
            w="full"
          />
        </Box>
        <Flex gap={4} flexDirection="column" alignItems="center">
          <Text
            color="#619D1F"
            fontWeight="600"
            fontSize="16px"
            lineHeight="24px"
          >
            We’re hiring!
          </Text>
          <Text
            fontWeight="700"
            lineHeight={{ base: "38px", lg: "60x" }}
            fontSize={{ base: "30px", lg: "36px" }}
            color="#181D27"
          >
            Meet our team
          </Text>
          <Text
            fontSize={{ base: "18px", lg: "20px" }}
            lineHeight={{ base: "28px", lg: "30px" }}
            fontWeight={{ base: "400" }}
            color="#5F5D5A"
            maxW="768px"
            textAlign="center"
          >
            Our philosophy is simple — hire a team of diverse, passionate people
            and foster a culture that empowers you to do you best work.
          </Text>
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            gap={3}
            w="full"
            justifyContent="center"
            mt={{ base: 8, lg: 10 }}
          >
            <Button
              w={{ base: "full", lg: "176px" }}
              h="48px"
              variant="outline"
              colorScheme="black"
              borderColor="#D5D7DA"
              color="#414651"
            >
              About us
            </Button>
            <Button
              w={{ base: "full", lg: "176px" }}
              h="48px"
              colorScheme="brand"
            >
              Open positions
            </Button>
          </Flex>
        </Flex>
        <Flex
          mx="auto"
          gap={{ base: 6, lg: 8 }}
          flexDirection={{ base: "column", lg: "row" }}
          pt={{ base: "48px", lg: "64px" }}
          pb={{ base: 6, lg: 10 }}
        >
          <Flex
            bg="#FAFAFA"
            alignItems="center"
            p={6}
            flexDirection="column"
            gap={5}
          >
            <Image src="/assets/images/about/avatar1.jpg" boxSize="80px" />
            <Flex gap={4} flexDirection="column" alignItems="center">
              <Flex flexDirection="column" alignItems="center">
                <Text
                  lineHeight="28px"
                  fontSize="18px"
                  fontWeight="600"
                  color="#231509"
                >
                  Saeed Mirshekari
                </Text>
                <Text
                  lineHeight="24px"
                  fontSize="16px"
                  fontWeight="400"
                  color="#619D1F"
                >
                  Founder & Director
                </Text>
                <Text
                  lineHeight="24px"
                  fontSize="14px"
                  fontWeight="400"
                  color="#5F5D5A"
                  mt={2}
                >
                  Ideation | Planning | Execution | Growth
                </Text>
              </Flex>
              <Image src="/assets/images/about/linkden.jpg" boxSize={5} />
            </Flex>
          </Flex>
          <Flex
            bg="#FAFAFA"
            alignItems="center"
            p={6}
            flexDirection="column"
            gap={5}
          >
            <Image src="/assets/images/about/avatar2.jpg" boxSize="80px" />
            <Flex gap={4} flexDirection="column" alignItems="center">
              <Flex flexDirection="column" alignItems="center">
                <Text
                  lineHeight="28px"
                  fontSize="18px"
                  fontWeight="600"
                  color="#231509"
                >
                  Cinna Karimi
                </Text>
                <Text
                  lineHeight="24px"
                  fontSize="16px"
                  fontWeight="400"
                  color="#619D1F"
                >
                  Product Developer
                </Text>
                <Text
                  lineHeight="24px"
                  fontSize="14px"
                  fontWeight="400"
                  color="#5F5D5A"
                  mt={2}
                >
                  Python | Typescript | Vercel | Supabase
                </Text>
              </Flex>
              <Image src="/assets/images/about/linkden.jpg" boxSize={5} />
            </Flex>
          </Flex>
          <Flex
            bg="#FAFAFA"
            alignItems="center"
            p={6}
            flexDirection="column"
            gap={5}
          >
            <Image src="/assets/images/about/avatar3.jpg" boxSize="80px" />
            <Flex gap={4} flexDirection="column" alignItems="center">
              <Flex flexDirection="column" alignItems="center">
                <Text
                  lineHeight="28px"
                  fontSize="18px"
                  fontWeight="600"
                  color="#231509"
                >
                  Pegah Habibi
                </Text>
                <Text
                  lineHeight="24px"
                  fontSize="16px"
                  fontWeight="400"
                  color="#619D1F"
                >
                  Product Designer
                </Text>
                <Text
                  lineHeight="24px"
                  fontSize="14px"
                  fontWeight="400"
                  color="#5F5D5A"
                  mt={2}
                >
                  User Experience | User Interface | Figma
                </Text>
              </Flex>
              <Image src="/assets/images/about/linkden.jpg" boxSize={5} />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          w="full"
          bg="#FAFAFA"
          py={{ base: 6, lg: 10 }}
          px={{ lg: 8 }}
          flexDirection="column"
        >
          <Flex gap={3} flexDirection="column" alignItems="center" flex="1">
            <Text
              fontSize={{ base: "14px", lg: "16px" }}
              fontWeight="600"
              lineHeight={{ base: "20px", lg: "24px" }}
              color="#619D1F"
            >
              Our values
            </Text>
            <Text
              fontSize={{ base: "30px", lg: "36px" }}
              lineHeight={{ base: "38px", lg: "44px" }}
              fontWeight="600"
              color="#231509"
              textAlign="center"
            >
              How we work at O’Mentors
            </Text>
            <Text
              mt={1}
              fontWeight="400"
              fontSize={{ base: "18px", lg: "20px" }}
              textAlign="center"
              lineHeight={{ base: "28px", lg: "30px" }}
              color="#535862"
            >
              Our shared values keep us connected and guide us as one team.
            </Text>
          </Flex>
          <Box
            display="grid"
            gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
            columnGap={8}
            rowGap={{ base: 10, lg: "64px" }}
            mt={{ base: 6, lg: 10 }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Image
                src="/assets/images/about/hire1.svg"
                boxSize={{ base: "40px", lg: "48px" }}
              />
              <Text
                lineHeight={{ base: "28px", lg: "30px" }}
                fontSize={{ base: "18px", lg: "20px" }}
                fontWeight="500"
                mt={{ base: 4, lg: 5 }}
                color="#231509"
              >
                Care about our team
              </Text>
              <Text
                textAlign="center"
                color="#5F5D5A"
                mt={{ base: 1, lg: 2 }}
                fontSize="16px"
                lineHeight="24px"
                fontWeight="400"
              >
                Understand what matters to our employees. Give them what they
                need to do their best work.
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Image
                src="/assets/images/about/hire2.svg"
                boxSize={{ base: "40px", lg: "48px" }}
              />
              <Text
                lineHeight={{ base: "28px", lg: "30px" }}
                fontSize={{ base: "18px", lg: "20px" }}
                fontWeight="500"
                mt={{ base: 4, lg: 5 }}
                color="#231509"
              >
                Be excellent to each other
              </Text>
              <Text
                textAlign="center"
                color="#5F5D5A"
                mt={{ base: 1, lg: 2 }}
                fontSize="16px"
                lineHeight="24px"
                fontWeight="400"
              >
                No games. No bullshit. We rely on our peers to improve. Be open,
                honest and kind.
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Image
                src="/assets/images/about/hire3.svg"
                boxSize={{ base: "40px", lg: "48px" }}
              />
              <Text
                lineHeight={{ base: "28px", lg: "30px" }}
                fontSize={{ base: "18px", lg: "20px" }}
                fontWeight="500"
                mt={{ base: 4, lg: 5 }}
                color="#231509"
              >
                Pride in what we do
              </Text>
              <Text
                textAlign="center"
                color="#5F5D5A"
                mt={{ base: 1, lg: 2 }}
                fontSize="16px"
                lineHeight="24px"
                fontWeight="400"
              >
                Value quality and integrity in everything we do. At all times.
                No exceptions.
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Image
                src="/assets/images/about/hire4.svg"
                boxSize={{ base: "40px", lg: "48px" }}
              />
              <Text
                lineHeight={{ base: "28px", lg: "30px" }}
                fontSize={{ base: "18px", lg: "20px" }}
                fontWeight="500"
                mt={{ base: 4, lg: 5 }}
                color="#231509"
              >
                Don&apos;t #!&$ the customer
              </Text>
              <Text
                textAlign="center"
                color="#5F5D5A"
                mt={{ base: 1, lg: 2 }}
                fontSize="16px"
                lineHeight="24px"
                fontWeight="400"
              >
                Understand customers&apos; stated and unstated needs. Make them
                wildly successful.
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Image
                src="/assets/images/about/hire5.svg"
                boxSize={{ base: "40px", lg: "48px" }}
              />
              <Text
                lineHeight={{ base: "28px", lg: "30px" }}
                fontSize={{ base: "18px", lg: "20px" }}
                fontWeight="500"
                mt={{ base: 4, lg: 5 }}
                color="#231509"
              >
                Do the impossible
              </Text>
              <Text
                textAlign="center"
                color="#5F5D5A"
                mt={{ base: 1, lg: 2 }}
                fontSize="16px"
                lineHeight="24px"
                fontWeight="400"
              >
                Be energized by difficult problems. Revel in unknowns. Ask
                &qoute;Why?&qoute;, but always question, &qoute;Why not?&qoute;
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Image
                src="/assets/images/about/hire6.svg"
                boxSize={{ base: "40px", lg: "48px" }}
              />
              <Text
                lineHeight={{ base: "28px", lg: "30px" }}
                fontSize={{ base: "18px", lg: "20px" }}
                fontWeight="500"
                mt={{ base: 4, lg: 5 }}
                color="#231509"
              >
                Sweat the small stuff
              </Text>
              <Text
                textAlign="center"
                color="#5F5D5A"
                mt={{ base: 1, lg: 2 }}
                fontSize="16px"
                lineHeight="24px"
                fontWeight="400"
              >
                We believe the best products come from the best attention to
                detail. Sweat the small stuff.
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          flexDirection="column"
          py={{ base: 6, lg: 10 }}
        >
          <Text
            color="#619D1F"
            py={1}
            px={{ base: 2.5, lg: 3 }}
            borderRadius="16px"
            bg="#F9F5FF"
            fontSize={{ base: "14px" }}
            lineHeight={{ base: "20px" }}
            fontWeight="500"
          >
            Open positions
          </Text>
          <Text
            fontSize={{ base: "30px", lg: "36px" }}
            lineHeight={{ base: "38px", lg: "44px" }}
            fontWeight="600"
            color="#231509"
            textAlign="center"
            mt={4}
          >
            We’re looking for talented people
          </Text>
          <Text
            mt={4}
            fontWeight="400"
            fontSize={{ base: "18px", lg: "20px" }}
            textAlign="center"
            lineHeight={{ base: "28px", lg: "30px" }}
            color="#535862"
          >
            We’re a 100% remote team spread all across the world. Join us!
          </Text>
          <Image
            h={{ base: "240px", lg: "400px" }}
            src="/assets/images/about/about7.png"
          />
          <Flex
            flexDirection="column"
            w="full"
            justifyContent="start"
            mt={{ base: 6, lg: 10 }}
            gap={{ base: 5, lg: 8 }}
          >
            <Text
              lineHeight={{ base: "28px", lg: "30px" }}
              fontSize={{ base: "18px", lg: "20px" }}
              fontWeight="600"
              color="#231509"
            >
              Design
            </Text>
            <Flex
              p={6}
              border="1px solid #E9EAEB"
              borderRadius="16px"
              flexDirection="column"
            >
              <Text
                color="#231509"
                fontSize="18px"
                fontWeight="600"
                lineHeight="28px"
              >
                Product Designer
              </Text>
              <Text
                mt={2}
                lineHeight="24px"
                fontSize="16px"
                fontWeight="400"
                color="#5F5D5A"
              >
                We’re looking for a mid-level product designer to join our team.
              </Text>
              <Flex color="#5F5D5A" mt={8} gap={6}>
                <Text display="flex" alignItems="center" gap={2}>
                  <Icon as={Clock} boxSize={5} />
                  <Text as="span">Full-time</Text>
                </Text>
                <Text display="flex" alignItems="center" gap={2}>
                  <Icon as={DollarCircle} boxSize={5} />
                  <Text as="span">50k - 100k</Text>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
}
