import { ElementRef, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  useColorModeValue,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { SearchNormal } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { ApplicationCard } from "@/components/application-card";
import { PUBLIC_ROUTES } from "@/constants/routes";
import { failedToast, SharedAPI, useAuthStore } from "@/services";
import { ApplicationResponseStatusTypes, ApplicationType } from "@/types";
import { filterByName } from "@/utils/filter-by-name";
import { useSyncFormWithQueryParams } from "@/utils/sync-params";

import { AuthorDetails } from "./author-details";
import { CancelApplicationModal } from "./cancel-application-modal";
import { getApplicationsQuery } from "./loading";
import { RespondApplicationModal } from "./respond-application-modal";

export function ApplicationPage() {
  const [queryParams, setQueryParams] =
    useSyncFormWithQueryParams<Partial<SharedAPI.GetApplicationsFilters>>();

  const { data: initialData, isFetching } =
    useQuery<SharedAPI.GetApplicationsResponse>(
      getApplicationsQuery({ archived: queryParams.archived || false }),
    );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const role = useAuthStore((state) => state.user?.role);

  const initialRef = useRef<ElementRef<"textarea">>(null);

  const [mode, setMode] = useState<
    ApplicationResponseStatusTypes | "cancel" | "details" | undefined
  >();
  const [selected, setSelected] = useState<ApplicationType | undefined>();

  const { register, watch } = useForm<{ query: string }>({
    defaultValues: { query: "" },
  });

  const [searchParams] = useSearchParams();
  const isPaymentFailed = searchParams.get("success") === "false";

  useLayoutEffect(() => {
    if (isPaymentFailed) {
      failedToast({
        title: "Application payment failed or canceled",
        description: "Please try again",
      });
    }
  }, [isPaymentFailed]);

  const isMentee = role === "mentee";

  const filteredApplications = initialData?.filter(
    (application) =>
      filterByName(
        isMentee
          ? application.mentor?.first_name || ""
          : application.mentee?.first_name || "",
        watch("query"),
      ) ||
      filterByName(
        isMentee
          ? application.mentor?.last_name || ""
          : application.mentee?.last_name || "",
        watch("query"),
      ),
  );

  const handleShowDetails = (selectedApplication: ApplicationType) => {
    setSelected(selectedApplication);
    setMode("details");
    onOpen();
  };

  const handleRespondApplication = (
    response: ApplicationResponseStatusTypes,
    selectedApplication: ApplicationType,
  ) => {
    setSelected(selectedApplication);
    setMode(response);
    onOpen();
  };

  const handleCancelApplication = (selectedApplication: ApplicationType) => {
    setSelected(selectedApplication);
    setMode("cancel");
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    setMode(undefined);
    onClose();
  };

  return (
    <Flex
      boxSize="full"
      px={{ base: 0, md: 8 }}
      py={{ base: 0, md: 10 }}
      direction="column"
      align="flex-start"
      justify="center"
      bg={useColorModeValue("white", "gray.900")}
      color={useColorModeValue("black", "gray.200")}
    >
      <Flex
        w="full"
        px={{ base: 2, md: 0 }}
        py={{ base: 4, md: 0 }}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        gap={6}
      >
        <Heading fontSize="3xl" display={{ base: "none", md: "block" }}>
          Applications
        </Heading>
        <Stack w={{ base: "full", md: "auto" }} direction="row">
          <InputGroup w="full" gap={5}>
            <Input
              ps={10}
              placeholder="Search"
              borderRadius="lg"
              borderColor="gray.400"
              color="gray.500"
              _placeholder={{
                color: "gray.500",
              }}
              _focus={{
                bg: "whiteAlpha.300",
              }}
              {...register("query")}
            />
            <InputLeftElement>
              <Icon as={SearchNormal} boxSize={5} color="gray.500" />
            </InputLeftElement>
          </InputGroup>
          {role === "mentee" && (
            <Button
              as={Link}
              to={PUBLIC_ROUTES.ALL_MENTORS}
              colorScheme="brand"
            >
              Apply
            </Button>
          )}
        </Stack>
      </Flex>
      <Tabs
        defaultIndex={queryParams.archived ? 1 : 0}
        onChange={(index) => setQueryParams({ archived: Boolean(index) })}
        boxSize="full"
        px={{ base: 2, md: 0 }}
        py={{ base: 3, md: 6 }}
      >
        <TabList fontWeight="bold" color="#717680" gap={5}>
          <Tab _selected={{ color: "brand.500" }} px={2}>
            New
          </Tab>
          <Tab _selected={{ color: "brand.500" }} px={2}>
            Archived
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="brand.500"
          borderRadius="1px"
        />
        <TabPanels boxSize="full">
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : filteredApplications?.length ? (
              <Wrap
                shouldWrapChildren
                direction={{ base: "column", md: "row" }}
                spacing={6}
                py={10}
              >
                {filteredApplications.map((application) => (
                  <ApplicationCard
                    key={application.id}
                    w="full"
                    isMentee={isMentee}
                    application={application}
                    onDetail={handleShowDetails}
                    onCancel={handleCancelApplication}
                    onRespond={handleRespondApplication}
                  />
                ))}
              </Wrap>
            ) : (
              <EmptyState name="application" />
            )}
          </Flex>
        </TabPanels>
      </Tabs>
      {selected &&
        (mode === "details" ? (
          <AuthorDetails
            isMentee={isMentee}
            application={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === "cancel" ? (
          <CancelApplicationModal
            key={+isOpen}
            initialRef={initialRef}
            selectedApplication={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode ? (
          <RespondApplicationModal
            key={+isOpen}
            initialRef={initialRef}
            selectedApplication={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
            action={mode}
          />
        ) : (
          <></>
        ))}
    </Flex>
  );
}
