import { MutableRefObject } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { MenteeAPI, successToast } from "@/services";
import { GoalType } from "@/types";

export function ModifyGoalModal({
  initialRef,
  finalRef,
  selectedGoal,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedGoal?: GoalType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<MenteeAPI.CreateGoalPayload>(
    selectedGoal
      ? {
          defaultValues: selectedGoal,
        }
      : {},
  );

  const handleSubmitGoal = handleSubmit(
    async ({
      title,
      description,
      expectations,
      expected_timeline,
      meeting_frequency,
      focus_hpw,
    }) => {
      try {
        const res = selectedGoal
          ? await MenteeAPI.editGoal(selectedGoal.id, {
              title,
              description,
              expectations,
              expected_timeline,
              meeting_frequency,
              focus_hpw,
            })
          : await MenteeAPI.createGoal({
              title,
              description,
              expectations,
              expected_timeline,
              meeting_frequency,
              focus_hpw,
            });

        if (res.status === 200) {
          successToast({
            title: `Your goal ${
              selectedGoal ? "updated" : "created"
            } successfully!`,
          });
          onClose();

          await queryClient.invalidateQueries({
            queryKey: ["goals"],
          });
        }
      } catch (error) {
        console.error({ error });
      }
    },
  );

  const { ref: titleInputRef, ...titleInputRegister } = register("title", {
    required: "This field is required",
  });

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      // isCentered
      size={{ base: "full", sm: "xl" }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmitGoal} noValidate>
        <ModalHeader>
          {selectedGoal ? "Edit goal" : "Create new goal"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <FormControl isRequired id="title" isInvalid={Boolean(errors.title)}>
            <FormLabel htmlFor="title" fontWeight="normal">
              Title
            </FormLabel>
            <Input
              placeholder="Expert in Data Engineering"
              isDisabled={isSubmitting}
              {...titleInputRegister}
              ref={(e) => {
                titleInputRef(e);

                // it need to support focus on first input
                if (e) {
                  initialRef.current = e;
                }
              }}
            />
            {errors.title && (
              <FormErrorMessage>{errors.title.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="description"
            isInvalid={Boolean(errors.description)}
          >
            <FormLabel htmlFor="description" fontWeight="normal">
              Description
            </FormLabel>
            <Input
              placeholder="I'd like to get my resume reviewed for my next Data Scientist job application"
              isDisabled={isSubmitting}
              {...register("description", {
                required: "This field is required",
              })}
            />
            {errors.description && (
              <FormErrorMessage>{errors.description.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="expectations"
            isInvalid={Boolean(errors.expectations)}
          >
            <FormLabel htmlFor="expectations" fontWeight="normal">
              Expected Results
            </FormLabel>
            <Input
              placeholder="I'd expect my mentor to review and make some suggestions to improve my resume"
              isDisabled={isSubmitting}
              {...register("expectations", {
                required: "This field is required",
              })}
            />
            {errors.expectations && (
              <FormErrorMessage>{errors.expectations.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="expected_timeline"
            isInvalid={Boolean(errors.expected_timeline)}
          >
            <FormLabel htmlFor="expected_timeline" fontWeight="normal">
              Expected Timeline
            </FormLabel>
            <Input
              placeholder="I'd expect results within a few weeks"
              isDisabled={isSubmitting}
              {...register("expected_timeline", {
                required: "This field is required",
              })}
            />
            {errors.expected_timeline && (
              <FormErrorMessage>
                {errors.expected_timeline.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <Flex gap={4} direction={{ base: "column", sm: "row" }}>
            <FormControl
              isRequired
              id="meeting_frequency"
              isInvalid={Boolean(errors.meeting_frequency)}
            >
              <FormLabel htmlFor="meeting_frequency" fontWeight="normal">
                Expected Meetings with Mentor per Month
              </FormLabel>
              <Input
                type="number"
                isDisabled={isSubmitting}
                placeholder="0"
                min={0}
                {...register("meeting_frequency", {
                  required: "This field is required",
                })}
              />
              {errors.meeting_frequency && (
                <FormErrorMessage>
                  {errors.meeting_frequency.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isRequired
              id="focus_hpw"
              isInvalid={Boolean(errors.focus_hpw)}
            >
              <FormLabel htmlFor="focus_hpw" fontWeight="normal">
                My Available Time to Work on This (Hours per Week)
              </FormLabel>
              <Input
                type="number"
                isDisabled={isSubmitting}
                placeholder="0"
                min={0}
                {...register("focus_hpw", {
                  required: "This field is required",
                })}
              />
              {errors.focus_hpw && (
                <FormErrorMessage>{errors.focus_hpw.message}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            colorScheme="brand"
            w="full"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
