import { NavLink } from "react-router";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FlexProps,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Activity,
  Diamonds,
  DocumentText,
  Home,
  type Icon as IconType,
  Lifebuoy,
  Notification,
  Profile,
  Profile2User,
  Setting2,
  Warning2,
} from "iconsax-react";

const iconMap: Record<string, IconType> = {
  Home: Home,
  Teacher: Profile2User,
  Profile: Profile,
  Activity: Activity,
  Warning2: Warning2,
  Setting2: Setting2,
  DocumentText: DocumentText,
  Diamonds: Diamonds,
  Lifebuoy: Lifebuoy,
  Notification: Notification,
};

interface NavItemProps extends FlexProps {
  icon: string;
  children: React.ReactNode;
  href?: string;
  isExternal?: boolean;
  variant?: "sidebar" | "mobile";
  isText?: boolean;
}

export function NavItem({
  icon,
  href = "#",
  isExternal,
  children,
  variant = "sidebar",
  isText = true,
  ...rest
}: NavItemProps) {
  const isMobile = variant === "mobile";
  const textColor = useColorModeValue("#414651", "white");

  return (
    <Box
      as={NavLink}
      to={href}
      {...(isExternal
        ? {
            target: "_blank",
            rel: "noopener noreferrer",
          }
        : {})}
      w="full"
      textDecoration="none"
      color={textColor}
      _focus={{ boxShadow: "none" }}
      flex={isMobile ? 1 : "unset"}
      position={isMobile ? "relative" : "static"}
    >
      {({ isActive }: { isActive: boolean }) => (
        <>
          {isMobile && isActive && (
            <Box
              position="absolute"
              top={0}
              left="50%"
              transform="translateX(-50%)"
              width="70%"
              height="2px"
              bg="brand.400"
              borderRadius="full"
            />
          )}
          <Flex
            align="center"
            px={isMobile ? 2 : 0}
            py={isMobile ? 2 : 3}
            borderRadius={isMobile ? "none" : "lg"}
            role="group"
            cursor="pointer"
            direction={isMobile ? "column" : "row"}
            justify={isMobile ? "center" : "flex-start"}
            alignItems="center"
            gap={isMobile ? 1 : 3}
            {...(isActive
              ? {
                  // bg: isMobile ? "transparent" : "#e6f1da",
                  color: "brand.500",
                }
              : {})}
            _hover={{
              color: "brand.700",
            }}
            {...rest}
          >
            {isExternal ? (
              <ExternalLinkIcon mx="4px" />
            ) : (
              icon && (
                <Icon
                  as={iconMap[`${icon}`]}
                  boxSize={6}
                  _groupHover={{
                    color: "brand.700",
                  }}
                />
              )
            )}
            {isText ? (
              <Text
                w={isMobile ? "fit-content" : "full"}
                fontWeight="bold"
                fontSize={isMobile ? "xs" : "md"}
              >
                {children}
              </Text>
            ) : (
              children
            )}
          </Flex>
        </>
      )}
    </Box>
  );
}
