import { Link } from "react-router";
import {
  Avatar,
  AvatarBadge,
  Badge,
  BoxProps,
  Divider,
  Flex,
  IconButton,
  Image,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Logout } from "iconsax-react";

import { SIDE_BAR_WIDTH } from "@/constants/config";
import {
  AUTH_ROUTES,
  DASHBOARD_ROUTES,
  PUBLIC_ROUTES,
} from "@/constants/routes";
import { getNotificationListQuery } from "@/pages/dashboard";
import { NotificationAPI, useAuthStore } from "@/services";

import { MenteeLinkItems, MentorLinkItems } from "./config";
import { NavItem } from "./nav-item";

interface SidebarProps extends BoxProps {}

export function SidebarContent({ ...rest }: SidebarProps) {
  const user = useAuthStore((state) => state.user);

  const { data: initialData, isLoading } =
    useQuery<NotificationAPI.GetNotificationList>(getNotificationListQuery());

  const unreadCount =
    initialData?.filter((item) => item.status === "unread").length || 0;
  const moreThanNine = unreadCount > 9;

  return (
    <Flex
      w={SIDE_BAR_WIDTH}
      h="full"
      px={6}
      py={8}
      direction="column"
      gap={6}
      justifyContent="space-between"
      pos="fixed"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      transition="3s ease"
      {...rest}
    >
      <Flex direction="column" gap={6}>
        <Flex as={Link} to={PUBLIC_ROUTES.HOME}>
          <Image src="/assets/images/logo_full.svg" alt="O'Mentors" h={8} />
        </Flex>
        <Flex direction="column">
          {(user?.role === "mentee" ? MenteeLinkItems : MentorLinkItems)
            .slice(0, -1)
            .map((item) => (
              <NavItem
                key={item.label}
                href={item.href}
                icon={item.icon}
                isExternal={item.isExternal}
              >
                {item.label}
              </NavItem>
            ))}
          <NavItem
            key="Notification"
            href={DASHBOARD_ROUTES.NOTIFICATIONS}
            icon="Notification"
            w="full"
            isText={false}
          >
            <Flex w="full" alignItems="center" justifyContent="space-between">
              <Text w="full" fontWeight="bold" fontSize="md">
                Notifications
              </Text>

              {isLoading ? (
                <Spinner
                  size="sm"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="red.500"
                />
              ) : (
                unreadCount && (
                  <Badge
                    h={6}
                    px={2}
                    bg="red.500"
                    color="white"
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text>{moreThanNine ? "9+" : unreadCount}</Text>
                  </Badge>
                )
              )}
            </Flex>
          </NavItem>
        </Flex>
      </Flex>
      <Flex direction="column" gap={6}>
        <Flex direction="column">
          <NavItem
            key="Lifebuoy"
            href={DASHBOARD_ROUTES.SUPPORT}
            icon="Lifebuoy"
          >
            Support
          </NavItem>
          <NavItem
            key="Settings"
            href={DASHBOARD_ROUTES.SETTINGS}
            icon="Setting2"
          >
            Settings
          </NavItem>
        </Flex>
        <Divider />
        <Flex alignContent="center" justifyContent="space-between" gap={2}>
          <Flex alignItems="center" gap={3} overflow="hidden">
            <Avatar
              src={user?.avatar}
              name={user?.full_name}
              bgColor="brand.300"
              boxSize={10}
              cursor="pointer"
            >
              <AvatarBadge
                boxSize={3}
                bg="green.500"
                borderColor={useColorModeValue("white", "gray.900")}
                right={0}
                bottom={0}
                border="3px solid white"
              />
            </Avatar>
            <Flex direction="column" justifyContent="center" overflow="hidden">
              <Text
                fontSize="md"
                fontWeight="bold"
                noOfLines={1}
                textOverflow="ellipsis"
                whiteSpace="pre-line"
              >
                {user?.full_name}
              </Text>
              <Text
                fontSize="sm"
                fontWeight="normal"
                color="gray.600"
                noOfLines={1}
                textOverflow="ellipsis"
                whiteSpace="pre-line"
              >
                {user?.email}
              </Text>
            </Flex>
          </Flex>
          <IconButton
            as={Link}
            to={AUTH_ROUTES.LOGOUT}
            boxSize={10}
            p={2}
            color="#717680"
            variant="ghost"
            aria-label="support"
          >
            <Logout />
          </IconButton>
        </Flex>
      </Flex>
    </Flex>
  );
}
