import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { DESKTOP_FOOTER_HEIGHT, HEADER_HEIGHT } from "@/constants/config";
import { EMAIL_REGEX } from "@/constants/regex";
import { AUTH_ROUTES } from "@/constants/routes";
import { AuthAPI, failedToast, successToast } from "@/services";
import { ErrorServerResponse } from "@/services/api/axios";

type FormValues = {
  email: string;
};

export function ForgetPasswordPage() {
  const navigate = useNavigate();

  const { mutateAsync: sendPasswordResetLink, isPending: isSubmitting } =
    useMutation({
      mutationKey: ["forget-password"],
      mutationFn: (email: string) => AuthAPI.forgetPassword({ email }),
      onSuccess: (data) => {
        successToast({
          title: "Password reset link sent",
          description: data?.message,
        });
        navigate(AUTH_ROUTES.LOGIN);
      },
      onError: (error: AxiosError<ErrorServerResponse>) =>
        failedToast({
          title: "Password reset failed",
          description: error.response?.data.message,
        }),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const handleSubmitReset = handleSubmit(
    async ({ email }) => await sendPasswordResetLink(email),
  );

  return (
    <Flex
      as="form"
      onSubmit={handleSubmitReset}
      boxSize="full"
      minH={{
        base: `calc(100vh - ${HEADER_HEIGHT * 4}px)`,
        md: `calc(100vh - ${(HEADER_HEIGHT + DESKTOP_FOOTER_HEIGHT) * 4}px)`,
      }}
      px={{ base: 4, md: 10 }}
      py={{ base: 12, md: 24 }}
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        w="full"
        maxW="360px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={8}
      >
        <Flex w="full" direction="column" alignItems="center" gap={4}>
          <Image src="/assets/images/logo.svg" alt="O'Mentors" w={10} />
          <Flex direction="column" alignItems="center" gap={2}>
            <Text fontSize="24px" fontWeight="bold">
              Forgot your password
            </Text>
            <Text fontSize="16px" fontWeight="normal" color="gray.600">
              Enter your email to reset your password
            </Text>
          </Flex>
        </Flex>
        <Flex w="full" direction="column" alignItems="center" gap={5}>
          <FormControl id="email" isInvalid={Boolean(errors.email)}>
            <FormLabel fontSize="14px">Email</FormLabel>
            <Input
              required
              type="email"
              placeholder="Email address"
              {...register("email", {
                required: "Email is required for sending password reset link",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <Button
          type="submit"
          isLoading={isSubmitting}
          loadingText="Sending..."
          w="full"
          borderRadius="lg"
          colorScheme="brand"
          // bg="button.green"
          // color="white"
          // _hover={{
          //   bg: "brand.500",
          // }}
        >
          Send reset link
        </Button>
        <Text fontSize="14px" fontWeight="normal" color="gray.600">
          Don’t have an account?{" "}
          <Button
            variant="link"
            as={Link}
            to={AUTH_ROUTES.REGISTER}
            fontSize="inherit"
            color="brand.600"
          >
            Sign up
          </Button>
        </Text>
      </Flex>
    </Flex>
  );
}
