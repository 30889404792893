import { ElementRef, useRef, useState } from "react";
import {
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { EmptyState, LoadingState } from "@/components";
import { BackgroundType } from "@/types";

import { EditEducationModal } from "./education/edit-education-modal";
import { EditJobModal } from "./job/edit-job-modal";
import { EditSkillModal } from "./skills/edit-skill-modal";
import { EditToolModal } from "./tools/edit-tool-modal";
import { Education } from "./education";
import { Job } from "./job";
import { getBackgroundQuery } from "./loading";
import { Skills } from "./skills";
import { Tools } from "./tools";

export function BackgroundPage() {
  const { data: initialData, isFetching } = useQuery<BackgroundType>(
    getBackgroundQuery() as UseQueryOptions<BackgroundType>,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [section, setSection] = useState<
    "education" | "job" | "skills" | "tools" | undefined
  >();

  const initialRef = useRef<ElementRef<"input">>(null);

  const handleEditSection = (selected: typeof section) => {
    setSection(selected);
    onOpen();
  };
  const handleCloseEdit = () => {
    setSection(undefined);
    onClose();
  };

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        p={{ base: 5, md: 20 }}
        align="flex-start"
        justify="center"
      >
        <Stack
          spacing={6}
          w="full"
          maxW="3xl"
          bg={useColorModeValue("white", "gray.900")}
          color={useColorModeValue("gray.700", "gray.200")}
          rounded="xl"
          boxShadow="lg"
          p={6}
        >
          <Flex
            pb={4}
            borderBottom={1}
            borderStyle="solid"
            borderColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent="space-between"
          >
            <Heading fontSize={{ base: "2xl", sm: "3xl" }}>
              My Background
            </Heading>
          </Flex>
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : initialData ? (
              <>
                <Education
                  background={initialData}
                  onClick={() => handleEditSection("education")}
                />
                <Job
                  background={initialData}
                  onClick={() => handleEditSection("job")}
                />
                <Skills
                  background={initialData}
                  onClick={() => handleEditSection("skills")}
                />
                <Tools
                  background={initialData}
                  onClick={() => handleEditSection("tools")}
                />
              </>
            ) : (
              <EmptyState name="background" onClick={onOpen} />
            )}
          </Flex>
        </Stack>
      </Flex>
      {initialData && section === "education" && (
        <EditEducationModal
          key={+isOpen}
          initialRef={initialRef}
          background={initialData}
          isOpen={isOpen}
          onClose={handleCloseEdit}
        />
      )}
      {initialData && section === "job" && (
        <EditJobModal
          key={+isOpen}
          initialRef={initialRef}
          background={initialData}
          isOpen={isOpen}
          onClose={handleCloseEdit}
        />
      )}
      {initialData && section === "skills" && (
        <EditSkillModal
          key={+isOpen}
          initialRef={initialRef}
          background={initialData}
          isOpen={isOpen}
          onClose={handleCloseEdit}
        />
      )}
      {initialData && section === "tools" && (
        <EditToolModal
          key={+isOpen}
          initialRef={initialRef}
          background={initialData}
          isOpen={isOpen}
          onClose={handleCloseEdit}
        />
      )}
    </>
  );
}
