import {
  Badge,
  Flex,
  Heading,
  Icon,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tab,
  TabIndicator,
  Table,
  TableContainer,
  TabList,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { Danger, Magicpen, More } from "iconsax-react";

import { useAuthStore } from "@/services";

export function DashboardPage() {
  const user = useAuthStore((state) => state.user);

  return (
    <Flex
      boxSize="full"
      px={{ base: 0, md: 8 }}
      py={{ base: 0, md: 10 }}
      direction="column"
      align="flex-start"
      justify="center"
      bg={useColorModeValue("white", "gray.900")}
      color={useColorModeValue("black", "gray.200")}
    >
      <Flex
        w="full"
        px={{ base: 8, md: 0 }}
        py={{ base: 4, md: 0 }}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        gap={6}
        display={{ base: "none", md: "block" }}
      >
        <Heading fontSize="3xl">Home</Heading>
      </Flex>
      <Tabs
        // defaultIndex={queryParams.archived ? 1 : 0}
        // onChange={(index) => setQueryParams({ archived: Boolean(index) })}
        boxSize="full"
        px={{ base: 8, md: 0 }}
        py={{ base: 3, md: 6 }}
      >
        <TabList fontWeight="bold" color="#717680" gap={5}>
          <Tab _selected={{ color: "brand.500" }} px={2}>
            Stats
          </Tab>
          <Tab isDisabled _selected={{ color: "brand.500" }} px={2}>
            To-dos
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="brand.500"
          borderRadius="1px"
        />
        <TabPanels boxSize="full">
          <Flex
            px={{ base: 4, md: 0 }}
            py={{ base: 6, md: 10 }}
            direction={{ base: "column" }}
            alignItems="center"
            gap={6}
          >
            <Flex w="full" direction="column" gap={1}>
              <Text fontSize="30px" fontWeight="bold">
                Welcome back, {user?.first_name}
              </Text>
              <Text fontSize="16px" fontWeight="normal" color="#535862">
                Track, manage and improve your mentoring experience.
              </Text>
            </Flex>
            <Flex w="full" direction="column" alignItems="center" gap={4}>
              <Text fontSize={{ base: "30px", md: "36px" }} fontWeight="bold">
                Your stats
              </Text>
              <Text
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight="normal"
                color="#535862"
              >
                Keep track of your progress on the platform and find ways to
                improve your stats
              </Text>
              <Flex
                as={StatGroup}
                w="full"
                direction={{ base: "column", lg: "row" }}
                gap={{ base: 5, md: 6 }}
                mt={{ base: 6, md: 2 }}
              >
                <Flex
                  as={Stat}
                  boxSize="full"
                  flex={1}
                  px={{ base: 4, md: 6 }}
                  py={{ base: 5, md: 6 }}
                  direction="column"
                  gap={{ base: 5, md: 6 }}
                  borderRadius="lg"
                  border="1px solid"
                  borderColor="#E9EAEB"
                  box-shadow="0px 1px 2px 0px rgba(10, 13, 18, 0.05)"
                >
                  <Flex w="full" justify="space-between" alignItems="center">
                    <Text as={StatLabel} fontSize="16px" fontWeight="bold">
                      Total Applications
                    </Text>
                    <Icon as={More} transform="rotate(90deg)" />
                  </Flex>
                  <Flex direction="column" gap={4}>
                    <Text
                      as={StatNumber}
                      fontSize={{ base: "30px", md: "36px" }}
                      fontWeight="bold"
                    >
                      0
                    </Text>
                    <Flex as={StatHelpText} alignItems="center">
                      <Icon
                        as={StatArrow}
                        type="increase"
                        color="gray.500"
                        transform="rotate(90deg)"
                      />
                      <Text fontSize="14px">
                        0%{" "}
                        <Text as="span" color="#535862">
                          since last year
                        </Text>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  as={Stat}
                  boxSize="full"
                  flex={1}
                  px={{ base: 4, md: 6 }}
                  py={{ base: 5, md: 6 }}
                  direction="column"
                  gap={{ base: 5, md: 6 }}
                  borderRadius="lg"
                  border="1px solid"
                  borderColor="#E9EAEB"
                  box-shadow="0px 1px 2px 0px rgba(10, 13, 18, 0.05)"
                >
                  <Flex w="full" justify="space-between" alignItems="center">
                    <Text as={StatLabel} fontSize="16px" fontWeight="bold">
                      Total Sessions Booked
                    </Text>
                    <Icon as={More} transform="rotate(90deg)" />
                  </Flex>
                  <Flex direction="column" gap={4}>
                    <Text
                      as={StatNumber}
                      fontSize={{ base: "30px", md: "36px" }}
                      fontWeight="bold"
                    >
                      0
                    </Text>
                    <Flex as={StatHelpText} alignItems="center">
                      <Icon
                        as={StatArrow}
                        type="increase"
                        color="gray.500"
                        transform="rotate(90deg)"
                      />
                      <Text fontSize="14px">
                        0%{" "}
                        <Text as="span" color="#535862">
                          since last year
                        </Text>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  as={Stat}
                  boxSize="full"
                  flex={1}
                  px={{ base: 4, md: 6 }}
                  py={{ base: 5, md: 6 }}
                  direction="column"
                  gap={{ base: 5, md: 6 }}
                  borderRadius="lg"
                  border="1px solid"
                  borderColor="#E9EAEB"
                  box-shadow="0px 1px 2px 0px rgba(10, 13, 18, 0.05)"
                >
                  <Flex w="full" justify="space-between" alignItems="center">
                    <Text as={StatLabel} fontSize="16px" fontWeight="bold">
                      Total Mentors
                    </Text>
                    <Icon as={More} transform="rotate(90deg)" />
                  </Flex>
                  <Flex direction="column" gap={4}>
                    <Text
                      as={StatNumber}
                      fontSize={{ base: "30px", md: "36px" }}
                      fontWeight="bold"
                    >
                      0
                    </Text>
                    <Flex as={StatHelpText} alignItems="center">
                      <Icon
                        as={StatArrow}
                        type="increase"
                        color="gray.500"
                        transform="rotate(90deg)"
                      />
                      <Text fontSize="14px">
                        0%{" "}
                        <Text as="span" color="#535862">
                          since last year
                        </Text>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex w="full" direction="column" alignItems="center" gap={4}>
              <Text fontSize={{ base: "30px", md: "36px" }} fontWeight="bold">
                Your to-do list
              </Text>
              <Text
                fontSize={{ base: "18px", md: "20px" }}
                fontWeight="normal"
                color="#535862"
              >
                Check your to-do list. Remove barriers and make sure you’re all
                set for success
              </Text>
              <TableContainer w="full">
                <Table
                  variant="striped"
                  colorScheme="gray"
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="lg"
                  mt={{ base: 6, md: 2 }}
                >
                  <Thead>
                    <Tr>
                      <Th>Emergency</Th>
                      <Th>Action items</Th>
                      <Th>Error(s)</Th>
                      <Th>About</Th>
                      <Th>Fix it</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Icon as={Danger} boxSize={5} />
                      </Td>
                      <Td>Setup Goals</Td>
                      <Td>
                        <Flex gap={1}>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="red"
                          >
                            Empty
                          </Badge>
                        </Flex>
                      </Td>
                      <Td>
                        Setup at least one goal
                        <br />
                        Only your mentors will be able to see your goals
                      </Td>
                      <Td>
                        <Icon as={Magicpen} boxSize={5} />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Icon as={Danger} boxSize={5} />
                      </Td>
                      <Td>Review Applications</Td>
                      <Td>
                        <Flex gap={1}>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="orange"
                          >
                            Pending
                          </Badge>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="yellow"
                          >
                            Paid
                          </Badge>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="purple"
                          >
                            Label
                          </Badge>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="gray"
                          >
                            +4
                          </Badge>
                        </Flex>
                      </Td>
                      <Td>
                        Manage received applications from mentee
                        <br />
                        Don’t let new applicants hang for too long
                      </Td>
                      <Td>
                        <Icon as={Magicpen} boxSize={5} />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Icon as={Danger} boxSize={5} />
                      </Td>
                      <Td>Review Mentorships</Td>
                      <Td>
                        <Flex gap={1}>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="red"
                          >
                            Canceled
                          </Badge>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="blue"
                          >
                            Refund
                          </Badge>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="purple"
                          >
                            Label
                          </Badge>
                          <Badge
                            px={2}
                            py={0.5}
                            borderRadius="full"
                            fontSize="12px"
                            colorScheme="gray"
                          >
                            +4
                          </Badge>
                        </Flex>
                      </Td>
                      <Td>
                        Manage your current mentorships
                        <br />
                        Don’t let mentees’ requests hang for too long
                      </Td>
                      <Td>
                        <Icon as={Magicpen} boxSize={5} />
                      </Td>
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th></Th>
                      <Th></Th>
                      <Th></Th>
                      <Th></Th>
                      <Th>Page 1 of 1</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </Flex>
          </Flex>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
