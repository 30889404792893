import { PropsWithChildren } from "react";
import { Link } from "react-router";
import { Avatar, Box, BoxProps, Button, Flex, Text } from "@chakra-ui/react";

import { PUBLIC_ROUTES } from "@/constants/routes";

interface ProfileHeaderProps extends BoxProps {
  avatar?: string;
  fullname: string;
  slug: string;
  email: string;
}

export function ProfileHeader({
  avatar,
  fullname,
  slug,
  email,
  children,
  ...rest
}: PropsWithChildren<ProfileHeaderProps>) {
  const profileUrl = `${PUBLIC_ROUTES.MENTOR_PROFILE}/${slug}`;

  return (
    <Box w="full" mt={{ base: 0, md: 10 }} {...rest}>
      <Box
        bgGradient="linear(to-r, brand.400, brand.200)"
        h={{ base: 16, md: 52 }}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "start", md: "center" }}
        gap={4}
        px={{ base: 0, md: 8 }}
      >
        <Avatar
          src={avatar}
          name={fullname}
          boxSize={{ base: 24, md: 40 }}
          bgColor="brand.300"
          border="4px solid white"
          borderRadius="full"
          mt={{ base: -12, md: -8 }}
          size={{ base: "lg", md: "2xl" }}
        />
        <Flex
          w="full"
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems="start"
          gap={4}
        >
          <Flex direction="column" alignItems="start" gap={1}>
            <Text
              fontSize={{ base: "24px", md: "30px" }}
              fontWeight="bold"
              color="gray.900"
            >
              {fullname}
            </Text>
            <Text fontSize="16px" fontWeight="normal" color="gray.600">
              {email}
            </Text>
          </Flex>
          <Flex justifyContent="end" gap={4}>
            {children || (
              <>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}${profileUrl}`,
                    );
                  }}
                  colorScheme="gray"
                  bgColor="white"
                  border="2px solid"
                  borderColor="gray.200"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  Share
                </Button>
                <Button
                  as={Link}
                  to={profileUrl}
                  colorScheme="brand"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  View public profile
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
