import { redirect } from "react-router";

import {
  AUTH_ROUTES,
  DASHBOARD_ROUTES,
  PUBLIC_ROUTES,
} from "@/constants/routes";
import { useAuthStore } from "@/services";

export async function resetPasswordLoader() {
  const { isAuthenticated, user } = useAuthStore?.getState() || {};

  const token = new URLSearchParams(window.location.search).get("token");

  if (isAuthenticated) {
    if (user?.role === "mentee") {
      return redirect(PUBLIC_ROUTES.HOME);
    }
    return redirect(DASHBOARD_ROUTES.HOME);
  } else if (!token) {
    return redirect(AUTH_ROUTES.FORGET_PASSWORD);
  }

  return true;
}
