import { useForm } from "react-hook-form";
import { Link } from "react-router";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { DESKTOP_FOOTER_HEIGHT, HEADER_HEIGHT } from "@/constants/config";
import { EMAIL_REGEX } from "@/constants/regex";
import { PUBLIC_ROUTES } from "@/constants/routes";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  message: string;
  terms: boolean;
};

export function ContactPage() {
  const {
    register: registerField,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
    },
  });

  return (
    <Flex
      boxSize="full"
      minH={{
        base: `full`,
        lg: `calc(100vh - ${(HEADER_HEIGHT + DESKTOP_FOOTER_HEIGHT) * 4}px)`,
      }}
      px={{ base: 0, lg: 20 }}
      py={{ base: 6, lg: 10 }}
      direction={{ base: "column", lg: "row-reverse" }}
      gap={{ base: 0, lg: 8 }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Image
        flex={1}
        src="/assets/images/contact/poster.jpg"
        alt="contact us image"
        w="full"
        // maxH={{ base: "200px", sm: "400px", lg: "720px" }}
        px={{ base: 4, lg: 8 }}
        objectFit="cover"
        objectPosition="center"
        overflow="hidden"
        display={{ base: "none", lg: "block" }}
      />
      <Flex
        flex={1}
        w="full"
        px={{ base: 4, lg: 8 }}
        py={{ base: 6, lg: 0 }}
        pt={{ base: 0, lg: 6 }}
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={{ base: 8, lg: 12 }}
      >
        <Flex
          w="full"
          direction="column"
          justifyContent="center"
          alignItems="start"
          gap={3}
        >
          <Text
            fontSize={{ base: "36px", lg: "50px" }}
            fontWeight="bold"
            color="gray.900"
          >
            Get in touch
          </Text>
          <Text
            fontSize={{ base: "18px", lg: "20px" }}
            fontWeight="normal"
            color="gray.600"
            mt={3}
          >
            Whether you have questions, feedback, or need assistance, the
            O&apos;Mentors team is here to help. Our team will get back to you
            within 24-48 hours. Together, let’s make your mentorship journey
            smooth and impactful!
          </Text>
        </Flex>
        <Flex
          w="full"
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={8}
        >
          <Flex w="full" direction="column" alignItems="start" gap={5}>
            <Flex
              w="full"
              direction={{ base: "column", lg: "row" }}
              alignItems="start"
              gap={5}
            >
              <FormControl
                w="full"
                id="first_name"
                isInvalid={Boolean(errors.first_name)}
              >
                <FormLabel fontSize="14px">First name</FormLabel>
                <Input
                  required
                  type="first_name"
                  placeholder="First name"
                  {...registerField("first_name", {
                    required: "You must provide your first name to submit",
                  })}
                />
                {errors.first_name && (
                  <FormErrorMessage>
                    {errors.first_name.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                w="full"
                id="last_name"
                isInvalid={Boolean(errors.last_name)}
              >
                <FormLabel fontSize="14px">Last name</FormLabel>
                <Input
                  required
                  type="last_name"
                  placeholder="Last name"
                  {...registerField("last_name", {
                    required: "You must provide your last name to submit",
                  })}
                />
                {errors.last_name && (
                  <FormErrorMessage>
                    {errors.last_name.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Flex>

            <FormControl w="full" id="email" isInvalid={Boolean(errors.email)}>
              <FormLabel fontSize="14px">Email address</FormLabel>
              <Input
                required
                type="email"
                placeholder="you@company.com"
                {...registerField("email", {
                  required: "You must provide your email to submit",
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl w="full" id="phone" isInvalid={Boolean(errors.phone)}>
              <FormLabel fontSize="14px">Phone number</FormLabel>
              <InputGroup>
                <InputLeftAddon>+1</InputLeftAddon>
                <Input
                  required
                  type="tel"
                  placeholder="(555) 000-0000"
                  {...registerField("phone", {
                    required: "You must provide a phone to submit",
                    minLength: {
                      value: 8,
                      message: "phone should be at least 8 characters",
                    },
                  })}
                />
              </InputGroup>
              {errors.phone && (
                <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              w="full"
              id="message"
              isInvalid={Boolean(errors.message)}
            >
              <FormLabel fontSize="14px">Message</FormLabel>
              <Textarea
                required
                h={24}
                {...registerField("message", {
                  required: "You must provide your message to submit",
                })}
              />
              {errors.message && (
                <FormErrorMessage>{errors.message.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl w="full" id="terms" isInvalid={Boolean(errors.terms)}>
              <Checkbox
                type="checkbox"
                display="flex"
                alignItems="center"
                justifyContent="start"
                fontSize="14px"
                {...registerField("terms", {
                  required: "You must agree with our privacy policy and terms",
                })}
                color="gray.600"
              >
                You agree to our friendly{" "}
                <Button
                  variant="link"
                  as={Link}
                  to={PUBLIC_ROUTES.PRIVACY_POLICY}
                  fontSize="16px"
                  p={0}
                >
                  privacy policy
                </Button>
              </Checkbox>
              {errors.terms && (
                <FormErrorMessage>{errors.terms.message}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>

          <Button
            type="submit"
            loadingText="Sending..."
            w="full"
            h={12}
            borderRadius="lg"
            colorScheme="brand"
          >
            Send message
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
