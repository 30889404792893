import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router";
import {
  Container,
  Divider,
  Flex,
  Icon,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import { PUBLIC_ROUTES } from "@/constants/routes";

import { JoinUsToday } from "./components/join-us-today";

export function Footer() {
  return (
    <Container
      as="footer"
      variant="banner"
      maxW="full"
      w="full"
      py={{ base: 6, lg: 10 }}
      px={{ base: 4, lg: 28 }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="start"
      gap={{ base: 8, md: 10 }}
      background="rgba(152, 192, 69, 0.10)"
    >
      <JoinUsToday />
      <Divider bg="gray.200" />
      <Flex
        w="full"
        direction={{ base: "column", md: "row" }}
        justifyContent="center"
        alignItems="start"
        gap={{ base: 8, md: 16 }}
      >
        <Flex
          maxW={{ base: "full", md: "320px" }}
          direction="column"
          gap={{ base: 0, md: 2 }}
          alignItems="start"
          justifyContent="center"
        >
          <Flex as={Link} to={PUBLIC_ROUTES.HOME}>
            <Image src="/assets/images/logo_full.svg" alt="O'Mentors" h={8} />
          </Flex>
          <Text fontSize="16px" fontWeight="normal">
            We connect mentees with expert mentors from Fortune 500 companies
            for personalized career growth.
          </Text>
        </Flex>

        <SimpleGrid w="full" columns={{ base: 2, md: 5 }} spacing={10}>
          <Flex direction="column" alignItems="start" gap={3}>
            <Text fontSize="14px" fontWeight="bold" color="gray.500" mb={2}>
              Product
            </Text>
            <Link to={PUBLIC_ROUTES.HOME}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Overview
              </Text>
            </Link>
            <Link to={PUBLIC_ROUTES.HOME}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Features
              </Text>
            </Link>
          </Flex>

          <Flex direction="column" alignItems="start" gap={3}>
            <Text fontSize="14px" fontWeight="bold" color="gray.500" mb={2}>
              Company
            </Text>
            <Link to={PUBLIC_ROUTES.ABOUT}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                About us
              </Text>
            </Link>
            <Link to={PUBLIC_ROUTES.ABOUT}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Careers
              </Text>
            </Link>
            <Link to={PUBLIC_ROUTES.CONTACT}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Contact
              </Text>
            </Link>
          </Flex>

          <Flex direction="column" alignItems="start" gap={3}>
            <Text fontSize="14px" fontWeight="bold" color="gray.500" mb={2}>
              Resources
            </Text>
            <Link to={PUBLIC_ROUTES.BLOG}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Blog
              </Text>
            </Link>
            <Link to={PUBLIC_ROUTES.HOME}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Newsletter
              </Text>
            </Link>
            <Link to={PUBLIC_ROUTES.HELP}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Help center
              </Text>
            </Link>
          </Flex>

          <Flex direction="column" alignItems="start" gap={3}>
            <Text fontSize="14px" fontWeight="bold" color="gray.500" mb={2}>
              Social
            </Text>
            <Link to={PUBLIC_ROUTES.HOME}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                LinkedIn
              </Text>
            </Link>
            <Link to={PUBLIC_ROUTES.HOME}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                X
              </Text>
            </Link>
          </Flex>

          <Flex direction="column" alignItems="start" gap={3}>
            <Text fontSize="14px" fontWeight="bold" color="gray.500" mb={2}>
              Legal
            </Text>
            <Link to={PUBLIC_ROUTES.TERMS_OF_SERVICE}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Terms of Service
              </Text>
            </Link>
            <Link to={PUBLIC_ROUTES.PRIVACY_POLICY}>
              <Text fontSize="16px" fontWeight="bold" color="gray.600">
                Privacy Policy
              </Text>
            </Link>
          </Flex>
        </SimpleGrid>
      </Flex>

      <Divider />
      <Flex
        w="full"
        direction={{ base: "column", md: "row-reverse" }}
        justifyContent="center"
        alignItems="start"
        gap={{ base: 8, md: 10 }}
      >
        <Flex justifyContent="start" gap={6}>
          <Link to={PUBLIC_ROUTES.HOME} aria-label="LinkedIn">
            <Icon as={FaTwitter} boxSize={6} color="gray" />
          </Link>
          <Link to={PUBLIC_ROUTES.HOME} aria-label="LinkedIn">
            <Icon as={FaLinkedin} boxSize={6} color="gray" />
          </Link>
        </Flex>

        <Flex
          w="full"
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          gap={2}
        >
          <Text fontSize="14px" fontWeight="normal" color="#A39C96">
            Made with 💝 in St. Louis MO, USA ©{new Date().getFullYear()}.
          </Text>
          <Text fontSize="14px" fontWeight="normal" color="#A39C96">
            All rights reserved for O’Fallon Labs LLC.
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
}
