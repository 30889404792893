import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { DESKTOP_FOOTER_HEIGHT, HEADER_HEIGHT } from "@/constants/config";
import { AUTH_ROUTES } from "@/constants/routes";
import { AuthAPI, failedToast, successToast } from "@/services";
import { ErrorServerResponse } from "@/services/api/axios";

type FormValues = {
  token: string;
  new_password: string;
  new_password_confirmation: string;
};

export function ResetPasswordPage() {
  const navigate = useNavigate();

  const token = new URLSearchParams(window.location.search).get("token") || "";

  const { mutateAsync: sendPasswordResetLink, isPending: isSubmitting } =
    useMutation({
      mutationKey: ["reset-password"],
      mutationFn: (params: Parameters<typeof AuthAPI.resetPassword>[0]) =>
        AuthAPI.resetPassword(params),
      onSuccess: (data) => {
        successToast({
          title: "Password successfully changed",
          description: data?.message,
        });
        navigate(AUTH_ROUTES.LOGIN);
      },
      onError: (error: AxiosError<ErrorServerResponse>) =>
        failedToast({
          title: "Password change failed",
          description: error.response?.data.message,
        }),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      token,
      new_password: "",
      new_password_confirmation: "",
    },
  });

  const handleSubmitReset = handleSubmit(
    async ({ new_password, new_password_confirmation, token }) =>
      await sendPasswordResetLink({
        new_password,
        new_password_confirmation,
        token,
      }),
  );

  return (
    <Flex
      as="form"
      onSubmit={handleSubmitReset}
      boxSize="full"
      minH={{
        base: `calc(100vh - ${HEADER_HEIGHT * 4}px)`,
        md: `calc(100vh - ${(HEADER_HEIGHT + DESKTOP_FOOTER_HEIGHT) * 4}px)`,
      }}
      px={{ base: 4, md: 10 }}
      py={{ base: 12, md: 24 }}
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        w="full"
        maxW="360px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={8}
      >
        <Flex w="full" direction="column" alignItems="center" gap={4}>
          <Image src="/assets/images/logo.svg" alt="O'Mentors" w={10} />
          <Flex direction="column" alignItems="center" gap={2}>
            <Text fontSize="24px" fontWeight="bold">
              Change your password
            </Text>
            <Text fontSize="16px" fontWeight="normal" color="gray.600">
              Enter your new password
            </Text>
          </Flex>
        </Flex>
        <Flex w="full" direction="column" alignItems="center" gap={5}>
          <FormControl
            id="new_password"
            isInvalid={Boolean(errors.new_password)}
          >
            <FormLabel fontSize="14px">New Password</FormLabel>
            <Input
              required
              type="password"
              placeholder="********"
              {...register("new_password", {
                required: "You must specify a new password",
              })}
            />
            {errors.new_password && (
              <FormErrorMessage>{errors.new_password.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            id="new_password_confirmation"
            isInvalid={Boolean(errors.new_password_confirmation)}
          >
            <FormLabel fontSize="14px">Confirm New Password</FormLabel>
            <Input
              required
              type="password"
              placeholder="********"
              {...register("new_password_confirmation", {
                required: "You must confirm your new password",
                deps: ["new_password"],
                validate: (value, { new_password }) =>
                  value === new_password || "Passwords do not match",
              })}
            />
            {errors.new_password_confirmation && (
              <FormErrorMessage>
                {errors.new_password_confirmation.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <Button
          type="submit"
          isLoading={isSubmitting}
          loadingText="Sending..."
          w="full"
          borderRadius="lg"
          colorScheme="brand"
          // bg="button.green"
          // color="white"
          // _hover={{
          //   bg: "brand.500",
          // }}
        >
          Change Password
        </Button>
        <Text fontSize="14px" fontWeight="normal" color="gray.600">
          Don’t have an account?{" "}
          <Button
            variant="link"
            as={Link}
            to={AUTH_ROUTES.REGISTER}
            fontSize="inherit"
            color="brand.600"
          >
            Sign up
          </Button>
        </Text>
      </Flex>
    </Flex>
  );
}
