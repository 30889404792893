export function getTimeZones() {
  // Get all supported timezones from the browser
  const allTimezones = Intl.supportedValuesOf("timeZone");

  // Map of emoji clock faces to match time zones
  const getClockEmoji = (offset: number) => {
    const hours = Math.round(Math.abs(offset / 60)) % 12;
    // Convert to clock emoji (🕐 = 1 o'clock, 🕙 = 10 o'clock, etc.)
    return String.fromCodePoint(0x1f550 + (hours === 0 ? 11 : hours - 1));
  };

  // Get the current date to calculate offsets
  const date = new Date();

  // Format all timezones with metadata
  const timezones = allTimezones.map((tz) => {
    // Format the timezone with the format: "Region/City (GMT+/-XX:XX)"
    const formatter = new Intl.DateTimeFormat("en", {
      timeZone: tz,
      timeZoneName: "longOffset",
    });

    const parts = formatter.formatToParts(date);
    const timeZonePart = parts.find((part) => part.type === "timeZoneName");
    const offset = timeZonePart?.value || "";

    // Calculate minutes offset for emoji selection
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone: tz }));
    const minutesOffset = (tzDate.getTime() - utcDate.getTime()) / 60000;

    // Get friendly name from timezone
    const cityName = tz.split("/").pop()?.replace(/_/g, " ") || tz;
    const regionName = tz.split("/")[0];
    const displayName = tz.includes("/")
      ? `${cityName} (${regionName})`
      : cityName;

    return {
      value: tz,
      emoji: getClockEmoji(minutesOffset),
      name: displayName,
      offset,
      minutesOffset, // For sorting
    };
  });

  // Sort timezones by offset
  timezones.sort((a, b) => a.minutesOffset - b.minutesOffset);

  return timezones;
}
