import { Link as RouterLink } from "react-router";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Icon,
  Link as ChakraLink,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";

import { NAV_ITEMS } from "../config";

import { DesktopSubNav } from "./desktop-sub-nav";

export function DesktopNav() {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction="row" spacing={4} flex={1}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              {navItem.isExternal ? (
                <ChakraLink
                  href={navItem.href || "#"}
                  p={2}
                  fontSize="sm"
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  display="flex"
                  gap={1}
                  alignItems="flex-end"
                >
                  {navItem.label}
                  {navItem.children && (
                    <Icon color="brand.400" w={5} h={5} as={ChevronDownIcon} />
                  )}
                </ChakraLink>
              ) : (
                <ChakraLink
                  as={RouterLink}
                  to={navItem.href || "#"}
                  p={2}
                  fontSize="sm"
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  display="flex"
                  gap={1}
                  alignItems="flex-end"
                >
                  {navItem.label}
                  {navItem.children && (
                    <Icon color="brand.400" w={5} h={5} as={ChevronDownIcon} />
                  )}
                </ChakraLink>
              )}
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW="sm"
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
}
