import { ElementRef, useRef, useState } from "react";
import {
  Button,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Add, AddSquare, Edit, Trash } from "iconsax-react";

import { EmptyState, Rating } from "@/components";
import { BackgroundSkillType, BackgroundType } from "@/types";

import { DeleteSkillModal } from "./delete-skill-modal";
import { EditSkillModal } from "./edit-skill-modal";

interface SkillsProps extends Omit<FlexProps, "background"> {
  background: BackgroundType;
  emptyText?: string;
  showTitle?: boolean;
  showIconButton?: boolean;
  onClick?: () => void;
}

export function Skills({
  background,
  emptyText,
  showTitle = true,
  showIconButton,
  onClick,
  ...rest
}: SkillsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef<ElementRef<"input">>(null);

  const [mode, setMode] = useState<"edit" | "remove" | undefined>();
  const [selected, setSelected] = useState<BackgroundSkillType | undefined>();

  const handleEditSkill = (skill: BackgroundSkillType) => {
    setMode("edit");
    setSelected(skill);
    onOpen();
  };
  const handleDeleteSkill = (skill: BackgroundSkillType) => {
    setMode("remove");
    setSelected(skill);
    onOpen();
  };
  const handleCloseModal = () => {
    setMode(undefined);
    setSelected(undefined);
    onClose();
  };

  return (
    <>
      <Flex
        w="full"
        bg={useColorModeValue("white", "gray.900")}
        rounded="md"
        p={6}
        overflow="hidden"
        borderWidth="1px"
        borderRadius="md"
        gap={4}
        direction="column"
        {...rest}
      >
        {(showTitle || onClick) && (
          <HStack justify={showTitle ? "space-between" : "end"} align="center">
            {showTitle && (
              <Text
                color="brand.500"
                textTransform="uppercase"
                fontWeight={800}
                fontSize="md"
                letterSpacing={1.1}
              >
                Skills
              </Text>
            )}

            {onClick &&
              (!showIconButton ? (
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  size="sm"
                  onClick={onClick}
                >
                  <AddSquare size={20} />
                </Button>
              ) : (
                <IconButton
                  aria-label="1"
                  icon={<Add size={20} />}
                  colorScheme="brand"
                  borderRadius="full"
                  onClick={onClick}
                />
              ))}
          </HStack>
        )}

        {background?.skills?.length ? (
          <Flex direction="column" gap={4}>
            {background.skills.map((skill) => (
              <Stack
                key={skill.name}
                direction={{ base: "column", lg: "row" }}
                justifyContent="space-between"
                spacing={2}
                fontSize="sm"
                w="full"
              >
                <Text fontWeight={600}>{skill.name}</Text>
                <Flex justify={{ base: "space-between", lg: "initial" }}>
                  <Rating rating={skill.rating} mr={2} />
                  {onClick && (
                    <Flex>
                      <Button
                        variant="ghost"
                        colorScheme="gray"
                        size="sm"
                        px={0}
                        onClick={() => handleEditSkill(skill)}
                      >
                        <Edit size={20} />
                      </Button>
                      <Button
                        variant="ghost"
                        colorScheme="red"
                        size="sm"
                        px={0}
                        onClick={() => handleDeleteSkill(skill)}
                      >
                        <Trash size={20} />
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </Stack>
            ))}
          </Flex>
        ) : (
          <EmptyState name="skill" onClick={onClick} emptyText={emptyText} />
        )}
      </Flex>
      {selected && mode === "edit" && (
        <EditSkillModal
          key={+isOpen}
          initialRef={initialRef}
          background={background}
          selectedSkill={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}
      {selected && mode === "remove" && (
        <DeleteSkillModal
          key={+isOpen}
          initialRef={initialRef}
          background={background}
          selectedSkill={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}
