import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { failedToast, UserAPI } from "@/services";

type FormType = {
  verified_calendly: boolean;
};
interface ProfileBookingProps extends FlexProps {
  verifiedCalendly?: boolean;
}

export function ProfileBooking({
  verifiedCalendly,
  ...rest
}: ProfileBookingProps) {
  const { mutateAsync: connectToCalendlyOAuth, isPending: isSubmitting } =
    useMutation({
      mutationKey: [`connect-stripe`],
      mutationFn: () => UserAPI.connectToCalendlyOAuth(),
      onSuccess: async (res) => {
        if (res.data?.redirect_url) {
          window.open(res.data.redirect_url, "_blank");
        }
        return true;
      },
      onError: () => {
        failedToast({
          title: "Account connection failed",
        });
      },
    });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormType>({
    defaultValues: {
      verified_calendly: verifiedCalendly,
    },
  });

  const handleSubmitEdit = handleSubmit(async (values) =>
    values.verified_calendly
      ? await connectToCalendlyOAuth()
      : await (() => {
          // TODO: implement disconnect stripe
          console.log("[DEBUG]: Disconnect stripe");
        })(),
  );

  const isEditMode = true;

  return (
    <Flex
      {...(isEditMode
        ? {
            as: "form",
            onSubmit: handleSubmitEdit,
          }
        : {})}
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Booking
        </Text>
        <Text fontSize="14px" color="gray.600">
          Connect your account to Calendly for easy scheduling and instant
          booking
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        <FormControl
          id="verified_calendly"
          isInvalid={Boolean(errors.verified_calendly)}
          display="flex"
          alignItems="start"
          gap={2}
        >
          <Switch
            id="verified_calendly"
            isDisabled={isSubmitting}
            colorScheme="brand"
            {...register("verified_calendly")}
          />
          <Flex direction="column" gap={1}>
            <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
              <Text fontSize="14px" fontWeight="medium" color="gray.700">
                {verifiedCalendly ? "Connected" : "Connect"} Calendly
              </Text>
              <Text fontSize="14px" fontWeight="normal" color="gray.600">
                Connect your account to Calendly for easy scheduling
              </Text>
            </FormLabel>
            {errors.verified_calendly && (
              <FormErrorMessage>
                {errors.verified_calendly.message}
              </FormErrorMessage>
            )}
          </Flex>
        </FormControl>
        {isDirty && (
          <Flex justifyContent="end" gap={4}>
            <Button variant="outline" colorScheme="red">
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting} colorScheme="brand">
              Save changes
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
