export const breakpoints = {
  // place theme breakpoints here
  xs: "360px",
  sm: "640px",
  md: "768px",
  lg: "992px",
  xl: "1280px",
  "2xl": "1336px",
  "3xl": "1440px",
  "4xl": "1596px",
};
