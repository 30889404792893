import { ElementRef, useRef } from "react";
import { Flex, FlexProps, Text, useDisclosure } from "@chakra-ui/react";

import { Skills } from "@/pages/dashboard/background/skills";
import { EditSkillModal } from "@/pages/dashboard/background/skills/edit-skill-modal";
import { Tools } from "@/pages/dashboard/background/tools";
import { EditToolModal } from "@/pages/dashboard/background/tools/edit-tool-modal";
import { BackgroundType } from "@/types";

interface ProfileSkillsProps extends Omit<FlexProps, "background"> {
  background: BackgroundType;
  type?: "own" | "mentor" | "mentee";
}

export function ProfileSkills({
  background,
  type = "own",
  ...rest
}: ProfileSkillsProps) {
  const {
    isOpen: skillsOpen,
    onOpen: skillsOnOpen,
    onClose: skillsOnClose,
  } = useDisclosure();
  const {
    isOpen: toolsOpen,
    onOpen: toolsOnOpen,
    onClose: toolsOnClose,
  } = useDisclosure();

  const initialRef = useRef<ElementRef<"input">>(null);

  const isOwnProfile = type === "own";

  return (
    <Flex
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Skills and tools
        </Text>
        <Text fontSize="14px" color="gray.600">
          {isOwnProfile
            ? "Update your skills and tools items"
            : `See the ${type}’s skills and tools items`}
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        <Skills
          background={background}
          onClick={isOwnProfile ? skillsOnOpen : undefined}
          showIconButton
          emptyText="No skills added yet"
          border="none"
          p={0}
        />
        <Tools
          background={background}
          onClick={isOwnProfile ? toolsOnOpen : undefined}
          showIconButton
          emptyText="No tools added yet"
          border="none"
          p={0}
        />
        {/* <Flex alignItems="center" justify="space-between">
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Skill / Tool
          </FormLabel>
          <IconButton
            aria-label="1"
            icon={<FaPlus />}
            colorScheme="brand"
            borderRadius="full"
          />
        </Flex>
        <Flex direction="column" gap={4}>
          <Flex alignItems="flex-start" justify="space-between">
            <Text fontSize="14px" fontWeight="medium" color="gray.700">
              Python
            </Text>
            <Flex justify={{ base: "space-between", lg: "initial" }}>
              <Rating rating={4} mr={2} />
              <IconButton
                aria-label="options"
                icon={<FaEllipsisV />}
                colorScheme="gray"
                bgColor="white"
                color="gray.300"
                size="sm"
                px={0}
              />
            </Flex>
          </Flex>

          <Flex alignItems="flex-start" justify="space-between">
            <Text fontSize="14px" fontWeight="medium" color="gray.700">
              Machine Learning
            </Text>
            <Flex justify={{ base: "space-between", lg: "initial" }}>
              <Rating rating={4} mr={2} />
              <IconButton
                aria-label="options"
                icon={<FaEllipsisV />}
                colorScheme="gray"
                bgColor="white"
                color="gray.300"
                size="sm"
                px={0}
              />
            </Flex>
          </Flex>

          <Flex alignItems="flex-start" justify="space-between">
            <Text fontSize="14px" fontWeight="medium" color="gray.700">
              Data Science
            </Text>
            <Flex justify={{ base: "space-between", lg: "initial" }}>
              <Rating rating={4} mr={2} />
              <IconButton
                aria-label="options"
                icon={<FaEllipsisV />}
                colorScheme="gray"
                bgColor="white"
                color="gray.300"
                size="sm"
                px={0}
              />
            </Flex>
          </Flex>
        </Flex> */}
      </Flex>
      <EditSkillModal
        key={+skillsOpen}
        initialRef={initialRef}
        background={background}
        isOpen={skillsOpen}
        onClose={skillsOnClose}
      />
      <EditToolModal
        key={+toolsOpen}
        initialRef={initialRef}
        background={background}
        isOpen={toolsOpen}
        onClose={toolsOnClose}
      />
    </Flex>
  );
}
