import { LoaderFunctionArgs } from "react-router";
import { QueryClient, QueryOptions } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { MenteeAPI } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

export const getBackgroundQuery = (options: QueryOptions = {}) => ({
  queryKey: ["background"],
  queryFn: MenteeAPI.getBackground,
  ...options,
});

export const backgroundLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const query = getBackgroundQuery();

      return (
        queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query))
      );
    } catch (error) {
      captureNetworkError(error);
    }
  };
