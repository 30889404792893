import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { GoogleLoginButton } from "@/components/google-login-button";
import { EMAIL_REGEX } from "@/constants/regex";
import { AUTH_ROUTES, DASHBOARD_ROUTES } from "@/constants/routes";
import {
  AuthAPI,
  failedToast,
  successToast,
  useAuthStore,
  UserAPI,
} from "@/services";

type FormValues = {
  email: string;
  password: string;
  remember_me: boolean;
};

export function LoginPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setAccessToken, setUser } = useAuthStore();

  const { refetch: getUserInfo } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      const userInfo = await UserAPI.getMe();
      setUser({
        ...userInfo,
        full_name: `${userInfo.first_name} ${userInfo.last_name}`,
      });

      const from = searchParams.get("from");
      if (from) {
        navigate(from);
      } else {
        navigate(DASHBOARD_ROUTES.HOME);
      }

      return userInfo;
    },
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: login, isPending: isLoggingIn } = useMutation({
    mutationKey: ["login"],
    mutationFn: async (credentials: { email: string; password: string }) => {
      return await AuthAPI.login(credentials);
    },
    onSuccess: async (res) => {
      successToast({ title: "Logging in successfully!" });
      setAccessToken(res.access_token);

      // Fetch user info after successful login
      await getUserInfo();
    },
    onError: (error) => {
      failedToast({
        title: "Login failed",
        description: "Invalid email or password. Please try again.",
      });
      console.error(error);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const handleSubmitLogin = handleSubmit(
    async ({ email, password }) => await login({ email, password }),
  );

  return (
    <Flex
      as="form"
      onSubmit={handleSubmitLogin}
      boxSize="full"
      px={{ base: 4, lg: 10 }}
      py={{ base: 12, lg: 24 }}
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        w="full"
        maxW="360px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={8}
      >
        <Flex w="full" direction="column" alignItems="center" gap={4}>
          <Image src="/assets/images/logo.svg" alt="O'Mentors" w={10} />
          <Flex direction="column" alignItems="center" gap={2}>
            <Text fontSize="24px" fontWeight="bold">
              Log in to your account
            </Text>
            <Text fontSize="16px" fontWeight="normal" color="gray.600">
              Welcome back! Please enter your details.
            </Text>
          </Flex>
        </Flex>
        <Flex w="full" direction="column" alignItems="center" gap={5}>
          <FormControl id="email" isInvalid={Boolean(errors.email)}>
            <FormLabel fontSize="14px">Email</FormLabel>
            <Input
              required
              type="email"
              placeholder="Enter your email"
              {...register("email", {
                required: "You must provide a username to log in",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="password" isInvalid={Boolean(errors.password)}>
            <FormLabel fontSize="14px">Password</FormLabel>
            <Input
              required
              type="password"
              placeholder="********"
              {...register("password", {
                required: "You must provide a password to log in",
              })}
            />
            {errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <Flex w="full" justify="space-between" alignItems="center">
          <Checkbox
            isDisabled
            type="checkbox"
            fontSize="14px"
            {...register("remember_me")}
          >
            Remember me for 30 days
          </Checkbox>
          <Button
            variant="link"
            as={Link}
            to={AUTH_ROUTES.FORGET_PASSWORD}
            fontSize="14px"
            fontWeight="bold"
            color="#619D1F"
          >
            Forgot password
          </Button>
        </Flex>
        <Flex w="full" direction="column" gap={4}>
          <Button
            type="submit"
            isLoading={isLoggingIn}
            loadingText="Logging in..."
            w="full"
            borderRadius="lg"
            colorScheme="brand"
          >
            Sign In
          </Button>
          <GoogleLoginButton mode="login" />
        </Flex>
        <Text fontSize="14px" fontWeight="normal" color="gray.600">
          Don’t have an account?{" "}
          <Button
            variant="link"
            as={Link}
            to={AUTH_ROUTES.REGISTER}
            fontSize="inherit"
            color="brand.600"
          >
            Sign up
          </Button>
        </Text>
      </Flex>
    </Flex>
  );
}
