import { Link } from "react-router";
import { Button, Flex, Text } from "@chakra-ui/react";

import { AUTH_ROUTES, PUBLIC_ROUTES } from "@/constants/routes";

export function JoinUsToday() {
  return (
    <Flex
      boxSize="full"
      direction={{ base: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ base: "start", md: "end" }}
      gap={{ base: 8, md: 12 }}
    >
      <Flex direction="column" gap={{ base: 2, md: 6 }}>
        <Text fontSize={{ base: "24px", md: "30px" }} fontWeight="bold">
          Join us today!
        </Text>
        <Text fontSize="20px" fontWeight="normal">
          Connect with top industry mentors, gain personalized guidance, and
          take your career to the next level. Sign up for free and start your
          journey today.
        </Text>
      </Flex>
      <Flex
        w={{ base: "full", md: "fit-content" }}
        direction={{ base: "column", md: "row-reverse" }}
        justifyContent="center"
        alignItems="center"
        gap={3}
      >
        <Button
          as={Link}
          to={AUTH_ROUTES.LOGIN}
          w="full"
          maxW={{ base: "full", md: "120px" }}
          h={12}
          colorScheme="brand"
        >
          Get Started
        </Button>
        <Button
          as={Link}
          to={PUBLIC_ROUTES.HOME}
          w="full"
          maxW={{ base: "full", md: "120px" }}
          h={12}
          bg="white"
          color="#5F5D5A"
          variant="outline"
        >
          Learn more
        </Button>
      </Flex>
    </Flex>
  );
}
