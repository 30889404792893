import { captureNetworkError } from "@/services/error-handler";
import { ServiceType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getServices request
 */

export type GetServicesResponse = ServiceType[];

export const getServices = async <T extends GetServicesResponse>(
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>("services", options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * getSingleService request
 */

export type GetSingleServiceResponse = ServiceType;

export const getSingleService = async <T extends GetSingleServiceResponse>(
  service_id: ServiceType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`services/${service_id}`, options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * createService request
 */

export type CreateServicePayload = Pick<
  ServiceType,
  | "title"
  | "description"
  | "qualifications"
  | "type"
  | "price"
  | "calendly_event"
  | "need_approval"
>;

export type CreateServiceResponse = ServiceType;

export const createService = async <T extends CreateServiceResponse>(
  data: CreateServicePayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>("services", data, options)
    .then((res) => res)
    .catch(captureNetworkError);
};

/**
 * editService request
 */

export type EditServicePayload = Omit<CreateServicePayload, "type" | "price">;

export type EditServiceResponse = ServiceType;

export const editService = async <T extends EditServiceResponse>(
  service_id: ServiceType["id"],
  data: EditServicePayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .put<T, SuccessResponseType<T>>(`services/${service_id}`, data, options)
    .then((res) => res)
    .catch(captureNetworkError);
};

/**
 * deleteService request
 */

export type DeleteServicePayload = CreateServicePayload;

export type DeleteServiceResponse = ServiceType;

export const deleteService = async <T extends DeleteServiceResponse>(
  service_id: ServiceType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .delete<T, SuccessResponseType<T>>(`services/${service_id}`, options)
    .then((res) => res)
    .catch(captureNetworkError);
};
