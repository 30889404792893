import {
  Badge,
  Button,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Edit } from "iconsax-react";

import { BackgroundType } from "@/types";

interface JobProps extends Omit<FlexProps, "background"> {
  background: BackgroundType;
  showTitle?: boolean;
  onClick?: () => void;
}

export function Job({
  background,
  showTitle = true,
  onClick,
  ...rest
}: JobProps) {
  return (
    <Flex
      w="full"
      bg={useColorModeValue("white", "gray.900")}
      rounded="md"
      p={6}
      overflow="hidden"
      borderWidth="1px"
      borderRadius="md"
      gap={4}
      direction="column"
      {...rest}
    >
      {(showTitle || onClick) && (
        <HStack justify={showTitle ? "space-between" : "end"} align="center">
          {showTitle && (
            <Text
              color="brand.500"
              textTransform="uppercase"
              fontWeight={800}
              fontSize="md"
              letterSpacing={1.1}
            >
              Job
            </Text>
          )}
          {onClick &&
            (showTitle ? (
              <Button
                variant="ghost"
                colorScheme="gray"
                size="sm"
                onClick={onClick}
              >
                <Edit size={20} />
              </Button>
            ) : (
              <IconButton
                aria-label="1"
                icon={<Edit size={20} />}
                colorScheme="brand"
                borderRadius="full"
                onClick={onClick}
              />
            ))}
        </HStack>
      )}
      <Stack>
        <Text color={useColorModeValue("gray.700", "white")} fontFamily="body">
          Employed Status:
        </Text>
        <Badge colorScheme="brand" w="fit-content">
          {background.job.is_employed ? "Employed" : "Unemployed"}
        </Badge>
      </Stack>
      {background.job.is_employed && (
        <Flex
          direction={{ base: "column", lg: "row" }}
          mt={6}
          gap={4}
          align={{ base: "flex-start", lg: "center" }}
        >
          <Stack direction="column" spacing={0} fontSize="sm" w="full">
            <Text fontWeight={600}>Title:</Text>
            <Text color="gray.500">{background.job.title || "N/A"}</Text>
          </Stack>
          <Stack direction="column" spacing={0} fontSize="sm" w="full">
            <Text fontWeight={600}>Current Employer:</Text>
            <Text color="gray.500">
              {background.job.current_employer || "N/A"}
            </Text>
          </Stack>
        </Flex>
      )}
    </Flex>
  );
}
