import { useMemo, useState } from "react";
import { Link } from "react-router";
import {
  Badge,
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { CloseCircle, Tag } from "iconsax-react";

import { applicationStatusColorMapping } from "@/constants/mappings";
import { DASHBOARD_ROUTES } from "@/constants/routes";
import { SharedAPI } from "@/services";
import {
  ApplicationResponseStatusTypes,
  ApplicationStatusEnum,
  ApplicationType,
} from "@/types";
import { dateFormatter } from "@/utils/date-formatter";
import { getRelativeTimeString } from "@/utils/relative-date";

import { ApplicationActionButton } from "./application-action-button";

interface ApplicationCardProps extends FlexProps {
  application?: ApplicationType;
  isOwn?: boolean;

  isMentee: boolean;
  onDetail: (application: ApplicationType) => void;
  onCancel: (application: ApplicationType) => void;
  onRespond: (
    response: ApplicationResponseStatusTypes,
    application: ApplicationType,
  ) => void;
}

export function ApplicationCard({
  application,
  isOwn,
  isMentee,
  onDetail,
  onCancel,
  onRespond,
  ...rest
}: ApplicationCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const memberInfo = isMentee ? application?.mentor : application?.mentee;

  const { mutateAsync: payApplication, isPending: payApplicationLoading } =
    useMutation({
      mutationKey: [`pay-application`, application?.id],
      mutationFn: (
        application_id: Parameters<typeof SharedAPI.payApplication>[0],
      ) => SharedAPI.payApplication(application_id),
      onSuccess: (res) => {
        if (res?.data?.url) {
          window.open(res.data.url, "_blank")?.focus();
        }
      },
    });

  const handlePayApplication = async () => {
    if (application) {
      await payApplication(application.id);
    }
  };

  const showWithdrawButton =
    isMentee &&
    (application?.status === ApplicationStatusEnum.PENDING ||
      application?.status === ApplicationStatusEnum.ACCEPTED);

  const handleToggleExpand = () => {
    if (isMobile) {
      setIsExpanded((prev) => !prev);
    }
  };

  const daysLeft = useMemo(() => {
    const date = application?.updated_at || application?.created_at;
    if (!date) return null;

    const currentDate = new Date();
    const applicationDate = new Date(date);

    // 7 days is typically the expiration window for booking
    const expirationDays = 7;
    const daysPassed = Math.floor(
      (currentDate.getTime() - applicationDate.getTime()) / (1000 * 3600 * 24),
    );

    const remainingDays = Math.max(0, expirationDays - daysPassed);
    return remainingDays > 0 ? remainingDays : null;
  }, [application]);

  const showFullContent = isExpanded || !isMobile;
  const isExpired = application?.is_expired;
  const isInProgress =
    application?.status === ApplicationStatusEnum.PENDING ||
    application?.status === ApplicationStatusEnum.ACCEPTED;
  const fullName = `${memberInfo?.first_name} ${memberInfo?.last_name}`;
  const note = isMentee
    ? application?.response_message
    : application?.request_note;

  const isSubscription = application?.type === "subscription";

  return (
    <Flex
      minW={{ base: "300px", md: "375px" }}
      w="full"
      maxW={{ base: "full", md: "375px" }}
      p={{ base: 3, md: 5 }}
      bg={useColorModeValue("white", "gray.900")}
      rounded="lg"
      direction={showFullContent ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      gap={6}
      borderWidth="1px"
      borderRadius="lg"
      cursor={isMobile ? "pointer" : "default"}
      onClick={handleToggleExpand}
      transition="all 0.3s ease"
      {...rest}
    >
      <Box
        w={showFullContent ? "full" : "auto"}
        position="relative"
        borderRadius="lg"
        boxShadow="0px 6px 20px -4px rgba(55, 74, 89, 0.36)"
        transition="all 0.3s ease"
      >
        <Image
          w={showFullContent ? "full" : "98px"}
          h={showFullContent ? "232px" : "138px"}
          // aspectRatio={showFullContent ? "327 / 232" : "98 / 138"}
          objectFit="cover"
          borderRadius="lg"
          src={memberInfo?.avatar}
          alt={fullName}
          filter={isExpired || !isInProgress ? "grayscale(100%)" : "none"}
        />
        {/* Icons overlay */}
        {!isOwn && showFullContent && (
          <Flex
            w="full"
            px={2}
            position="absolute"
            top={2}
            zIndex={1}
            alignItems="start"
            direction="row-reverse"
            justifyContent="space-between"
            gap={2}
            transition="all 0.3s ease"
          >
            <Badge
              colorScheme={
                application
                  ? applicationStatusColorMapping[application.status]
                  : "gray"
              }
              boxSize="fit-content"
              borderRadius="lg"
              py={1}
              px={2}
            >
              <Text fontSize={["10px", "14px"]}>
                {application?.status || "unknown"}
              </Text>
            </Badge>
            {showWithdrawButton && (
              <Tooltip hasArrow label="Withdraw this application">
                <IconButton
                  icon={<CloseCircle />}
                  onClick={() => application && onCancel?.(application)}
                  color="white"
                  bg="red"
                  size="sm"
                  borderRadius="full"
                  aria-label="Withdraw this application"
                  _hover={{
                    bgColor: "unset",
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        )}
        {!isOwn && (
          <Flex
            w="full"
            px={2}
            position="absolute"
            bottom={2}
            zIndex={1}
            alignItems="end"
            justifyContent="space-between"
            gap={2}
            transition="all 0.3s ease"
          >
            <Icon
              as={Tag}
              color="brand.400"
              variant="Bold"
              boxSize={showFullContent ? 6 : 4}
            />
          </Flex>
        )}
      </Box>
      <Flex
        flex={1}
        w="full"
        direction="column"
        justifyContent="space-between"
        gap={showFullContent ? 4 : 1}
        transition="all 0.3s ease"
      >
        <Flex w="full" direction="column" order={showFullContent ? 1 : 0}>
          <Text
            fontSize={["14px", "20px", "24px"]}
            fontWeight="bold"
            noOfLines={1}
            transition="all 0.3s ease"
          >
            {application?.service?.title}
          </Text>
          <Text
            fontSize={["10px", "14px", "16px"]}
            fontWeight="normal"
            color="#374A59"
          >
            With{" "}
            <Button
              as={Link}
              to={`${
                isMentee ? DASHBOARD_ROUTES.MENTORS : DASHBOARD_ROUTES.MENTEES
              }/${memberInfo?.id}`}
              variant="link"
              color="brand.600"
            >
              {fullName}
            </Button>
          </Text>
        </Flex>
        <Flex
          direction="column"
          alignItems="start"
          order={showFullContent ? 0 : 1}
        >
          <Text
            fontSize={["10px", "12px", "14px"]}
            fontWeight="normal"
            color="#6E798C"
          >
            Applied{" "}
            <Tooltip
              label={dateFormatter(
                application?.created_at || new Date().toISOString(),
                {
                  dateStyle: "full",
                },
              )}
              aria-label="Application Applied Tooltip"
              placement="top"
            >
              <Text as="span" fontWeight="bold" color="brand.500">
                {application
                  ? getRelativeTimeString(application?.created_at)
                  : new Date().toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
              </Text>
            </Tooltip>
          </Text>
          {!isExpired && daysLeft && isInProgress && (
            <Text
              as="span"
              fontSize={["10px", "12px", "14px"]}
              fontWeight="bold"
              color="yellow.500"
            >
              ({daysLeft} days to expire)
            </Text>
          )}
        </Flex>
        {note && (
          <Box
            display={showFullContent ? "block" : "none"}
            order={2}
            opacity={showFullContent ? 1 : 0}
            transition="opacity 0.3s ease"
            style={{
              visibility: showFullContent ? "visible" : "hidden",
              height: showFullContent ? "auto" : 0,
            }}
          >
            <Text
              as="q"
              fontStyle="italic"
              fontSize={["12px", "14px", "16px"]}
              fontWeight="normal"
              color="#374A59"
              noOfLines={3}
            >
              {/* {isMentee ? "Response Message:" : "Request Note:"} */}
              {note}
            </Text>
          </Box>
        )}
        <Flex
          w="full"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          order={showFullContent ? 3 : 2}
        >
          {!showFullContent && (
            <Badge
              colorScheme={
                application
                  ? applicationStatusColorMapping[application.status]
                  : "gray"
              }
              boxSize="fit-content"
              borderRadius="lg"
              py={showFullContent ? 2 : undefined}
              px={showFullContent ? 3 : undefined}
            >
              <Text fontSize={["10px", "14px"]}>
                {application?.status || "unknown"}
              </Text>
            </Badge>
          )}
          <Flex
            w="full"
            direction="row-reverse"
            justifyContent={showFullContent ? "start" : "end"}
            alignItems="center"
            gap={showFullContent ? 2 : 0}
            order={showFullContent ? 2 : 3}
            transition="all 0.3s ease"
          >
            <Tooltip
              label={
                application?.service?.need_approval
                  ? "Need Approval"
                  : "Auto Approval"
              }
              aria-label="Approval Tooltip"
              placement="top"
            >
              <Image
                src={
                  !application?.service?.need_approval
                    ? "/assets/images/icons/envelope.svg"
                    : "/assets/images/icons/envelope_open.svg"
                }
                borderRadius="lg"
                p={
                  !application?.service?.need_approval
                    ? showFullContent
                      ? 2.5
                      : 1.5
                    : undefined
                }
                boxSize={showFullContent ? "48px" : "32px"}
                bgColor={showFullContent ? "brand.50" : "transparent"}
                transition="all 0.3s ease"
              />
            </Tooltip>
            <Tooltip
              label={isSubscription ? "Subscription" : "One Session"}
              aria-label={
                isSubscription ? "Subscription Tooltip" : "One Session Tooltip"
              }
              placement="top"
            >
              {isSubscription ? (
                <Image
                  src="/assets/images/icons/refresh.svg"
                  borderRadius="lg"
                  boxSize={showFullContent ? "48px" : "32px"}
                  bgColor={showFullContent ? "brand.50" : "transparent"}
                  transition="all 0.3s ease"
                />
              ) : (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="lg"
                  boxSize={showFullContent ? "48px" : "32px"}
                  bgColor={showFullContent ? "brand.50" : "transparent"}
                  transition="all 0.3s ease"
                >
                  <Text
                    fontSize={{ base: "16px", md: "20px" }}
                    fontWeight="bold"
                    color="#7AB738"
                  >
                    +1
                  </Text>
                </Flex>
              )}
            </Tooltip>
          </Flex>
        </Flex>
        {isOwn ? (
          <Button
            as={Link}
            to={DASHBOARD_ROUTES.APPLICATIONS}
            display={showFullContent ? "flex" : "none"}
            w="full"
            mt={4}
            colorScheme="brand"
            order={4}
          >
            Edit
          </Button>
        ) : (
          <Stack
            direction="row"
            spacing={4}
            w="full"
            mt={4}
            order={4}
            display={showFullContent ? "flex" : "none"}
          >
            {application ? (
              <ApplicationActionButton
                isMentee={isMentee}
                application={application}
                onPay={handlePayApplication}
                isLoading={payApplicationLoading}
                onRespond={onRespond}
                flex={1}
              />
            ) : (
              <Button
                as={Link}
                to={`${
                  isMentee ? DASHBOARD_ROUTES.MENTORS : DASHBOARD_ROUTES.MENTEES
                }/${memberInfo?.id}`}
                w="full"
                colorScheme="brand"
              >
                Review
              </Button>
            )}
          </Stack>
        )}
      </Flex>
    </Flex>
  );
}
