import { failedToast } from "@/services/toast";
import { RoleTypes } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * login request
 */

export type LoginResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
};

export const login = async <T extends LoginResponse>(
  data: {
    email: string;
    password: string;
  },
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>("login", data, options)
    .then((res) => res.data);
};

/**
 * register request
 */

export type RegisterPayload = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: RoleTypes;
};

export type RegisterResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
};

export const register = async <T extends RegisterResponse>(
  data: RegisterPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>("register", data, options)
    .then((res) => res.data);
};

/**
 * forget password request
 */

export type ForgetPasswordPayload = {
  email: string;
};

export type ForgetPasswordResponse = {
  message: string;
};

export const forgetPassword = async <T extends ForgetPasswordResponse>(
  data: ForgetPasswordPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>("forget-password", data, options)
    .then((res) => res.data);
};

/**
 * reset password request
 */

export type ResetPasswordPayload = {
  token: string;
  new_password: string;
  new_password_confirmation: string;
};

export type ResetPasswordResponse = {
  message: string;
};

export const resetPassword = async <T extends ResetPasswordResponse>(
  data: ResetPasswordPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>("reset-password", data, options)
    .then((res) => res.data);
};

/**
 * change password request
 */

export type ChangePasswordPayload = {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
};

export type ChangePasswordResponse = {
  message: string;
};

export const changePassword = async <T extends ChangePasswordResponse>(
  data: ChangePasswordPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>("change-password", data, options)
    .then((res) => res.data);
};

/**
 * google callback request
 */

export type GoogleCallbackResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
};

export const googleCallback = async <T extends GoogleCallbackResponse>(
  params: string,
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`google/callback?${params}`, options)
    .then((res) => res.data)
    .catch((error) => {
      failedToast({ title: error.response?.data.message });
      return null;
    });
};
