import { createBrowserRouter, redirect } from "react-router";

import {
  AUTH_ROUTES,
  CALENDLY_PREFIX,
  DASHBOARD_PREFIX,
  DASHBOARD_ROUTES,
  GOOGLE_PREFIX,
  PUBLIC_PREFIX,
  PUBLIC_ROUTES,
  STRIPE_PREFIX,
} from "./constants/routes";
import { Page404 } from "./pages/404";
import {
  ForgetPasswordPage,
  loginLoader,
  LoginPage,
  RegisterPage,
  resetPasswordLoader,
  ResetPasswordPage,
} from "./pages/auth";
import { calendlyLoader } from "./pages/calendly";
import {
  ApplicationPage,
  applicationsLoader,
  backgroundLoader,
  BackgroundPage,
  goalsLoader,
  GoalsPage,
  menteeProfileLoader,
  MenteeProfilePage,
  mentorProfileLoader,
  MentorProfilePage,
  MentorshipPage,
  mentorshipsLoader,
  NotificationPage,
  notificationsLoader,
  servicesLoader,
  ServicesPage,
  settingsLoader,
  SettingsPage,
} from "./pages/dashboard";
import { DashboardPage } from "./pages/dashboard/page";
import { googleLoader } from "./pages/google";
import { protectedLoader } from "./pages/protected/loading";
import { HomePage } from "./pages/public";
import { AboutPage } from "./pages/public/about";
import { AllMentorsPage } from "./pages/public/all-mentors";
import { AllServicesPage } from "./pages/public/all-services";
import { ContactPage } from "./pages/public/contact";
import { HelpCenterPage } from "./pages/public/help-center";
import {
  mentorPublicProfileLoader,
  MentorPublicProfilePage,
} from "./pages/public/mentor-public-profile";
import { PrivacyAndPolicyPage } from "./pages/public/privacy-and-policy";
import { TermsOfServicesPage } from "./pages/public/terms-of-services";
import { stripeLoader } from "./pages/stripe";
import { useAuthStore } from "./services/store";
import { queryClient } from "./index";
import { DashboardLayout, MainLayout } from "./layouts";

export const router = createBrowserRouter([
  {
    id: "root",
    path: PUBLIC_ROUTES.HOME,
    Component: MainLayout,
    children: [
      {
        index: true,
        path: "/",
        Component: HomePage,
      },
      {
        path: AUTH_ROUTES.REGISTER,
        loader: loginLoader,
        Component: RegisterPage,
      },
      {
        path: AUTH_ROUTES.LOGIN,
        loader: loginLoader,
        Component: LoginPage,
      },
      {
        path: AUTH_ROUTES.FORGET_PASSWORD,
        loader: loginLoader,
        Component: ForgetPasswordPage,
      },
      {
        path: AUTH_ROUTES.RESET_PASSWORD,
        loader: resetPasswordLoader,
        Component: ResetPasswordPage,
      },
      {
        path: PUBLIC_ROUTES.ABOUT,
        Component: AboutPage,
      },
      {
        path: PUBLIC_ROUTES.ALL_MENTORS,
        Component: AllMentorsPage,
      },
      {
        path: PUBLIC_ROUTES.ALL_SERVICES,
        Component: AllServicesPage,
      },
      {
        path: `${PUBLIC_ROUTES.MENTOR_PROFILE}/:mentorId`,
        Component: MentorPublicProfilePage,
        loader: mentorPublicProfileLoader(queryClient),
      },
      {
        path: PUBLIC_ROUTES.TERMS_OF_SERVICE,
        Component: TermsOfServicesPage,
      },
      {
        path: PUBLIC_ROUTES.PRIVACY_POLICY,
        Component: PrivacyAndPolicyPage,
      },
      {
        path: PUBLIC_ROUTES.CONTACT,
        Component: ContactPage,
      },
      {
        path: PUBLIC_ROUTES.HELP,
        Component: HelpCenterPage,
      },
      {
        path: `${PUBLIC_PREFIX}/404`,
        Component: Page404,
      },
    ],
  },
  {
    path: DASHBOARD_PREFIX,
    Component: DashboardLayout,
    loader: protectedLoader,
    children: [
      {
        index: true,
        loader: () => redirect(DASHBOARD_ROUTES.HOME),
      },
      {
        path: DASHBOARD_ROUTES.HOME,
        Component: DashboardPage,
      },
      {
        path: DASHBOARD_ROUTES.NOTIFICATIONS,
        Component: NotificationPage,
        loader: notificationsLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.SETTINGS,
        Component: SettingsPage,
        loader: settingsLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.GOALS,
        Component: GoalsPage,
        loader: goalsLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.BACKGROUND,
        Component: BackgroundPage,
        loader: backgroundLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.SERVICES,
        Component: ServicesPage,
        loader: servicesLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.APPLICATIONS,
        Component: ApplicationPage,
        loader: applicationsLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.MENTORSHIPS,
        Component: MentorshipPage,
        loader: mentorshipsLoader(queryClient),
      },
      {
        path: `${DASHBOARD_ROUTES.MENTEES}/:menteeId`,
        Component: MenteeProfilePage,
        loader: menteeProfileLoader(queryClient),
      },
      {
        path: `${DASHBOARD_ROUTES.MENTORS}/:mentorId`,
        Component: MentorProfilePage,
        loader: mentorProfileLoader(queryClient),
      },
      {
        path: DASHBOARD_ROUTES.SUPPORT,
        Component: ContactPage,
      },
      {
        path: `${DASHBOARD_PREFIX}/*`,
        Component: Page404,
      },
    ],
  },
  {
    path: `${STRIPE_PREFIX}/:return_url`,
    loader: stripeLoader,
  },
  {
    path: `${CALENDLY_PREFIX}/:return_url`,
    loader: calendlyLoader(queryClient),
  },
  {
    path: `${GOOGLE_PREFIX}/:return_url`,
    loader: googleLoader(queryClient),
  },
  {
    path: AUTH_ROUTES.LOGOUT,
    async loader() {
      // We signOut in a "resource route" that we can hit from a fetcher.Form
      await useAuthStore.getState().clearTokens();
      return redirect(PUBLIC_ROUTES.HOME);
    },
  },
]);
