export const PUBLIC_PREFIX = "/";
export const AUTH_PREFIX = "/auth";
export const DASHBOARD_PREFIX = "/dashboard";
export const STRIPE_PREFIX = "/stripe";
export const CALENDLY_PREFIX = "/calendly";
export const GOOGLE_PREFIX = "/google";

export const PUBLIC_ROUTES = {
  HOME: PUBLIC_PREFIX,
  ABOUT: "/about",
  BLOG: "/blog",
  HELP: "/help",
  TERMS_OF_SERVICE: "/terms-of-service",
  CONTACT: "/contact",
  PRIVACY_POLICY: "/privacy-policy",
  ALL_SERVICES: "/services",
  ALL_MENTORS: "/mentors",
  MENTOR_PROFILE: "/profile",
};

export const AUTH_ROUTES = {
  LOGIN: `${AUTH_PREFIX}/login`,
  REGISTER: `${AUTH_PREFIX}/register`,
  LOGOUT: `${AUTH_PREFIX}/logout`,
  FORGET_PASSWORD: `${AUTH_PREFIX}/forget-password`,
  RESET_PASSWORD: `${AUTH_PREFIX}/reset-password`,
};

export const DASHBOARD_ROUTES = {
  // mentee's routes
  MENTORS: `${DASHBOARD_PREFIX}/mentors`,
  BACKGROUND: `${DASHBOARD_PREFIX}/background`,
  GOALS: `${DASHBOARD_PREFIX}/goals`,

  // mentor's routes
  MENTEES: `${DASHBOARD_PREFIX}/mentees`,
  APPLICATIONS: `${DASHBOARD_PREFIX}/applications`,
  SERVICES: `${DASHBOARD_PREFIX}/services`,

  // shared routes
  HOME: `${DASHBOARD_PREFIX}/home`,
  NOTIFICATIONS: `${DASHBOARD_PREFIX}/notifications`,
  MENTORSHIPS: `${DASHBOARD_PREFIX}/mentorships`,
  SUPPORT: `${DASHBOARD_PREFIX}/support`,
  SETTINGS: `${DASHBOARD_PREFIX}/settings`,
};

export const STRIPE_ROUTES = {
  CONNECT_REAUTH: "connect-reauth",
  CONNECT_RETURN: "connect-return",
  CHECKOUT_CANCEL: "checkout-cancel",
  CHECKOUT_SUCCESS: "checkout-success",
};

export const CALENDLY_ROUTES = {
  CONNECT_REAUTH: "connect-reauth",
  CONNECT_RETURN: "connect-return",
};

export const GOOGLE_ROUTES = {
  CALLBACK: "callback",
};
