import { MutableRefObject } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MenteeAPI, successToast } from "@/services";
import type { BackgroundToolType, BackgroundType } from "@/types";

export function EditToolModal({
  initialRef,
  finalRef,
  background,
  selectedTool,
  isOpen,
  onClose,
  ...props
}: {
  initialRef: MutableRefObject<HTMLInputElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  background: BackgroundType;
  selectedTool?: BackgroundToolType;
} & Omit<ModalProps, "children">) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationKey: [`edit-background`],
    mutationFn: (data: Parameters<typeof MenteeAPI.editBackground>[0]) =>
      MenteeAPI.editBackground(data),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<BackgroundToolType>(
    selectedTool
      ? {
          defaultValues: selectedTool,
        }
      : {},
  );

  const handleSubmitTool = handleSubmit(async (edited_tool) => {
    try {
      const newTools = selectedTool
        ? background.tools.map((item) =>
            item.name === selectedTool.name ? edited_tool : item,
          )
        : [...background.tools, edited_tool];

      const res = await mutateAsync({
        ...background,
        tools: newTools,
      });

      if (res.status === 200) {
        successToast({
          title: `Your background tools updated successfully!`,
        });
        onClose();

        await queryClient.invalidateQueries({
          queryKey: ["background"],
        });
      }
    } catch (error) {
      console.error({ error });
    }
  });

  const { ref: helInputRef, ...helInputRegister } = register("name");

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      // isCentered
      size={{ base: "full", sm: "md" }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmitTool} noValidate>
        <ModalHeader>
          {selectedTool ? `Edit Tool: "${selectedTool.name}"` : "Add New Tool"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          <FormControl isRequired id="name" isInvalid={Boolean(errors.name)}>
            <FormLabel htmlFor="name" fontWeight="normal">
              Name:
            </FormLabel>
            <Input
              placeholder="Python"
              isDisabled={isSubmitting}
              {...helInputRegister}
              ref={(e) => {
                helInputRef(e);
                // it need to support focus on first input
                if (e) {
                  initialRef.current = e;
                }
              }}
            />
            {errors.name && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isRequired
            id="rating"
            isInvalid={Boolean(errors.rating)}
          >
            <FormLabel htmlFor="rating" fontWeight="normal">
              Rating:
            </FormLabel>
            <Input
              type="number"
              min="1"
              max="5"
              step="1"
              placeholder="0"
              isDisabled={isSubmitting}
              {...register("rating", {
                required: "This field is required",
                min: { value: 1, message: "The rating must grater than 1" },
                max: { value: 5, message: "The rating must lower than 5" },
              })}
            />
            {errors.rating && (
              <FormErrorMessage>{errors.rating.message}</FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            colorScheme="brand"
            w="full"
          >
            {selectedTool ? "Edit" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
