import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DocumentUpload, Trash } from "iconsax-react";

import { failedToast, successToast, useAuthStore, UserAPI } from "@/services";
import { UserType } from "@/types";

// Add a constant for max file size (5MB)
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

type FormType = {
  first_name: string;
  last_name: string;
  email: string;
};

interface ProfilePersonalInfoProps extends FlexProps {
  firstName: string;
  lastName: string;
  email: string;
  type?: "own" | "mentor" | "mentee";
}

export function ProfilePersonalInfo({
  firstName,
  lastName,
  email,
  type = "own",
  ...rest
}: ProfilePersonalInfoProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { user, setUser } = useAuthStore();
  const queryClient = useQueryClient();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormType>({
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
      email,
    },
  });

  const { mutateAsync: updateUser, isPending: isSubmitting } = useMutation({
    mutationKey: ["update-user"],
    mutationFn: async (data: FormType) => UserAPI.updateUser(data),
    onSuccess: async (data) => {
      successToast({
        title: "Profile updated successfully",
      });

      queryClient.setQueryData(["profile"], data);
      setUser({
        ...data,
        full_name: `${data.first_name} ${data.last_name}`,
      });

      reset({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
      });
    },
    onError: () => {
      failedToast({
        title: "Profile update failed",
      });
    },
  });

  const { mutateAsync: uploadAvatar, isPending: isUploading } = useMutation({
    mutationKey: ["upload-avatar"],
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("avatar", file);

      return await UserAPI.updateAvatar(formData);
    },
    onSuccess: (data) => {
      successToast({
        title: "Avatar uploaded successfully",
      });

      setUser({
        ...(user as UserType),
        avatar: data.avatar_url,
      });

      queryClient.setQueryData(["profile"], (prev) =>
        prev
          ? {
              ...prev,
              avatar: data.avatar_url,
            }
          : {},
      );

      setSelectedFile(null);
    },
    onError: () => {
      failedToast({
        title: "Avatar upload failed",
      });
    },
  });

  const handleSubmitEdit = handleSubmit(
    async (values) => await updateUser({ ...user, ...values }),
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];

      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        failedToast({
          title: "File too large",
          description: "Please select an image smaller than 5MB.",
        });

        // Reset the input
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      setSelectedFile(file);
    }
  };

  const handleFileDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer?.files;
    if (files?.[0]) {
      const file = files[0];

      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        failedToast({
          title: "File too large",
          description: "Please select an image smaller than 5MB.",
        });
        return;
      }

      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      // Double check file size before upload as an additional safeguard
      if (selectedFile.size > MAX_FILE_SIZE) {
        failedToast({
          title: "File too large",
          description: "Please select an image smaller than 5MB.",
        });
        return;
      }

      await uploadAvatar(selectedFile);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);

    // Reset the file input value so the same file can be selected again
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCancel = () => {
    reset({
      first_name: firstName,
      last_name: lastName,
      email,
    });
    setSelectedFile(null);

    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const isOwnProfile = type === "own";
  const previewImage = selectedFile ? URL.createObjectURL(selectedFile) : null;

  return (
    <Flex
      {...(isOwnProfile
        ? {
            as: "form",
            onSubmit: handleSubmitEdit,
          }
        : {})}
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Personal info
        </Text>
        <Text fontSize="14px" color="gray.600">
          Update your photo and personal details.
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        <FormControl
          isRequired
          id="first_name"
          isInvalid={Boolean(errors.first_name)}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            First name
          </FormLabel>
          <Input
            type="text"
            isDisabled={!isOwnProfile || isSubmitting}
            {...(isOwnProfile
              ? {
                  placeholder: "John",
                  ...register("first_name", {
                    required:
                      "You should fill the first name to complete registration",
                  }),
                }
              : {
                  value: firstName || "",
                })}
          />
          {errors.first_name && (
            <FormErrorMessage>{errors.first_name.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isRequired
          id="last_name"
          isInvalid={Boolean(errors.last_name)}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Last name
          </FormLabel>
          <Input
            type="text"
            isDisabled={!isOwnProfile || isSubmitting}
            {...(isOwnProfile
              ? {
                  placeholder: "Doe",
                  ...register("last_name", {
                    required:
                      "You should fill the last name to complete profile",
                  }),
                }
              : {
                  value: lastName || "",
                })}
          />
          {errors.last_name && (
            <FormErrorMessage>{errors.last_name.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired isDisabled isReadOnly id="email">
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Email
          </FormLabel>
          <InputGroup>
            <InputLeftAddon bgColor="white" color="gray.500">
              <Icon as={EmailIcon} color="gray.500" />
            </InputLeftAddon>
            <Input
              type="email"
              defaultValue={email}
              pointerEvents="none"
              isDisabled
            />
          </InputGroup>
        </FormControl>
        <Flex direction="column" alignItems="start" gap={4}>
          <Box
            position="relative"
            width="full"
            borderRadius="xl"
            border="1px dashed"
            borderColor="gray.200"
            p={4}
            textAlign="center"
          >
            <Input
              ref={fileInputRef}
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              aria-hidden="true"
              accept="image/*"
              cursor="pointer"
              onChange={handleFileChange}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={handleFileDrop}
            />
            {previewImage ? (
              <Box position="relative">
                <Image
                  src={previewImage}
                  alt="Preview"
                  maxH="150px"
                  mx="auto"
                  borderRadius="md"
                />
                <Flex justifyContent="center" mt={4} gap={3}>
                  <Button
                    size="sm"
                    variant="outline"
                    leftIcon={<Icon as={Trash} boxSize={4} />}
                    onClick={handleRemoveFile}
                    isDisabled={isUploading}
                  >
                    Remove
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="brand"
                    isLoading={isUploading}
                    onClick={handleUpload}
                  >
                    Upload Avatar
                  </Button>
                </Flex>
              </Box>
            ) : (
              <VStack spacing={2}>
                <Icon
                  as={DocumentUpload}
                  boxSize={10}
                  bgColor="#F5F5F5"
                  borderRadius="full"
                  color="gray.500"
                  p={2.5}
                />
                <FormLabel
                  fontSize="14px"
                  fontWeight="medium"
                  color="gray.700"
                  m={0}
                >
                  <Text as="span" color="brand.600" fontWeight="medium">
                    Click to upload
                  </Text>{" "}
                  or drag and drop
                </FormLabel>
                <Text fontSize="12px" color="gray.500">
                  PNG, JPG (max. 5MB)
                </Text>
              </VStack>
            )}
          </Box>
        </Flex>
        {isDirty && (
          <Flex justifyContent="end" gap={4}>
            <Button onClick={handleCancel} variant="outline" colorScheme="red">
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting} colorScheme="brand">
              Save changes
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
