import { useState } from "react";
import { Outlet } from "react-router";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";

import { Footer, Header } from "@/components";
import { ACTION_BAR_HEIGHT, HEADER_HEIGHT } from "@/constants/config";
import { queryClient } from "@/index";
import { useAuthStore } from "@/services";

import { MobileActionBar } from "../dashboard-layout/components/mobile-action-bar";
import { MobileNav } from "../dashboard-layout/components/mobile-nav";

export function MainLayout() {
  const [queryClientState] = useState(() => queryClient);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return (
    <QueryClientProvider client={queryClientState}>
      {isAuthenticated ? (
        <MobileNav display="flex" position="fixed" top={0} zIndex={10} />
      ) : (
        <Header display="flex" position="sticky" top={0} zIndex={10} />
      )}

      <Box
        minH="full"
        bg={useColorModeValue("white", "gray.800")}
        overflowX="hidden"
        pt={isAuthenticated ? HEADER_HEIGHT : 0}
        pb={isAuthenticated ? ACTION_BAR_HEIGHT : 0}
      >
        <Flex
          w="auto"
          h="full"
          direction="column"
          justify="center"
          align="center"
        >
          <Flex
            as="main"
            direction="column"
            boxSize="full"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <Outlet />
          </Flex>

          <Footer />
        </Flex>
        {isAuthenticated && (
          <MobileActionBar
            display={{ base: "flex", md: "none" }}
            position="fixed"
            bottom={0}
            zIndex={10}
          />
        )}
      </Box>
    </QueryClientProvider>
  );
}
