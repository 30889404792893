import { ElementRef, useRef, useState } from "react";
import { Flex, FlexProps, Text, useDisclosure, Wrap } from "@chakra-ui/react";

import { EmptyState } from "@/components/empty-state";
import { ServiceCard } from "@/components/service-card";
import { CreateApplicationModal } from "@/pages/public/all-mentors/components/create-application-modal";
import { useAuthStore } from "@/services";
import { MentorType, ServiceType } from "@/types";

interface ProfileServicesProps extends FlexProps {
  mentor: MentorType;
  services: Array<ServiceType>;
}

export function ProfileServices({
  mentor,
  services,
  ...rest
}: ProfileServicesProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isAuthenticated } = useAuthStore();

  const initialRef = useRef<ElementRef<"textarea">>(null);
  const [selected, setSelected] = useState<ServiceType>();

  const handleApply = (selectedService: ServiceType) => {
    setSelected(selectedService);
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    onClose();
  };

  return (
    <Flex
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Services
        </Text>
        <Text fontSize="14px" color="gray.600">
          See the mentor’s services and apply/buy today!
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        {services?.length ? (
          <Wrap
            shouldWrapChildren
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "center", md: "flex-start" }}
            spacing={2}
          >
            {services.map((service) => (
              <ServiceCard
                key={service.id}
                service={service}
                mentor={mentor}
                isAuthenticated={isAuthenticated}
                onApply={handleApply}
                w="full"
              />
            ))}
          </Wrap>
        ) : (
          <Flex
            maxW={{ base: "full" }}
            w="full"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            flexShrink={0}
            p={5}
            rounded="lg"
            alignItems="center"
            direction="column"
          >
            <EmptyState
              name="mentors"
              emptyText="No mentors with this information found"
            />
          </Flex>
        )}
      </Flex>
      {isAuthenticated && selected && (
        <CreateApplicationModal
          key={+isOpen}
          initialRef={initialRef}
          selectedService={selected}
          selectedMentor={mentor}
          isOpen={isOpen}
          onClose={handleCloseModal}
          showServices={false}
        />
      )}
    </Flex>
  );
}
