import { useState } from "react";
import { Link } from "react-router";
import { StarIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  Image,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Heart, Tag } from "iconsax-react";

import {
  AUTH_ROUTES,
  DASHBOARD_ROUTES,
  PUBLIC_ROUTES,
} from "@/constants/routes";
import { useAuthStore } from "@/services";
import { MentorType, ServiceType } from "@/types";

interface ServiceCardProps extends FlexProps {
  service?: ServiceType;
  mentor?: MentorType;

  isOwn?: boolean;
  isAuthenticated?: boolean;

  onEdit?: (selected: ServiceType) => void;
  onDelete?: (selected: ServiceType) => void;
  onApply?: (selected: ServiceType) => void;
}

export function ServiceCard({
  service,
  mentor,
  isOwn,
  onEdit,
  onDelete,
  isAuthenticated = false,
  onApply,
  ...rest
}: ServiceCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const role = useAuthStore((state) => state.user?.role);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const isMentee = role === "mentee";

  const handleToggleExpand = () => {
    if (isMobile) {
      setIsExpanded((prev) => !prev);
    }
  };

  const showFullContent = isExpanded || !isMobile;

  const fullName = `${mentor?.first_name} ${mentor?.last_name}`;
  const isSubscription = service?.type === "subscription";

  return (
    <Flex
      minW={{ base: "300px", md: "375px" }}
      w="full"
      maxW={{ base: "full", md: "375px" }}
      p={{ base: 3, md: 5 }}
      bg={useColorModeValue("white", "gray.900")}
      rounded="lg"
      direction={showFullContent ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      gap={6}
      borderWidth="1px"
      borderRadius="lg"
      cursor={isMobile ? "pointer" : "default"}
      onClick={handleToggleExpand}
      transition="all 0.3s ease"
      {...rest}
    >
      <Box
        w={showFullContent ? "full" : "auto"}
        position="relative"
        borderRadius="lg"
        boxShadow="0px 6px 20px -4px rgba(55, 74, 89, 0.36)"
        transition="all 0.3s ease"
      >
        <Flex
          w="full"
          h={showFullContent ? "232px" : "138px"}
          p={showFullContent ? 2 : 0}
          backgroundImage={
            showFullContent || isOwn
              ? `/assets/images/services/service_bg_${
                  ((service?.id || 0) % 5) + 1
                }.png`
              : "none"
          }
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          borderRadius="lg"
          _before={{
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            backdropFilter: "grayscale(1)",
          }}
        >
          <Image
            w={showFullContent ? "100px" : "98px"}
            h={showFullContent ? "100px" : "138px"}
            objectFit="cover"
            borderRadius={showFullContent ? "full" : "lg"}
            border={showFullContent ? "4px solid #fff" : "none"}
            src={mentor?.avatar}
            alt={fullName}
            visibility={isOwn ? "hidden" : "visible"}
            zIndex={1}
          />
        </Flex>
        {/* Icons overlay */}
        {!isOwn && (
          <Flex
            w="full"
            px={2}
            position="absolute"
            top={2}
            zIndex={1}
            alignItems="start"
            justifyContent="end"
            gap={2}
            transition="all 0.3s ease"
          >
            <Icon
              as={Heart}
              color="brand.400"
              boxSize={showFullContent ? 6 : 4}
            />
          </Flex>
        )}
        {!isOwn && (
          <Flex
            w="full"
            px={2}
            position="absolute"
            bottom={2}
            zIndex={1}
            alignItems="end"
            justifyContent="space-between"
            gap={2}
            transition="all 0.3s ease"
          >
            <Icon
              as={Tag}
              color="brand.400"
              variant="Bold"
              boxSize={showFullContent ? 6 : 4}
            />
          </Flex>
        )}
      </Box>
      <Flex
        flex={1}
        w="full"
        direction="column"
        justifyContent="space-between"
        gap={showFullContent ? 4 : 1}
        transition="all 0.3s ease"
      >
        <Flex w="full" direction="column" order={showFullContent ? 1 : 0}>
          <Text
            fontSize={["14px", "20px", "24px"]}
            fontWeight="bold"
            noOfLines={1}
            transition="all 0.3s ease"
          >
            {service?.title}
          </Text>
          <Text
            fontSize={["10px", "14px", "16px"]}
            fontWeight="normal"
            color="#374A59"
            autoCapitalize="words"
          >
            By{" "}
            <Text
              as={Link}
              to={
                isOwn
                  ? DASHBOARD_ROUTES.SETTINGS
                  : `${PUBLIC_ROUTES.MENTOR_PROFILE}/${mentor?.id}`
              }
              color="brand.600"
            >
              {fullName}
            </Text>
          </Text>
        </Flex>
        <Flex alignItems="center" gap={2} order={showFullContent ? 0 : 1}>
          <Flex>
            {Array.from({ length: 5 }).map((_, index) => (
              <Icon
                key={index}
                as={StarIcon}
                boxSize={[2, 3, 4]}
                color={index < 3 ? "brand.400" : "gray.200"}
              />
            ))}
          </Flex>
          <Text
            fontSize={["10px", "12px", "14px"]}
            fontWeight="normal"
            color="#6E798C"
          >
            n/a (0 reviews)
          </Text>
        </Flex>
        <Box
          display={showFullContent ? "block" : "none"}
          order={2}
          opacity={showFullContent ? 1 : 0}
          transition="opacity 0.3s ease"
          style={{
            visibility: showFullContent ? "visible" : "hidden",
            height: showFullContent ? "auto" : 0,
          }}
        >
          <Text
            fontSize={["12px", "14px", "16px"]}
            fontWeight="normal"
            color="#374A59"
            noOfLines={3}
          >
            {service?.description}
          </Text>
        </Box>
        <Flex
          w="full"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          order={showFullContent ? 3 : 2}
        >
          <Text
            fontSize={["10px", "14px", "18px"]}
            fontWeight="normal"
            color="#374A59"
          >
            <Text
              as="span"
              fontSize={["16px", "18px", "24px"]}
              fontWeight="bold"
            >
              {service?.price ? `$${service.price}` : `Free`}/
            </Text>
            {service?.type === "session" ? "Session" : "Month"}
          </Text>
          {!isOwn && (
            <Badge
              display={showFullContent ? "block" : "none"}
              colorScheme="brand"
              boxSize="fit-content"
              borderRadius="lg"
              py={2}
              px={3}
            >
              <Text fontSize={["10px", "12px"]}> Preview Availability</Text>
            </Badge>
          )}
        </Flex>
        <Flex
          w="full"
          direction="row-reverse"
          justifyContent={showFullContent ? "start" : "end"}
          gap={showFullContent ? 2 : 0}
          order={showFullContent ? 2 : 3}
          transition="all 0.3s ease"
        >
          <Tooltip
            label={service?.need_approval ? "Need Approval" : "Auto Approval"}
            aria-label="Approval Tooltip"
            placement="top"
          >
            <Image
              src={
                !service?.need_approval
                  ? "/assets/images/icons/envelope.svg"
                  : "/assets/images/icons/envelope_open.svg"
              }
              borderRadius="lg"
              p={
                !service?.need_approval
                  ? showFullContent
                    ? 2.5
                    : 1.5
                  : undefined
              }
              boxSize={showFullContent ? "48px" : "32px"}
              bgColor={showFullContent ? "brand.50" : "transparent"}
              transition="all 0.3s ease"
            />
          </Tooltip>
          <Tooltip
            label={isSubscription ? "Subscription" : "One Session"}
            aria-label={
              isSubscription ? "Subscription Tooltip" : "One Session Tooltip"
            }
            placement="top"
          >
            {isSubscription ? (
              <Image
                src="/assets/images/icons/refresh.svg"
                borderRadius="lg"
                boxSize={showFullContent ? "48px" : "32px"}
                bgColor={showFullContent ? "brand.50" : "transparent"}
                transition="all 0.3s ease"
              />
            ) : (
              <Flex
                justifyContent="center"
                alignItems="center"
                borderRadius="lg"
                boxSize={showFullContent ? "48px" : "32px"}
                bgColor={showFullContent ? "brand.50" : "transparent"}
                transition="all 0.3s ease"
              >
                <Text
                  fontSize={{ base: "16px", md: "20px" }}
                  fontWeight="bold"
                  color="#7AB738"
                >
                  +1
                </Text>
              </Flex>
            )}
          </Tooltip>
        </Flex>
        {isOwn ? (
          <Button
            {...(onEdit && service
              ? {
                  onClick: () => onEdit(service),
                }
              : {
                  as: Link,
                  to: DASHBOARD_ROUTES.SERVICES,
                })}
            display={showFullContent ? "flex" : "none"}
            w="full"
            mt={4}
            colorScheme="brand"
            order={4}
          >
            Edit
          </Button>
        ) : (
          <Button
            {...(isAuthenticated
              ? isMentee
                ? {
                    onClick: () => service && onApply?.(service),
                  }
                : {}
              : {
                  as: Link,
                  to: AUTH_ROUTES.LOGIN,
                })}
            isDisabled={isAuthenticated && !isMentee}
            display={showFullContent ? "flex" : "none"}
            w="full"
            mt={4}
            colorScheme="brand"
            order={4}
          >
            {isAuthenticated && !isMentee ? "You aren't a mentee" : "Apply Now"}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
