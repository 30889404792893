import { Button, ButtonProps } from "@chakra-ui/react";

import { useAuthStore } from "@/services";
import { MentorshipStatusEnum, MentorshipType } from "@/types";

interface MentorshipActionButtonProps extends ButtonProps {
  isMentee: boolean;
  mentorship: MentorshipType;
  onBook?: (mentorship: MentorshipType) => void;
  onNext?: (mentorship: MentorshipType) => void;
  onFinish?: (mentorship: MentorshipType) => void;
  isLoading?: boolean;
}

export function MentorshipActionButton({
  isMentee,
  mentorship,
  onBook,
  onNext,
  onFinish,
  isLoading,
  ...rest
}: MentorshipActionButtonProps) {
  const user = useAuthStore((state) => state.user);
  // const CTABtnBg = useColorModeValue("#151f21", "gray.700");

  switch (mentorship.status) {
    case MentorshipStatusEnum.PAID:
      if (isMentee) {
        return (
          <Button
            onClick={() => onBook?.(mentorship)}
            isDisabled={!mentorship.booking_url || isLoading}
            isLoading={isLoading}
            colorScheme="brand"
            // bg={CTABtnBg}
            // color="white"
            // _hover={{
            //   transform: "translateY(-2px)",
            //   boxShadow: "lg",
            // }}
            {...rest}
          >
            Book 1:1 Meeting
          </Button>
        );
      }
      return (
        <Button
          as="a"
          href={`mailto:${mentorship.mentee?.email}?subject=A mentorship reminder from ${user?.full_name} (O'Mentors)&body=Hi ${mentorship.mentee?.first_name}, I would like to remind you about ...`}
          target="_blank"
          rel="noopener noreferrer"
          colorScheme="brand"
          {...rest}
        >
          Contact
        </Button>
      );

    case MentorshipStatusEnum.BOOKED:
      if (!isMentee) {
        const canFinish =
          mentorship.type === "session" || mentorship.progress === "phase3";

        return (
          <Button
            onClick={() =>
              canFinish ? onFinish?.(mentorship) : onNext?.(mentorship)
            }
            isDisabled={isLoading}
            isLoading={isLoading}
            colorScheme="brand"
            {...rest}
          >
            {canFinish ? "Finish Mentorship" : "Next Phase"}
          </Button>
        );
      }
      return (
        <Button isDisabled variant="outline" colorScheme="red" {...rest}>
          Finish Mentorship
        </Button>
      );

    case MentorshipStatusEnum.QUITTED:
    case MentorshipStatusEnum.CANCELED:
      if (isMentee) {
        return (
          <Button isDisabled {...rest}>
            Refund Request
          </Button>
        );
      }
      return (
        <Button isDisabled {...rest}>
          Make a Refund
        </Button>
      );

    case MentorshipStatusEnum.COMPLETED:
    case MentorshipStatusEnum.EXPIRED:
      if (isMentee) {
        return (
          <Button isDisabled {...rest}>
            Re-Apply
          </Button>
        );
      }
      return (
        <Button isDisabled colorScheme="brand" {...rest}>
          Contact
        </Button>
      );

    default:
      return (
        <Button isDisabled {...rest}>
          {isMentee ? "Application Sent" : "Done"}
        </Button>
      );
  }
}
