import { type LoaderFunctionArgs, type Params, redirect } from "react-router";
import { QueryClient } from "@tanstack/react-query";

import {
  AUTH_ROUTES,
  DASHBOARD_ROUTES,
  GOOGLE_ROUTES,
} from "@/constants/routes";
import { AuthAPI, successToast, useAuthStore } from "@/services";
import { captureNetworkError } from "@/services/error-handler";

import { getProfileQuery } from "../dashboard";

export const postGoogleOAuthCallbackQuery = (params: string) => ({
  queryKey: ["google-oauth-callback", params],
  queryFn: () => AuthAPI.googleCallback(params),
});

export const googleLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      const { setAccessToken } = useAuthStore.getState();

      const params = args.params as Params<"return_url">;

      const searchParams = window.location.search.replace("?", "");

      switch (params.return_url) {
        case GOOGLE_ROUTES.CALLBACK:
          if (!searchParams.includes("code")) {
            return redirect(AUTH_ROUTES.LOGIN);
          }

          await queryClient
            .fetchQuery(postGoogleOAuthCallbackQuery(searchParams))
            .then((res) => {
              if (res?.access_token) {
                successToast({ title: "Logging in successfully!" });
                setAccessToken(res.access_token);

                // Fetch user info after successful login
                queryClient.fetchQuery(getProfileQuery()).then(() => {
                  redirect(DASHBOARD_ROUTES.HOME);
                });
              }
            });
          return redirect(AUTH_ROUTES.LOGIN);

        default:
          return redirect(AUTH_ROUTES.LOGIN);
      }
    } catch (error) {
      captureNetworkError(error);
    }
  };
