import { ElementRef, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router";
import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { SearchNormal } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { MentorCard } from "@/components/mentor-card";
import { PUBLIC_ROUTES } from "@/constants/routes";
import { PublicAPI, useAuthStore } from "@/services";
import { MentorType } from "@/types";
import { filterByName } from "@/utils/filter-by-name";

import { NewsletterSection } from "../components/newsletter-section";

import { CreateApplicationModal } from "./components/create-application-modal";
import { getPublicMentorsQuery } from "./loading";

export function AllMentorsPage() {
  const { data: initialData, isFetching } =
    useQuery<PublicAPI.GetMentorsResponse>(getPublicMentorsQuery());

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isAuthenticated, user } = useAuthStore();

  const initialRef = useRef<ElementRef<"textarea">>(null);
  const [selected, setSelected] = useState<MentorType>();

  const { register, watch } = useForm<{ query: string }>({
    defaultValues: { query: "" },
  });

  const filteredMentors = initialData?.filter(
    (mentor) =>
      filterByName(mentor.first_name, watch("query")) ||
      filterByName(mentor.last_name, watch("query")),
  );

  const handleApply = (selectedMentor: MentorType) => {
    setSelected(selectedMentor);
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    onClose();
  };

  return (
    <Flex
      flex={1}
      w="full"
      maxW="full"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={3}
        py={{ base: 6, lg: "40px" }}
        px={4}
      >
        <Text
          fontWeight="semibold"
          fontSize={{ base: "sm", lg: "md" }}
          color="#98C045"
          lineHeight={{ base: "20px", lg: "24px" }}
        >
          All Mentors
        </Text>
        <Text
          fontWeight="600"
          fontSize={{ base: "36px", lg: "48px" }}
          lineHeight={{ base: "44px", lg: "60px" }}
        >
          Find a mentor
        </Text>
        <Text
          textAlign="center"
          color="#5F5D5A"
          fontWeight="400"
          lineHeight="28px"
          fontSize={{ base: "18px", lg: "20px" }}
        >
          Looking for a mentor? Explore all the available mentors below.
        </Text>

        <Button
          as={Link}
          to={PUBLIC_ROUTES.ALL_SERVICES}
          w={{ base: "full", md: "auto" }}
          p={6}
          mt={3}
          fontSize="18px"
          colorScheme="brand"
        >
          Explorer all services {"-->"}
        </Button>
      </Flex>
      <Flex
        as="section"
        w="full"
        maxW="932px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={4}
      >
        <InputGroup w="full" gap={5}>
          <Input
            ps={10}
            placeholder="Search"
            borderRadius="lg"
            borderColor="gray.400"
            color="gray.500"
            _placeholder={{
              color: "gray.500",
            }}
            _focus={{
              bg: "whiteAlpha.300",
            }}
            {...register("query")}
          />
          <InputLeftElement>
            <Icon as={SearchNormal} boxSize={5} color="gray.500" />
          </InputLeftElement>
        </InputGroup>

        <Flex w="full" direction="column" gap={6}>
          {isFetching ? (
            <LoadingState />
          ) : filteredMentors?.length ? (
            <Wrap
              shouldWrapChildren
              w="full"
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "center", md: "flex-start" }}
              spacing={6}
              py={10}
            >
              {filteredMentors.map((mentor) => (
                <MentorCard
                  key={mentor.id}
                  isAuthenticated={isAuthenticated}
                  isOwn={user?.role === "mentor" && user?.id === mentor?.id}
                  mentor={mentor}
                  onApply={handleApply}
                  w="full"
                />
              ))}
            </Wrap>
          ) : (
            <Flex
              maxW={{ base: "full" }}
              w="full"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              flexShrink={0}
              p={5}
              rounded="lg"
              alignItems="center"
              direction="column"
            >
              <EmptyState
                name="mentors"
                emptyText="No mentors with this information found"
              />
            </Flex>
          )}
        </Flex>

        {isAuthenticated && selected && (
          <CreateApplicationModal
            key={+isOpen}
            initialRef={initialRef}
            selectedMentor={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        )}
      </Flex>
      <NewsletterSection mt={40} />
    </Flex>
  );
}
