import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { AuthAPI, failedToast, successToast } from "@/services";
import { ErrorServerResponse } from "@/services/api/axios";

type FormType = {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
};

interface ProfilePasswordsProps extends FlexProps {}

export function ProfilePasswords({ ...rest }: ProfilePasswordsProps) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormType>({
    defaultValues: {
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    },
  });

  const { mutateAsync: changePassword, isPending: isSubmitting } = useMutation({
    mutationKey: [`change-password`],
    mutationFn: (params: Parameters<typeof AuthAPI.changePassword>[0]) =>
      AuthAPI.changePassword(params),
    onSuccess: async () => {
      successToast({
        title: "Password changed successfully",
      });
      reset({
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      });
    },
    onError: (error: AxiosError<ErrorServerResponse>) =>
      failedToast({
        title: "Password change failed",
        description: error.response?.data.message,
      }),
  });

  const handleSubmitEdit = handleSubmit(
    async (values) => await changePassword(values),
  );

  const handleCancel = () => {
    reset({
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    });
  };

  const isEditMode = true;

  return (
    <Flex
      {...(isEditMode
        ? {
            as: "form",
            onSubmit: handleSubmitEdit,
          }
        : {})}
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Security
        </Text>
        <Text fontSize="14px" color="gray.600">
          Change your password to keep your account secure.
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        <FormControl
          isRequired
          id="current_password"
          isInvalid={Boolean(errors.current_password)}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Current Password
          </FormLabel>
          <Input
            required
            type="password"
            {...register("current_password", {
              required: "You must specify your current password",
            })}
          />
          {errors.current_password && (
            <FormErrorMessage>
              {errors.current_password.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isRequired
          id="new_password"
          isInvalid={Boolean(errors.new_password)}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            New Password
          </FormLabel>
          <Input
            required
            type="password"
            {...register("new_password", {
              required: "You must specify a new password",
            })}
          />
          {errors.new_password && (
            <FormErrorMessage>{errors.new_password.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isRequired
          id="new_password_confirmation"
          isInvalid={Boolean(errors.new_password_confirmation)}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Confirm New Password
          </FormLabel>
          <Input
            required
            type="password"
            {...register("new_password_confirmation", {
              required: "You must confirm your new password",
              deps: ["new_password"],
              validate: (value, { new_password }) =>
                value === new_password || "Passwords do not match",
            })}
          />
          {errors.new_password_confirmation && (
            <FormErrorMessage>
              {errors.new_password_confirmation.message}
            </FormErrorMessage>
          )}
        </FormControl>
        {isDirty && (
          <Flex justifyContent="end" gap={4}>
            <Button onClick={handleCancel} variant="outline" colorScheme="red">
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting} colorScheme="brand">
              Save changes
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
