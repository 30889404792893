import { MentorshipActionStatusTypes, MentorshipType } from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getMentorships request
 * @author mentor, mentee
 */

export interface GetMentorshipsFilters {
  archived?: boolean;
}

export type GetMentorshipsResponse = MentorshipType[];

export const getMentorships = async <T extends GetMentorshipsResponse>(
  filters?: GetMentorshipsFilters,
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`mentorships`, {
      ...options,
      params: filters,
    })
    .then((res) => res.data);
};

/**
 * getSingleMentorship request
 * @author mentor, mentee
 */

export type GetSingleMentorshipResponse = MentorshipType;

export const getSingleMentorship = async <
  T extends GetSingleMentorshipResponse,
>(
  mentorship_id: MentorshipType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`mentorships/${mentorship_id}`, options)
    .then((res) => res.data);
};

/**
 * performActionOnMentorship request
 * @author mentor
 */

export type PerformActionOnMentorshipPayload = {
  action: MentorshipActionStatusTypes;
};

export type PerformActionOnMentorshipResponse = MentorshipType;

export const performActionOnMentorship = async <
  T extends PerformActionOnMentorshipResponse,
>(
  mentorship_id: MentorshipType["id"],
  data: PerformActionOnMentorshipPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(
      `mentorships/${mentorship_id}/action`,
      data,
      options,
    )
    .then((res) => res);
};

/**
 * cancelMentorship request
 * @author mentee
 */

export type CancelMentorshipResponse = MentorshipType;

export const cancelMentorship = async <T extends CancelMentorshipResponse>(
  mentorship_id: MentorshipType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios.post<T, SuccessResponseType<T>>(
    `mentorships/${mentorship_id}/cancel`,
    {},
    options,
  );
};

/**
 * bookMentorship request
 * @author mentee
 */

export type BookMentorshipResponse = {
  booking_url: string;
};

export const bookMentorship = async <T extends BookMentorshipResponse>(
  mentorship_id: MentorshipType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios.post<T, SuccessResponseType<T>>(
    `mentorships/${mentorship_id}/book`,
    {},
    options,
  );
};
