import { Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";

export function TermsOfServicesPage() {
  return (
    <Flex
      flex={1}
      w="full"
      maxW="full"
      px={4}
      pt={{ base: 6, md: 10 }}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      gap={{ base: 6, md: 10 }}
    >
      <Flex
        w="full"
        maxW="768px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={3}
      >
        <Text
          fontSize={{ base: "14px", md: "16px" }}
          fontWeight="normal"
          color="brand.500"
        >
          Current as of 6 Jan 2025
        </Text>
        <Text fontSize={{ base: "36px", md: "48px" }} fontWeight="600">
          Terms of Service
        </Text>
        <Text
          fontSize={{ base: "16px", md: "20px" }}
          fontWeight="normal"
          textAlign="center"
          color="gray.600"
        >
          Welcome to O&apos;Mentors! These Terms of Service (&quot;Terms&quot;)
          govern your use of the O&apos;Mentors platform, including our website
          and services. By accessing or using O&apos;Mentors, you agree to
          comply with and be bound by these Terms. If you do not agree to these
          Terms, please do not use our services.
        </Text>
      </Flex>
      <Flex
        as="section"
        w="full"
        maxW="768px"
        py={{ base: 6, md: 10 }}
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={{ base: 6, md: 10 }}
      >
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Overview of O’Mentors
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            O&apos;Mentors is a platform that connects mentees with mentors in
            the fields of Data Science, Machine Learning, Program Management,
            and People Leadership for personalized, 1-on-1 mentoring sessions.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Eligibility
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            You must be at least 18 years old to use O&apos;Mentors. By
            accessing or using our platform, you confirm that you are at least
            18 years old and capable of entering into a legally binding
            agreement.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Account Registration
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            To use O&apos;Mentors, you must create an account. You agree to
            provide accurate, current, and complete information during the
            registration process and to update it as necessary. You are
            responsible for maintaining the confidentiality of your account
            login details and for all activities under your account.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Use of the Platform
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            <Text as="span" fontWeight="bold">
              For Mentees:
            </Text>{" "}
            You can browse available mentors, send applications, and schedule
            1-on-1 sessions. Once an application is accepted and payment is
            processed, you may begin your mentorship.
          </Text>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            <Text as="span" fontWeight="bold">
              For Mentors:
            </Text>{" "}
            You can create a profile, list your services, and accept
            applications from mentees. Mentors are responsible for providing
            their services in a professional manner.
          </Text>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            You agree to use O&apos;Mentors only for lawful purposes and in
            compliance with these Terms. You must not use the platform to:
          </Text>
          <br />
          <UnorderedList>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                Violate any applicable laws or regulations.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                Distribute harmful or offensive content.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                Harass, abuse, or harm others.
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Payment and Fees
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            O&apos;Mentors charges a fee for mentoring services. Mentees will be
            required to pay after an application has been accepted by a mentor.
            The fees may vary depending on the mentor’s services. Payments will
            be processed through secure payment gateways.
          </Text>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            Mentors will receive payment after successful mentoring sessions and
            will be subject to a commission or fee as outlined on the platform.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Cancellation and Refunds
          </Text>
          <UnorderedList>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  For Mentees:
                </Text>{" "}
                You may request a full refund if a session has not been booked
                within 30 days of payment. Refund requests must be made through
                the platform&apos;s support system.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  For Mentors:
                </Text>{" "}
                You may cancel or reschedule sessions with proper notice as
                agreed upon with the mentee.
              </Text>
            </ListItem>
          </UnorderedList>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            Refunds will be processed at the discretion of O&apos;Mentors based
            on the circumstances and platform policies.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Confidentiality
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            Both mentors and mentees agree to maintain the confidentiality of
            any sensitive, personal, or business-related information shared
            during mentoring sessions. Information shared should not be used
            outside of the scope of the mentoring relationship without explicit
            consent.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Termination
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            We may suspend or terminate your account at our sole discretion if
            we believe you have violated these Terms. Upon termination, you will
            lose access to O&apos;Mentors, but any fees owed will remain due.
          </Text>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            You may terminate your account at any time by contacting support.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Disclaimers and Limitation of Liability
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            O&apos;Mentors provides a platform for mentorship, but we do not
            guarantee the quality or effectiveness of mentoring sessions.
            Mentors are independent contractors and are not employees of
            O&apos;Mentors.
          </Text>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            We do not assume responsibility for any loss, damage, or issues
            arising from the use of the platform, including but not limited to:
          </Text>
          <br />
          <UnorderedList>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                Any interactions between mentors and mentees.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                Disputes, dissatisfaction, or personal harm caused by mentorship
                sessions.
              </Text>
            </ListItem>
          </UnorderedList>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            To the maximum extent permitted by law, O&apos;Mentors’ liability
            will be limited to the amount you have paid for services through the
            platform.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Intellectual Property
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            All content, features, and functionality on O&apos;Mentors,
            including text, images, graphics, and logos, are the property of
            O&apos;Mentors and are protected by copyright laws. You may not
            reproduce, distribute, or modify the content without prior written
            consent.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Privacy
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            Your use of O&apos;Mentors is also governed by our Privacy Policy,
            which explains how we collect, use, and protect your personal
            information. Please review our Privacy Policy carefully.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Modifications to Terms
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            We may update these Terms from time to time. Any changes will be
            posted on this page, and the effective date will be updated. By
            continuing to use the platform after any changes, you agree to be
            bound by the revised Terms.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Governing Law
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            These Terms are governed by and construed in accordance with the
            laws of the State of Missouri, USA. Any disputes arising under or in
            connection with these Terms will be subject to the exclusive
            jurisdiction of the courts in Missouri.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Contact Us
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            If you have any questions about these Terms, please contact us at:
          </Text>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            Email:{" "}
            <a href="mailto:support@omentors.com">support@omentors.com</a>
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            Website: <a href="https://www.omentors.com">www.omentors.com</a>
          </Text>
          <br />
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            By using O&apos;Mentors, you acknowledge that you have read,
            understood, and agreed to these Terms of Service.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
