import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import { AddCircle, MinusCirlce } from "iconsax-react";

const generalFAQs = [
  // General Questions
  {
    question: "What is this platform?",
    answer:
      "Our platform connects aspiring data professionals with mentors specializing in Data Science, Machine Learning, Program Management, and People Leadership careers in the U.S. and Canada.",
  },
  {
    question: "How does the platform work?",
    answer:
      "Mentors and mentees create profiles, browse available matches, and apply to connect. Mentees pay only after an accepted application to start a 1-on-1 mentorship.",
  },
  {
    question: "Who can join the platform?",
    answer:
      "Anyone interested in growing a career in data fields can join as a mentee, while experienced professionals in these areas can apply to become mentors.",
  },
  {
    question: "Is it free to use the platform?",
    answer:
      "Yes, it’s free to sign up, explore mentors, and send/receive applications. Mentees are charged only when their application is accepted, and they are ready to start sessions.",
  },
  {
    question: "What specific areas of expertise are covered?",
    answer:
      "Mentors cover general data-related topics such as resume reviews, Python, and Machine Learning, as well as specialized areas like Deep Learning, Gen-AI, and Retrieval-Augmented Generation (RAGs).",
  },
];
const menteeFAQs = [
  // For Mentees
  {
    question: "How do I find the right mentor for me?",
    answer:
      "Browse mentors by specialization, experience, and reviews, with the option to explore specific skills and topics relevant to Data careers.",
  },
  {
    question: "What should I expect from a mentorship session?",
    answer:
      "Sessions are personalized based on your goals and may include technical guidance, career advice, project feedback, or job search tips.",
  },
  {
    question: "How do I prepare for my first session with a mentor?",
    answer:
      "Bring specific questions, career objectives, and any relevant background information on the Data Science topics you wish to discuss.",
  },
  {
    question: "Can I work with more than one mentor at a time?",
    answer:
      "Yes, each mentee can connect with up to two mentors simultaneously, allowing for guidance in different areas.",
  },
  {
    question:
      "What happens if my mentor-mentee relationship isn’t working out?",
    answer:
      "If the mentorship doesn’t meet your expectations, you can request a new match or a full refund.",
  },
  {
    question: "How long will it take to hear back from a mentor?",
    answer: "Mentors respond within seven days of receiving your application.",
  },
  {
    question:
      "How long do I have to make a payment after an accepted application?",
    answer:
      "You have seven days to complete payment after an application is accepted to secure your mentorship.",
  },
  {
    question: "What if I don’t book my session after payment?",
    answer:
      "After successful payment, you have 30 days to schedule a session with your mentor, or the mentorship will expire.",
  },
];
const mentorFAQs = [
  // For Mentors
  {
    question: "How do I become a mentor on the platform?",
    answer:
      "Apply by creating a mentor profile and detailing your experience and the areas of Data careers you specialize in.",
  },
  {
    question: "Do mentors get compensated?",
    answer:
      "Yes, mentors can set fees for their services, and our platform provides fast, secure payment solutions.",
  },
  {
    question: "How many mentees can I have at one time?",
    answer: "Mentors can work with up to five mentees simultaneously.",
  },
  {
    question: "What services can I offer as a mentor?",
    answer:
      "Mentors can create and offer general services like resume reviews, Python, and Machine Learning, as well as niche areas like Deep Learning, Gen-AI, and RAGs.",
  },
  {
    question: "How much time commitment is expected from mentors?",
    answer:
      "Mentors set their own schedules, so the commitment is entirely flexible.",
  },
];
const technicalFAQs = [
  //  Technical and Account Information
  {
    question: "What are the benefits of being a mentor?",
    answer:
      "Mentoring allows you to share expertise, build your network, and support the next generation of Data professionals.",
  },
  {
    question: "How do I contact support if I have issues?",
    answer:
      "Reach out to our support team through the help center or by emailing us at [info@omentors.com].",
  },
];

export function AccordionHome() {
  return (
    <Container
      as="section"
      variant="banner"
      maxW="full"
      w="full"
      py={{ base: 6, lg: 8 }}
      px={{ base: 18, lg: 28 }}
      display="flex"
      flexDir="column"
      alignItems="center"
      bg={{ base: "#F7F5F4", lg: "inherit" }}
    >
      <Flex direction="column" maxW="container.xl" w="full">
        <Heading as="h2" fontSize="xl" textAlign="center" mb={4}>
          Frequently asked questions
        </Heading>
        <Text textAlign="center" mb={8} color="#5F5D5A">
          Everything you need to know about being mentor and mentee.
        </Text>
        <Flex direction="column" gap={10}>
          <Flex direction="column">
            <Heading as="h3" fontSize="lg" textAlign="start" mb={4}>
              General Questions
            </Heading>
            <Accordion allowMultiple defaultIndex={[0]}>
              {generalFAQs.map((faq, index) => (
                <AccordionItem
                  key={index}
                  border="none"
                  borderBottom={index === 5 ? "none" : "1px solid"}
                  borderColor="gray.200"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        py={4}
                        _hover={{ bg: "transparent" }}
                        _focus={{ boxShadow: "none" }}
                      >
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          {faq.question}
                        </Box>
                        {isExpanded ? (
                          <Icon
                            as={MinusCirlce}
                            boxSize="24px"
                            color="#A39C96"
                          />
                        ) : (
                          <Icon as={AddCircle} boxSize="24px" color="#A39C96" />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4} pt={0}>
                        <Text>{faq.answer}</Text>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
          <Flex direction="column">
            <Heading as="h3" fontSize="lg" textAlign="start" mb={4}>
              For Mentees
            </Heading>
            <Accordion allowMultiple defaultIndex={[0]}>
              {menteeFAQs.map((faq, index) => (
                <AccordionItem
                  key={index}
                  border="none"
                  borderBottom={index === 5 ? "none" : "1px solid"}
                  borderColor="gray.200"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        py={4}
                        _hover={{ bg: "transparent" }}
                        _focus={{ boxShadow: "none" }}
                      >
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          {faq.question}
                        </Box>
                        {isExpanded ? (
                          <Icon
                            as={MinusCirlce}
                            boxSize="24px"
                            color="#A39C96"
                          />
                        ) : (
                          <Icon as={AddCircle} boxSize="24px" color="#A39C96" />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4} pt={0}>
                        <Text>{faq.answer}</Text>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>

          <Flex direction="column">
            <Heading as="h3" fontSize="lg" textAlign="start" mb={4}>
              For Mentors
            </Heading>
            <Accordion allowMultiple defaultIndex={[0]}>
              {mentorFAQs.map((faq, index) => (
                <AccordionItem
                  key={index}
                  border="none"
                  borderBottom={index === 5 ? "none" : "1px solid"}
                  borderColor="gray.200"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        py={4}
                        _hover={{ bg: "transparent" }}
                        _focus={{ boxShadow: "none" }}
                      >
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          {faq.question}
                        </Box>
                        {isExpanded ? (
                          <Icon
                            as={MinusCirlce}
                            boxSize="24px"
                            color="#A39C96"
                          />
                        ) : (
                          <Icon as={AddCircle} boxSize="24px" color="#A39C96" />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4} pt={0}>
                        <Text>{faq.answer}</Text>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
          <Flex direction="column">
            <Heading as="h3" fontSize="lg" textAlign="start" mb={4}>
              Technical and Account Information
            </Heading>
            <Accordion allowMultiple defaultIndex={[0]}>
              {technicalFAQs.map((faq, index) => (
                <AccordionItem
                  key={index}
                  border="none"
                  borderBottom={index === 5 ? "none" : "1px solid"}
                  borderColor="gray.200"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        py={4}
                        _hover={{ bg: "transparent" }}
                        _focus={{ boxShadow: "none" }}
                      >
                        <Box flex="1" textAlign="left" fontWeight="medium">
                          {faq.question}
                        </Box>
                        {isExpanded ? (
                          <Icon
                            as={MinusCirlce}
                            boxSize="24px"
                            color="#A39C96"
                          />
                        ) : (
                          <Icon as={AddCircle} boxSize="24px" color="#A39C96" />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4} pt={0}>
                        <Text>{faq.answer}</Text>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
}
