import { ElementRef, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router";
import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { SearchNormal } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { ServiceCard } from "@/components/service-card";
import { PUBLIC_ROUTES } from "@/constants/routes";
import { useAuthStore } from "@/services";
import { ServiceType } from "@/types";
import { filterByName } from "@/utils/filter-by-name";

import { CreateApplicationModal } from "../all-mentors/components/create-application-modal";
import { NewsletterSection } from "../components/newsletter-section";

import { getPublicServicesQuery } from "./loading";

export function AllServicesPage() {
  const { data: services = [], isFetching } = useQuery<Array<ServiceType>>(
    getPublicServicesQuery(),
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isAuthenticated, user } = useAuthStore();

  const initialRef = useRef<ElementRef<"textarea">>(null);
  const [selected, setSelected] = useState<ServiceType>();

  const { register, watch } = useForm<{ query: string }>({
    defaultValues: { query: "" },
  });

  const filteredServices =
    services?.filter(
      (service) =>
        filterByName(service.title, watch("query")) ||
        (service.mentor &&
          (filterByName(service.mentor.first_name, watch("query")) ||
            filterByName(service.mentor.last_name, watch("query")))),
    ) || [];

  const handleApply = (selectedService: ServiceType) => {
    setSelected(selectedService);
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    onClose();
  };

  return (
    <Flex
      flex={1}
      w="full"
      maxW="full"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={3}
        py={{ base: 6, lg: "40px" }}
        px={4}
      >
        <Text
          fontWeight="semibold"
          fontSize={{ base: "sm", lg: "md" }}
          color="#98C045"
          lineHeight={{ base: "20px", lg: "24px" }}
        >
          All Services
        </Text>
        <Text
          fontWeight="600"
          fontSize={{ base: "36px", lg: "48px" }}
          lineHeight={{ base: "44px", lg: "60px" }}
        >
          Find a service
        </Text>
        <Text
          textAlign="center"
          color="#5F5D5A"
          fontWeight="400"
          lineHeight="28px"
          fontSize={{ base: "18px", lg: "20px" }}
        >
          Need a particular mentorship service? Explore all the available
          options below.
        </Text>

        <Button
          as={Link}
          to={PUBLIC_ROUTES.ALL_MENTORS}
          w={{ base: "full", md: "auto" }}
          p={6}
          mt={3}
          fontSize="18px"
          colorScheme="brand"
        >
          Explorer all mentors {"-->"}
        </Button>
      </Flex>
      <Flex
        as="section"
        w="full"
        maxW="932px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        px={4}
      >
        <InputGroup w="full" gap={5}>
          <Input
            ps={10}
            placeholder="Search"
            borderRadius="lg"
            borderColor="gray.400"
            color="gray.500"
            _placeholder={{
              color: "gray.500",
            }}
            _focus={{
              bg: "whiteAlpha.300",
            }}
            {...register("query")}
          />
          <InputLeftElement>
            <Icon as={SearchNormal} boxSize={5} color="gray.500" />
          </InputLeftElement>
        </InputGroup>

        <Flex direction="column" gap={6}>
          {isFetching ? (
            <LoadingState />
          ) : filteredServices?.length ? (
            <Wrap
              shouldWrapChildren
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "center", md: "flex-start" }}
              spacing={6}
              py={10}
            >
              {filteredServices.map((service) => (
                <ServiceCard
                  key={service.id}
                  service={service}
                  mentor={service.mentor}
                  isAuthenticated={isAuthenticated}
                  isOwn={
                    user?.role === "mentor" && user?.id === service.mentor?.id
                  }
                  onApply={handleApply}
                  w="full"
                />
              ))}
            </Wrap>
          ) : (
            <Flex
              maxW={{ base: "full" }}
              w="full"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              flexShrink={0}
              p={5}
              rounded="lg"
              alignItems="center"
              direction="column"
            >
              <EmptyState
                name="mentors"
                emptyText="No mentors with this information found"
              />
            </Flex>
          )}
        </Flex>

        {isAuthenticated && selected && (
          <CreateApplicationModal
            key={+isOpen}
            initialRef={initialRef}
            selectedService={selected}
            selectedMentor={selected?.mentor}
            isOpen={isOpen}
            onClose={handleCloseModal}
            showServices={false}
          />
        )}
      </Flex>
      <NewsletterSection mt={40} />
    </Flex>
  );
}
