import {
  Button,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Edit } from "iconsax-react";

import { BackgroundType } from "@/types";

interface EducationProps extends Omit<FlexProps, "background"> {
  background: BackgroundType;
  showTitle?: boolean;
  onClick?: () => void;
}

export function Education({
  background,
  showTitle = true,
  onClick,
  ...rest
}: EducationProps) {
  return (
    <Flex
      w="full"
      bg={useColorModeValue("white", "gray.900")}
      rounded="md"
      p={6}
      overflow="hidden"
      borderWidth="1px"
      borderRadius="md"
      gap={4}
      direction="column"
      {...rest}
    >
      {(showTitle || onClick) && (
        <HStack justify={showTitle ? "space-between" : "end"} align="center">
          {showTitle && (
            <Text
              color="brand.500"
              textTransform="uppercase"
              fontWeight={800}
              fontSize="md"
              letterSpacing={1.1}
            >
              Education
            </Text>
          )}
          {onClick &&
            (showTitle ? (
              <Button
                variant="ghost"
                colorScheme="gray"
                size="sm"
                onClick={onClick}
              >
                <Edit size={20} />
              </Button>
            ) : (
              <IconButton
                aria-label="1"
                icon={<Edit size={20} />}
                colorScheme="brand"
                borderRadius="full"
                onClick={onClick}
              />
            ))}
        </HStack>
      )}
      <Flex
        direction={{ base: "column", lg: "row" }}
        gap={4}
        align={{ base: "flex-start", lg: "center" }}
      >
        <Stack direction="column" spacing={0} fontSize="sm" w="full">
          <Text fontWeight={600}>Highest Education Level:</Text>
          <Text color="gray.500">
            {background.education.highest_education_level || "N/A"}
          </Text>
        </Stack>
        <Stack direction="column" spacing={0} fontSize="sm" w="full">
          <Text fontWeight={600}>Field of Study:</Text>
          <Text color="gray.500">
            {background.education.field_of_study || "N/A"}
          </Text>
        </Stack>
      </Flex>
      <Flex
        direction={{ base: "column", lg: "row" }}
        mt={6}
        gap={4}
        align={{ base: "flex-start", lg: "center" }}
      >
        <Stack direction="column" spacing={0} fontSize="sm" w="full">
          <Text fontWeight={600}>Graduation Year:</Text>
          <Text color="gray.500">
            {background.education.graduation_year || "N/A"}
          </Text>
        </Stack>
        <Stack direction="column" spacing={0} fontSize="sm" w="full">
          <Text fontWeight={600}>Graduation Institute:</Text>
          <Text color="gray.500">
            {background.education.graduation_institute || "N/A"}
          </Text>
        </Stack>
      </Flex>
    </Flex>
  );
}
