import { captureNetworkError } from "@/services/error-handler";
import {
  ApplicationResponseStatusTypes,
  ApplicationType,
  MentorType,
  ServiceType,
} from "@/types";

import axios, { AxiosRequestConfig, SuccessResponseType } from "../axios";

/**
 * getApplications request
 * @author mentor, mentee
 */

export interface GetApplicationsFilters {
  archived?: boolean;
}

export type GetApplicationsResponse = ApplicationType[];

export const getApplications = async <T extends GetApplicationsResponse>(
  filters?: GetApplicationsFilters,
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`applications`, {
      ...options,
      params: filters,
    })
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * getSingleApplication request
 * @author mentor, mentee
 */

export type GetSingleApplicationResponse = ApplicationType;

export const getSingleApplication = async <
  T extends GetSingleApplicationResponse,
>(
  application_id: ApplicationType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .get<T, SuccessResponseType<T>>(`applications/${application_id}`, options)
    .then((res) => res.data)
    .catch(captureNetworkError);
};

/**
 * applyToApplication request
 * @author mentee
 */

export type ApplyToApplicationPayload = {
  mentor_id: MentorType["id"];
  service_id: ServiceType["id"];
  request_note?: string | null;
};

export type ApplyToApplicationResponse = ApplicationType;

export const applyToApplication = async <T extends ApplyToApplicationResponse>(
  data: ApplyToApplicationPayload,
  options?: AxiosRequestConfig,
) => {
  return axios.post<T, SuccessResponseType<T>>(
    `applications/apply`,
    data,
    options,
  );
};

/**
 * payApplication request
 * @author mentee
 */

export type PayApplicationResponse = {
  url: string;
};

export const payApplication = async <T extends PayApplicationResponse>(
  application_id: ApplicationType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(
      `applications/${application_id}/pay`,
      undefined,
      options,
    )
    .catch(captureNetworkError);
};

/**
 * withdrawApplication request
 * @author mentee
 */

export type WithdrawApplicationPayload = {
  mentor_id: string;
  request_note?: string;
};

export type WithdrawApplicationResponse = ApplicationType;

export const withdrawApplication = async <
  T extends WithdrawApplicationResponse,
>(
  application_id: ApplicationType["id"],
  options?: AxiosRequestConfig,
) => {
  return axios.post<T, SuccessResponseType<T>>(
    `applications/${application_id}/withdraw`,
    undefined,
    options,
  );
};

/**
 * respondApplication request
 * @author mentor
 */

export type RespondApplicationPayload = {
  action: ApplicationResponseStatusTypes;
  response_message?: string | null;
};

export type RespondApplicationResponse = ApplicationType;

export const respondApplication = async <T extends RespondApplicationResponse>(
  application_id: ApplicationType["id"],
  data: RespondApplicationPayload,
  options?: AxiosRequestConfig,
) => {
  return axios
    .post<T, SuccessResponseType<T>>(
      `applications/${application_id}/response`,
      data,
      options,
    )
    .then((res) => res);
};
