import { Link } from "react-router";
import {
  Flex,
  type FlexProps,
  HStack,
  IconButton,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { Lifebuoy } from "iconsax-react";

import { HEADER_HEIGHT } from "@/constants/config";
import { PUBLIC_ROUTES } from "@/constants/routes";

import { NotificationMenu } from "./notification-menu";
import { UserMenu } from "./user-menu";

export interface MobileProps extends FlexProps {
  variant?: "main" | "dashboard";
}

export function MobileNav({ variant, ...rest }: MobileProps) {
  const isDashboard = variant === "dashboard";

  return (
    <Flex
      as="header"
      variant="banner"
      maxW="full"
      w="full"
      h={HEADER_HEIGHT}
      ml={isDashboard ? { base: 0, md: 60 } : 0}
      px={isDashboard ? { base: 4, md: 4 } : { base: 18, lg: 28 }}
      py={{ base: 4, lg: 4 }}
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={
        isDashboard
          ? { base: "space-between", md: "flex-end" }
          : "space-between"
      }
      {...rest}
    >
      <Flex
        as={Link}
        to={PUBLIC_ROUTES.HOME}
        alignItems="center"
        gap={2}
        display={isDashboard ? { base: "flex", md: "none" } : "flex"}
      >
        <Image src="/assets/images/logo_full.svg" alt="O'Mentors" h={8} />
      </Flex>
      <HStack spacing={{ base: 0, md: 2 }}>
        <IconButton
          as={Link}
          to={PUBLIC_ROUTES.CONTACT}
          boxSize={10}
          p={2}
          color="#717680"
          variant="ghost"
          aria-label="support"
        >
          <Lifebuoy />
        </IconButton>
        <NotificationMenu />
        <UserMenu />
      </HStack>
    </Flex>
  );
}
