import { Link } from "react-router";
import { Button, Flex, FlexProps, Input, Text } from "@chakra-ui/react";

import { PUBLIC_ROUTES } from "@/constants/routes";

interface NewsletterSectionProps extends FlexProps {}

export function NewsletterSection({ ...rest }: NewsletterSectionProps) {
  return (
    <Flex
      w="full"
      direction="column"
      justify="center"
      align="center"
      gap={5}
      bg="#FAFAFA"
      p={10}
      {...rest}
    >
      <Text
        fontSize="36px"
        fontWeight="bold"
        textAlign="center"
        color="gray.800"
      >
        Subscribe to our newsletter
      </Text>
      <Text fontSize="20px" textAlign="center" color="gray.600">
        Be the first to know about releases and industry news and insights.
      </Text>
      <Flex w="full" maxW="768px" direction="column" alignItems="center" mt={5}>
        <Flex
          as="form"
          w="full"
          maxW="495px"
          direction={{ base: "column", md: "row" }}
          justifyContent="start"
          gap={{ base: 6, md: 4 }}
        >
          <Input
            w="full"
            h={{ base: 10, md: 12 }}
            placeholder="Enter your email"
            bg="white"
            borderRadius="lg"
          />
          <Button
            h={{ base: 10, md: 12 }}
            colorScheme="brand"
            borderRadius="lg"
            px={8}
          >
            Subscribe
          </Button>
        </Flex>
        <Text w="full" maxW="495px" fontSize="14px" color="gray.600" mt={2}>
          We care about your data in our{" "}
          <Button
            variant="link"
            as={Link}
            to={PUBLIC_ROUTES.PRIVACY_POLICY}
            display="inline"
            fontSize="inherit"
            textStyle="underline"
          >
            privacy policy
          </Button>
        </Text>
      </Flex>
    </Flex>
  );
}
