import { FaLinkedin } from "react-icons/fa";
import { useLoaderData, useParams } from "react-router";
import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link1 } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { ProfileDetailsInfo } from "@/components/user-profile/components/profile-details-info";
import { ProfileHeader } from "@/components/user-profile/components/profile-header";
import { ProfileServices } from "@/components/user-profile/components/profile-services";
import { MentorType, Prettify } from "@/types";

import { getSingleMentorQuery } from "./loading";

export function MentorProfilePage() {
  const { mentorId = "" } = useParams<{ mentorId: string }>();
  const initialData = useLoaderData<MentorType>();

  const { data: profile = initialData, isFetching: profileFetching } = useQuery<
    Prettify<MentorType & MentorType>
  >(getSingleMentorQuery(mentorId));

  const fullname = `${profile?.first_name} ${profile?.last_name}`;
  const haveLinkedIn = profile?.external_link?.includes("linkedin.com");

  return (
    <Flex
      boxSize="full"
      px={{ base: 0, md: 8 }}
      py={{ base: 0, md: 10 }}
      direction="column"
      align="flex-start"
      justify="center"
      bg={useColorModeValue("white", "gray.900")}
      color={useColorModeValue("black", "gray.200")}
      mb={20} // Add margin bottom to avoid the footer
    >
      <Flex
        w="full"
        px={{ base: 8, md: 0 }}
        py={{ base: 4, md: 0 }}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        gap={6}
        display={{ base: "none", md: "block" }}
      >
        <Heading fontSize="3xl">Mentor&apos;s Profile</Heading>
      </Flex>

      <Flex
        boxSize="full"
        px={{ base: 4, md: 0 }}
        py={{ base: 3, md: 6 }}
        direction="column"
        gap={6}
      >
        {profileFetching ? (
          <LoadingState />
        ) : profile ? (
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={{ base: 5, md: 10 }}
          >
            <ProfileHeader
              slug={profile.id}
              avatar={profile.avatar}
              fullname={fullname}
              email={profile.email}
            >
              <Button
                as="a"
                href={profile.external_link || ""}
                target="_blank"
                rel="noopener noreferrer"
                isDisabled={!profile.external_link}
                leftIcon={
                  haveLinkedIn ? (
                    <Icon as={FaLinkedin} boxSize={5} color="#0077B5" />
                  ) : (
                    <Icon as={Link1} boxSize={5} />
                  )
                }
                colorScheme="gray"
                bgColor="white"
                border="2px solid"
                borderColor="gray.200"
                fontSize="14px"
                fontWeight="bold"
              >
                View {haveLinkedIn ? "LinkedIn" : "Website"}
              </Button>
              <Button
                as="a"
                href={`mailto:${profile.email}?subject=Hello ${fullname}&body=Hi ${fullname}, I would like to...`}
                target="_blank"
                rel="noopener noreferrer"
                colorScheme="brand"
                fontSize="14px"
                fontWeight="bold"
              >
                Send Email
              </Button>
            </ProfileHeader>

            {/* Profile Section */}
            <ProfileDetailsInfo
              externalLink={profile.external_link || ""}
              tagline={profile.tagline || ""}
              country={profile.country || ""}
              id="details-info"
              type="mentor"
            />
            <Divider />
            {/* Services Section */}
            <ProfileServices
              mentor={profile}
              services={profile.services || []}
              id="services-info"
            />
            <Divider />
          </Flex>
        ) : (
          <EmptyState name="notification" />
        )}
      </Flex>
    </Flex>
  );
}
