import { Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";

export function PrivacyAndPolicyPage() {
  return (
    <Flex
      flex={1}
      w="full"
      maxW="full"
      px={4}
      pt={{ base: 6, md: 10 }}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      gap={{ base: 6, md: 10 }}
    >
      <Flex
        w="full"
        maxW="768px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={3}
      >
        <Text
          fontSize={{ base: "14px", md: "16px" }}
          fontWeight="normal"
          color="brand.500"
        >
          Current as of 6 Jan 2025
        </Text>
        <Text fontSize={{ base: "36px", md: "48px" }} fontWeight="600">
          Privacy Policy
        </Text>
        <Text
          fontSize={{ base: "16px", md: "20px" }}
          fontWeight="normal"
          textAlign="center"
          color="gray.600"
        >
          At O&apos;Mentors, your privacy is important to us. This Privacy
          Policy outlines how we collect, use, and protect your personal
          information when you use our platform. By accessing or using
          O&apos;Mentors, you agree to the terms of this Privacy Policy.
        </Text>
      </Flex>
      <Flex
        as="section"
        w="full"
        maxW="768px"
        py={{ base: 6, md: 10 }}
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={{ base: 6, md: 10 }}
      >
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            What information do we collect?
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            We collect two types of information:
          </Text>
          <br />
          <UnorderedList>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  Personal Information:
                </Text>{" "}
                This includes information you provide when you sign up, create a
                profile, or use our services, such as your name, email address,
                phone number, and payment details.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  Usage Data:
                </Text>{" "}
                We automatically collect certain information when you interact
                with our platform, such as IP addresses, browser type, device
                information, and pages visited on our site.
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            How do we use your information?
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            We use the information we collect for the following purposes:
          </Text>
          <br />
          <UnorderedList>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                To provide and improve our services, including connecting
                mentees with mentors and facilitating communication.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                To process payments and manage your account.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                To send you relevant updates, promotions, and information
                related to your mentoring sessions
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                To personalize your experience and enhance platform features.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                To comply with legal requirements and protect our rights.
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Sharing Your Information
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            We do not sell, rent, or trade your personal information. However,
            we may share your information in the following situations:
          </Text>
          <br />
          <UnorderedList>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  With Mentors::
                </Text>{" "}
                To facilitate 1-on-1 mentoring sessions, your name, profile
                details, and session information will be shared with the mentor
                you are connected with.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  Service Providers:
                </Text>{" "}
                We may share information with trusted third-party service
                providers who help us operate our platform, process payments,
                and enhance user experience.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  Legal Requirements:
                </Text>{" "}
                We may disclose your information to comply with legal
                obligations, enforce our terms, or protect the rights, safety,
                or property of O&apos;Mentors or others.
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Data Security
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            We take reasonable measures to protect your personal information
            from unauthorized access, alteration, or disclosure. While we strive
            to use commercially acceptable means to protect your data, no method
            of transmission over the Internet or electronic storage is 100%
            secure.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Your Rights and Choices
          </Text>
          <UnorderedList>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  Access and Update:
                </Text>{" "}
                You can access and update your personal information by logging
                into your account.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  Opt-Out:
                </Text>{" "}
                You can unsubscribe from marketing communications by following
                the opt-out instructions in emails or by contacting us directly.
              </Text>
            </ListItem>
            <ListItem>
              <Text
                as="p"
                fontSize={{ base: "16px", md: "18px" }}
                fontWeight="normal"
                color="gray.600"
              >
                <Text as="span" fontWeight="bold">
                  Data Retention:
                </Text>{" "}
                We retain your personal information only as long as necessary to
                fulfill the purposes outlined in this policy or as required by
                law.
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Cookies
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            Our website uses cookies to enhance your experience. Cookies are
            small text files stored on your device that help us improve site
            functionality, analyze traffic, and customize content. You can
            control cookie settings through your browser preferences.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Children’s Privacy
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            O&apos;Mentors is not intended for children under the age of 13. We
            do not knowingly collect personal information from children. If we
            become aware that a child under 13 has provided us with personal
            information, we will take steps to remove such data.
          </Text>
        </Flex>
        <Flex direction="column" alignItems="start">
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="gray.900"
            mb={{ base: 5, md: 6 }}
          >
            Changes to This Privacy Policy
          </Text>
          <Text
            as="p"
            fontSize={{ base: "16px", md: "18px" }}
            fontWeight="normal"
            color="gray.600"
          >
            We may update this Privacy Policy from time to time. When we make
            changes, we will post the updated policy on this page with the
            revised date. Please review this policy periodically to stay
            informed.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
