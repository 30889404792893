import { ElementRef, useRef, useState } from "react";
import {
  Flex,
  FlexProps,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Add } from "iconsax-react";

import { EmptyState } from "@/components/empty-state";
import { DeleteGoalModal } from "@/pages/dashboard/goals/delete-goal-modal";
import { ModifyGoalModal } from "@/pages/dashboard/goals/modify-goal-modal";
import { SingleGoal } from "@/pages/dashboard/goals/single-goal";
import { GoalType } from "@/types";

interface ProfileGoalProps extends FlexProps {
  goals: Array<GoalType>;
  type?: "own" | "mentor" | "mentee";
}

export function ProfileGoal({
  goals,
  type = "own",
  ...rest
}: ProfileGoalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef<ElementRef<"input">>(null);

  const [mode, setMode] = useState<"create" | "edit" | "delete">();
  const [selected, setSelected] = useState<GoalType | undefined>();

  const handleEditGoal = (selectedGoal: GoalType) => {
    setSelected(selectedGoal);
    setMode("edit");
    onOpen();
  };

  const handleDeleteGoal = (selectedGoal: GoalType) => {
    setSelected(selectedGoal);
    setMode("delete");
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    setMode("create");
    onClose();
  };

  const isOwnProfile = type === "own";

  return (
    <Flex
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Goal
        </Text>
        <Text fontSize="14px" color="gray.600">
          {isOwnProfile
            ? "Set some SMART goals. Your goals will be only visible to the mentors you apply for."
            : `See the ${type}’s goals.`}
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        {isOwnProfile && (
          <Flex alignItems="center" justify="end">
            {/* <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Level / Field
          </FormLabel> */}
            <IconButton
              aria-label="1"
              icon={<Add size={20} />}
              colorScheme="brand"
              borderRadius="full"
              onClick={onOpen}
            />
          </Flex>
        )}
        <Flex direction="column" gap={6}>
          {goals?.length ? (
            goals.map((goal, index) => (
              <SingleGoal
                key={goal.id}
                index={index + 1}
                goal={goal}
                onEdit={isOwnProfile ? handleEditGoal : undefined}
                onDelete={isOwnProfile ? handleDeleteGoal : undefined}
                border="none"
                p={0}
              />
            ))
          ) : (
            <EmptyState
              name="goal"
              emptyText="They didn't set any goals yet"
              onClick={onOpen}
            />
          )}
        </Flex>
      </Flex>
      {mode === "delete" && selected ? (
        <DeleteGoalModal
          key={+isOpen}
          initialRef={initialRef}
          selectedGoal={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      ) : (
        <ModifyGoalModal
          key={+isOpen}
          initialRef={initialRef}
          selectedGoal={selected}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}
    </Flex>
  );
}
