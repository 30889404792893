import { useLocation, useNavigate } from "react-router";

// Function to convert filters object to query string using URLSearchParams
export function filtersToQueryString(filters: Record<string, unknown>) {
  const params = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      params.append(key, String(filters[key]));
    }
  });

  const result = params.toString();

  return result ? `?${result}` : "";
}

export const useSyncFormWithQueryParams = <
  T extends Record<string, unknown>,
>(): [T, (arg: T) => void] => {
  const location = useLocation(); // Get current location (including query params)
  const navigate = useNavigate(); // Function to navigate (replace URL)

  // Function to sync form data with the URL's query params
  const syncFormWithQueryParams = (values: T) => {
    const queryString = filtersToQueryString(values);

    // Replace the URL with the new query string (without reloading the page)
    navigate({
      pathname: location.pathname,
      search: queryString,
    });
  };

  // Extract query params from location (since we're using react-router)
  const queryParams = new URLSearchParams(location.search);
  const queryObject: Record<string, unknown> = {};

  queryParams.forEach((value, key) => {
    queryObject[key] = value;
  });

  return [queryObject as T, syncFormWithQueryParams];
};
