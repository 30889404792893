import { MutableRefObject, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { isAxiosError } from "axios";

import { EmptyState, LoadingState } from "@/components";
import { DASHBOARD_ROUTES } from "@/constants/routes";
import { SingleService } from "@/pages/dashboard/services/single-service";
import { failedToast, SharedAPI, successToast } from "@/services";
import { captureNetworkError } from "@/services/error-handler";
import { MentorType, ServiceType } from "@/types";
import { getFullName } from "@/utils/get-user-name";

interface CreateApplicationModalProps extends Omit<ModalProps, "children"> {
  initialRef: MutableRefObject<HTMLTextAreaElement | null>;
  finalRef?: MutableRefObject<HTMLButtonElement | null>;
  selectedMentor?: MentorType;
  selectedService?: ServiceType;
  isOpen: boolean;
  showServices?: boolean;
  onClose(): void;
}

export function CreateApplicationModal({
  initialRef,
  finalRef,
  selectedMentor,
  selectedService,
  isOpen,
  onClose,
  showServices = true,
  ...props
}: CreateApplicationModalProps) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SharedAPI.ApplyToApplicationPayload>({
    defaultValues: {
      mentor_id: selectedService?.mentor?.id || selectedMentor?.id,
      request_note: null,
    },
  });

  const [selected, setSelected] = useState<ServiceType>(
    selectedService || (selectedMentor?.services?.[0] as ServiceType),
  );

  const fullname = selectedMentor
    ? getFullName(selectedMentor)
    : `${selectedService?.mentor?.first_name} ${selectedService?.mentor?.last_name}`;

  const handleSubmitApplication = handleSubmit(
    async ({ mentor_id, request_note }) => {
      try {
        const res = await SharedAPI.applyToApplication({
          mentor_id,
          service_id: selected.id,
          request_note: request_note || null,
        });
        if (res.status === 200) {
          successToast({
            title: `The application sent successfully!`,
          });
          onClose();
          navigate(DASHBOARD_ROUTES.APPLICATIONS);
        }
      } catch (error) {
        if (isAxiosError(error) && error.response?.data?.message) {
          failedToast({
            title: error.response.data.message,
          });
        } else {
          console.error({ error });
        }

        captureNetworkError(error);
      }
    },
  );

  const { ref: resMsgInputRef, ...resMsgInputRegister } =
    register("request_note");

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      // isCentered
      size={{ base: "full", sm: "2xl" }}
      {...props}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmitApplication} noValidate>
        <ModalHeader>
          Apply to{" "}
          <Text
            as="span"
            p="2px"
            borderBottom="3px solid"
            borderBottomColor="brand.500"
          >
            {fullname}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" gap={4} pb={6}>
          {showServices &&
            (selectedMentor && !selectedService ? (
              <Flex direction="column" gap={6}>
                {selectedMentor.services.length ? (
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    textAlign="center"
                    justify="flex-start"
                    spacing={{ base: 4, lg: 10 }}
                    overflowX="auto"
                  >
                    {selectedMentor.services.map((service, index) => (
                      <SingleService
                        key={service.id}
                        index={index + 1}
                        service={service as ServiceType}
                        cursor="pointer"
                        onClick={() => setSelected(service)}
                        borderColor={
                          service.id === selected?.id ? "brand.500" : "inherit"
                        }
                        bg={service.id === selected?.id ? "gray.50" : "inherit"}
                      />
                    ))}
                  </Stack>
                ) : (
                  <EmptyState
                    name="services"
                    emptyText="They don't added any services yet."
                  />
                )}
              </Flex>
            ) : selectedService ? (
              <Flex direction="column" gap={6}>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  textAlign="center"
                  justify="flex-start"
                  spacing={{ base: 4, lg: 10 }}
                  overflowX="auto"
                >
                  <SingleService
                    key={selectedService.id}
                    index={1}
                    service={selectedService}
                    cursor="pointer"
                    onClick={() => setSelected(selectedService)}
                    borderColor="brand.500"
                    bg="gray.50"
                  />
                </Stack>
              </Flex>
            ) : (
              <LoadingState />
            ))}
          {selected.need_approval && (
            <FormControl
              id="request_note"
              isInvalid={Boolean(errors.request_note)}
            >
              <FormLabel htmlFor="request_note" fontWeight="normal">
                Request Note
              </FormLabel>
              <Textarea
                isDisabled={isSubmitting}
                {...resMsgInputRegister}
                ref={(e) => {
                  resMsgInputRef(e);

                  // it need to support focus on first input
                  if (e) {
                    initialRef.current = e;
                  }
                }}
              />
              {errors.request_note && (
                <FormErrorMessage>
                  {errors.request_note.message}
                </FormErrorMessage>
              )}
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter gap={4}>
          <Button w="full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            w="full"
            bg={useColorModeValue("#151f21", "gray.700")}
            color="white"
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
