import { Link } from "react-router";
import {
  Button,
  Divider,
  Flex,
  FlexProps,
  useColorModeValue,
} from "@chakra-ui/react";

import { AUTH_ROUTES } from "@/constants/routes";

import { NAV_ITEMS } from "../config";

import { MobileCollapsableNavItem } from "./mobile-collapsable-nav-item";

interface MobileCollapsableNavProps extends FlexProps {}

export function MobileCollapsableNav({ ...rest }: MobileCollapsableNavProps) {
  return (
    <Flex
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ lg: "none" }}
      zIndex={20}
      {...rest}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileCollapsableNavItem key={navItem.label} {...navItem} />
      ))}
      <Divider my={4} />
      <Flex justifyContent="space-between" alignItems="center" gap={2}>
        <Button
          as={Link}
          to={AUTH_ROUTES.LOGIN}
          variant="outline"
          w="full"
          fontSize="sm"
          fontWeight={400}
          display="flex"
        >
          Log in
        </Button>
        <Button
          as={Link}
          to={AUTH_ROUTES.REGISTER}
          w="full"
          fontSize="sm"
          fontWeight={600}
          colorScheme="brand"
          display="flex"
        >
          Sign up
        </Button>
      </Flex>
    </Flex>
  );
}
