import { defineStyleConfig, defineStyle } from "@chakra-ui/react";

const bannerVariant = defineStyle(() => {
  return {
    paddingInline: {
      base: "0",
      lg: "4",
    },
    maxW: {
      base: "100%",
      lg: "992px",
      xl: "1280px",
      "2xl": "1336px",
      "3xl": "1440px",
      "4xl": "1596px",
    },
  };
});

const containerTheme = defineStyleConfig({
  baseStyle: {
    paddingInline: {
      base: "16",
      lg: "4",
    },
    maxW: {
      base: "100%",
      lg: "992px",
      xl: "1020px",
    },
  },
  variants: {
    banner: bannerVariant,
  },
});

export { containerTheme };
