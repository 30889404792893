import { Link } from "react-router";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Image,
  Text,
} from "@chakra-ui/react";

import { AUTH_ROUTES, PUBLIC_ROUTES } from "@/constants/routes";

import { AccordionHome } from "./components";

export function HomePage() {
  return (
    <>
      <Container
        as="section"
        w="full"
        mx={0}
        pt={{ base: 6, lg: "42px" }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Text
          color="#1B1C20"
          textAlign="center"
          lineHeight={{ base: "42px", lg: "72px" }}
          fontWeight="600"
          fontSize={{ base: "36px", lg: "60px" }}
        >
          Data Science Mentorship, Simplified
        </Text>
        <Text
          color="#383A47"
          fontSize={{ base: "18px", lg: "20px" }}
          lineHeight={{ base: "30px", lg: "30px" }}
          mt={{ base: 4, lg: 6 }}
          textAlign="center"
        >
          Accelerate your career in Data Science with top mentors in
          tech-industry across the U.S. and Canada. Discover insights, expand
          your skills, and unlock new opportunities.
        </Text>
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={3}
          justifyContent="center"
          w="full"
          mt={{ base: 8, lg: 10 }}
        >
          <Button
            as={Link}
            to={AUTH_ROUTES.REGISTER}
            w={{ base: "full", lg: "209px" }}
            h={{ base: "52px", lg: "60px" }}
            variant="outline"
            colorScheme="black"
            borderColor="#D5D7DA"
            color="#414651"
          >
            Become a mentor
          </Button>
          <Button
            as={Link}
            to={PUBLIC_ROUTES.ALL_MENTORS}
            w={{ base: "full", lg: "209px" }}
            h={{ base: "52px", lg: "60px" }}
            colorScheme="brand"
          >
            Find a mentor {"-->"}
          </Button>
        </Flex>
      </Container>
      <Container
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        alignItems="center"
      >
        <Image
          display={{ base: "none", lg: "block" }}
          w="full"
          src="/assets/images/home/home4.jpg"
          objectFit="scale-down"
          py="50px"
        />
        <Image
          src="/assets/images/home/home3.jpg"
          display={{ base: "block", lg: "none" }}
          py={6}
          w="full"
          objectFit="scale-down"
        />
      </Container>
      <Container
        as="section"
        id="latest-mentors"
        w="full"
        py={{ base: 6, lg: 10 }}
        gap={8}
        display="flex"
        flexDir="column"
        alignItems="center"
      >
        <Text
          w="full"
          textAlign="center"
          fontWeight="500"
          lineHeight="24px"
          fontSize="16px"
          color="#5F5D5A"
        >
          Our Mentors are from small startups to the world’s largest companies
        </Text>
        <Flex
          w="full"
          gap={7}
          justifyContent="space-between"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="center"
        >
          <Image h={8} src="/assets/images/brands/1.png" />
          <Image h={8} src="/assets/images/brands/2.png" />
          <Image h={8} src="/assets/images/brands/3.png" />
          <Image h={8} src="/assets/images/brands/4.png" />
          <Image h={8} src="/assets/images/brands/5.png" />
          <Image h={8} src="/assets/images/brands/6.png" />
        </Flex>
        <Divider my={{ base: "24px", lg: "42px" }} />
      </Container>
      <Container
        as="section"
        w="full"
        display="flex"
        flexDir="column"
        alignItems="center"
      >
        <Flex flexDirection="column" alignItems="center" w="full">
          <Text
            color="#619D1F"
            fontSize={{ base: "14px", lg: "16px" }}
            lineHeight={{ base: "20px", lg: "24px" }}
            fontWeight="600"
          >
            Features
          </Text>
          <Text
            color="#181D27"
            fontSize={{ base: "30px", lg: "36px" }}
            lineHeight={{ base: "44px", lg: "44px" }}
            fontWeight="600"
            mt={3}
          >
            Find the best mentors quickly
          </Text>
          <Text
            textAlign="center"
            color="#5F5D5A"
            mt={{ base: 4, lg: 5 }}
            fontSize={{ base: "18px", lg: "20px" }}
            lineHeight={{ base: "28px", lg: "30px" }}
          >
            Discover mentors from 500 fortune companies, gain personalized
            guidance, and take your career to the next level. Sign up for free
            and start your journey today.
          </Text>
        </Flex>

        <Grid
          mt="60px"
          templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
          rowGap={4}
          columnGap={8}
          maxW="container.xl"
          w="full"
        >
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", lg: "start" }}
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home8.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Qualified Mentors
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
            >
              Our mentors are seasoned professionals from top 500 Fortune
              companies who’ve been there, done that.
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", lg: "start" }}
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home9.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Secure Payment
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
            >
              Enjoy a safe and hassle-free payment experience with our trusted
              system.
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", lg: "start" }}
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home10.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Instant Booking
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
            >
              Book your mentorship sessions instantly and start making progress
              right away.
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", lg: "start" }}
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home11.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Seamless Application Process
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
            >
              Explore mentors for free, send applications, and receive responses
              within seven days.
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", lg: "start" }}
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home12.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Privacy Protected
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
            >
              Your data is secure with top-level encryption and strict privacy
              measures.
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems={{ base: "center", lg: "start" }}
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home13.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Satisfaction Guaranteed
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
            >
              Request a full refund if you’re not satisfied with the mentorship
              experience.
            </Text>
          </Flex>
        </Grid>
      </Container>

      <Container
        as="section"
        display="flex"
        variant="banner"
        flexDirection="column"
        alignItems="center"
        w="full"
        py={{ base: 6, lg: 8 }}
        px={{ base: 18, lg: 28 }}
        mt={{ base: 0, lg: 16 }}
        mb={{ base: 0, lg: 16 }}
      >
        <Flex
          w="full"
          gap={7}
          justifyContent="space-between"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="center"
        >
          <Image src="/assets/images/home/testimonials/1.png" />
        </Flex>
      </Container>
      <Container
        as="section"
        display="flex"
        variant="banner"
        flexDirection="column"
        alignItems="center"
        w="full"
        py={{ base: 6, lg: 8 }}
        px={{ base: 18, lg: 28 }}
        mt={{ base: 0, lg: 16 }}
        mb={{ base: 0, lg: 16 }}
      >
        <Text
          color="#619D1F"
          py={1}
          w="fit-content"
          px={{ base: 2.5, lg: 3 }}
          borderRadius="16px"
          bg="#F2F8EB"
          fontSize={{ base: "14px" }}
          lineHeight={{ base: "20px" }}
          fontWeight="500"
        >
          Features
        </Text>
        <Text
          fontSize={{ base: "30px", lg: "36px" }}
          lineHeight={{ base: "38px", lg: "44px" }}
          fontWeight="600"
          color="#231509"
          textAlign="center"
          mt={4}
        >
          Pay securely and book instantly
        </Text>
        <Text
          mt={4}
          fontWeight="400"
          fontSize={{ base: "18px", lg: "20px" }}
          textAlign="center"
          lineHeight={{ base: "28px", lg: "30px" }}
          color="#535862"
        >
          Enjoy safe payments and instant booking for a seamless mentorship
          experience.
        </Text>
        <Image
          mt="42px"
          display={{ base: "none", lg: "block" }}
          src="/assets/images/home/home33.jpg"
        />
        <Image
          mt="24px"
          w="full"
          display={{ base: "block", lg: "none" }}
          src="/assets/images/home/home34.jpg"
        />
        <Grid
          mt="60px"
          templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
          rowGap={4}
          columnGap={8}
          maxW="container.xl"
          w="full"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home22.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Our Vission
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
              textAlign="center"
            >
              To be the leading mentorship platform for data science
              professionals, creating a space where knowledge and guidance flow
              freely between mentors and mentees. We envision a world where
              every aspiring data professional has access to the mentorship they
              need to succeed and excel in their careers.
            </Text>
            <Button
              as={Link}
              to={PUBLIC_ROUTES.ABOUT}
              variant="link"
              mt={5}
              fontSize="16px"
              lineHeight="24px"
              fontWeight="600"
              alignItems="center"
              display="flex"
              gap="2"
              color="#619D1F"
            >
              Learn more <ArrowForwardIcon color="#619D1F" boxSize={5} />
            </Button>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home21.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Our Mission
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
              textAlign="center"
            >
              To empower individuals in data careers by providing accessible,
              meaningful, and one-on-one mentorship opportunities. Our platform
              connects ambitious mentees with experienced mentors, fostering
              personal growth, professional development, and a strong,
              supportive community across the U.S. and Canada.
            </Text>
            <Button
              as={Link}
              to={PUBLIC_ROUTES.ABOUT}
              variant="link"
              mt={5}
              fontSize="16px"
              lineHeight="24px"
              fontWeight="600"
              alignItems="center"
              display="flex"
              gap="2"
              color="#619D1F"
            >
              Learn more <ArrowForwardIcon color="#619D1F" boxSize={5} />
            </Button>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            borderRadius="md"
            px={8}
            pb={4}
            pt={6}
          >
            <Image boxSize="48px" src="/assets/images/home/home20.svg" />
            <Text fontSize="1rem" fontWeight="bold" mt={2} mb={4}>
              Our Goal
            </Text>
            <Text
              lineHeight="160%"
              w="full"
              fontSize="0.875rem"
              fontWeight="light"
              textAlign="center"
            >
              To seamlessly connect mentees with mentors, offering secure, fast,
              and reliable booking and payment options. Our platform is designed
              to make finding, booking, and benefiting from personalized
              mentorship as simple and impactful as possible for all data
              professionals.
            </Text>
            <Button
              as={Link}
              to={PUBLIC_ROUTES.ABOUT}
              variant="link"
              mt={5}
              fontSize="16px"
              lineHeight="24px"
              fontWeight="600"
              alignItems="center"
              display="flex"
              gap="2"
              color="#619D1F"
            >
              Learn more <ArrowForwardIcon color="#619D1F" boxSize={5} />
            </Button>
          </Flex>
        </Grid>
        <Divider my="38px" />
      </Container>
      <AccordionHome />

      <Container
        as="section"
        variant="banner"
        w="full"
        py={{ base: 6, lg: 8 }}
        px={{ base: 18, lg: 28 }}
        mt={{ base: 0, lg: 16 }}
        mb={{ base: 0, lg: 16 }}
      >
        <Box
          w="full"
          px={{ base: 4, lg: 8 }}
          py={{ base: 6, lg: "46px" }}
          display="grid"
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gridTemplateAreas={{
            base: `"banner"
        "bannerImage"
        "bannerNumbers"`,
            lg: `"bannerImage banner"
        "bannerImage bannerNumbers"`,
          }}
        >
          <Flex gridArea="banner" flexDirection="column">
            <Text
              color="#619D1F"
              fontWeight="600"
              fontSize={{ base: "14px", lg: "16px" }}
              lineHeight={{ base: "20px", lg: "24px" }}
            >
              We’ve helped hundreds of recent graduates and professionals
            </Text>
            <Text
              fontWeight="600"
              fontSize={{ base: "30px", lg: "48px" }}
              lineHeight={{ base: "38px", lg: "60px" }}
              color="#231509"
            >
              Empowering Data Science Careers, One Connection at a Time
            </Text>
          </Flex>
          <Image
            gridArea="bannerImage"
            aspectRatio={1 / 1}
            objectFit="scale-down"
            src="/assets/images/about/about1.png"
            w={{ base: "full", lg: "560px" }}
            boxSize={{ lg: "560px" }}
          />
          <Box
            gridArea="bannerNumbers"
            display="grid"
            gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
            gap={4}
          >
            <Flex
              alignItems="flex-start"
              flex={{ lg: "1 50%" }}
              flexDirection="column"
            >
              <Text
                color="#98C045"
                fontWeight="600"
                fontSize="60px"
                lineHeight="72px"
              >
                600+
              </Text>
              <Text
                color="#181D27"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="500"
              >
                Scheduled 1-on-1 Meetings
              </Text>
            </Flex>
            <Flex
              alignItems="flex-start"
              flex={{ lg: "1 50%" }}
              flexDirection="column"
            >
              <Text
                color="#98C045"
                fontWeight="600"
                fontSize="60px"
                lineHeight="72px"
              >
                100+
              </Text>
              <Text
                color="#181D27"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="500"
              >
                Registered Mentees
              </Text>
            </Flex>
            <Flex
              alignItems="flex-start"
              flex={{ lg: "1 50%" }}
              flexDirection="column"
            >
              <Text
                color="#98C045"
                fontWeight="600"
                fontSize="60px"
                lineHeight="72px"
              >
                20k
              </Text>
              <Text
                color="#181D27"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="500"
              >
                Global Website Visitors
              </Text>
            </Flex>
            <Flex
              alignItems="flex-start"
              flex={{ lg: "1 50%" }}
              flexDirection="column"
            >
              <Text
                color="#98C045"
                fontWeight="600"
                fontSize="60px"
                lineHeight="72px"
              >
                50+
              </Text>
              <Text
                color="#181D27"
                fontSize="18px"
                lineHeight="28px"
                fontWeight="500"
              >
                5-star reviews
              </Text>
            </Flex>
          </Box>
        </Box>
      </Container>
    </>
  );
}
