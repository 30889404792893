import { FaLinkedin } from "react-icons/fa";
import { useLoaderData, useParams } from "react-router";
import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link1 } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { ProfileEducation } from "@/components/user-profile/components/profile-education";
import { ProfileGoal } from "@/components/user-profile/components/profile-goal";
import { ProfileHeader } from "@/components/user-profile/components/profile-header";
import { ProfileJobExperience } from "@/components/user-profile/components/profile-job-experience";
import { ProfileSkills } from "@/components/user-profile/components/profile-skills";
import { MenteeType, MentorType, Prettify } from "@/types";

import { getSingleMenteeQuery } from "./loading";

export function MenteeProfilePage() {
  const { menteeId = "" } = useParams<{ menteeId: string }>();
  const initialData = useLoaderData<MenteeType>();

  const { data: profile = initialData, isFetching: profileFetching } = useQuery<
    Prettify<MentorType & MenteeType>
  >(getSingleMenteeQuery(menteeId));

  const fullname = `${profile?.first_name} ${profile?.last_name}`;
  const haveLinkedIn = profile?.external_link?.includes("linkedin.com");

  return (
    <Flex
      boxSize="full"
      px={{ base: 0, md: 8 }}
      py={{ base: 0, md: 10 }}
      direction="column"
      align="flex-start"
      justify="center"
      bg={useColorModeValue("white", "gray.900")}
      color={useColorModeValue("black", "gray.200")}
      mb={20} // Add margin bottom to avoid the footer
    >
      <Flex
        w="full"
        px={{ base: 8, md: 0 }}
        py={{ base: 4, md: 0 }}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        gap={6}
        display={{ base: "none", md: "block" }}
      >
        <Heading fontSize="3xl">Mentee&apos;s Profile</Heading>
      </Flex>

      <Flex
        boxSize="full"
        px={{ base: 4, md: 0 }}
        py={{ base: 3, md: 6 }}
        direction="column"
        gap={6}
      >
        {profileFetching ? (
          <LoadingState />
        ) : profile ? (
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={{ base: 5, md: 10 }}
          >
            <ProfileHeader
              slug={profile.id}
              avatar={profile.avatar}
              fullname={fullname}
              email={profile.email}
            >
              <Button
                as="a"
                href={profile.external_link || ""}
                target="_blank"
                rel="noopener noreferrer"
                isDisabled={!profile.external_link}
                leftIcon={
                  haveLinkedIn ? (
                    <Icon as={FaLinkedin} boxSize={5} color="#0077B5" />
                  ) : (
                    <Icon as={Link1} boxSize={5} />
                  )
                }
                colorScheme="gray"
                bgColor="white"
                border="2px solid"
                borderColor="gray.200"
                fontSize="14px"
                fontWeight="bold"
              >
                View {haveLinkedIn ? "LinkedIn" : "Website"}
              </Button>
              <Button
                as="a"
                href={`mailto:${profile.email}?subject=Hello ${fullname}&body=Hi ${fullname}, I would like to...`}
                target="_blank"
                rel="noopener noreferrer"
                colorScheme="brand"
                fontSize="14px"
                fontWeight="bold"
              >
                Send Email
              </Button>
            </ProfileHeader>

            {profile.background && (
              <>
                {/* Education Section */}
                <ProfileEducation
                  background={profile.background}
                  id="education-info"
                  type="mentee"
                />
                <Divider />

                {/* Job Experience Section */}
                <ProfileJobExperience
                  background={profile.background}
                  id="job-experience-info"
                  type="mentee"
                />
                <Divider />

                {/* Skills and Tools Section */}
                <ProfileSkills
                  background={profile.background}
                  id="skills-info"
                  type="mentee"
                />
                <Divider />
              </>
            )}

            {profile.goals && (
              <>
                {/* Goal Section */}
                <ProfileGoal
                  goals={profile.goals}
                  id="goals-info"
                  type="mentee"
                />
                <Divider />
              </>
            )}
          </Flex>
        ) : (
          <EmptyState name="notification" />
        )}
      </Flex>
    </Flex>
  );
}
