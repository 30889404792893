import { Link, useLocation, useNavigate } from "react-router";
import { Button, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { ArrowLeft } from "iconsax-react";

import { DESKTOP_FOOTER_HEIGHT, HEADER_HEIGHT } from "@/constants/config";
import {
  DASHBOARD_PREFIX,
  DASHBOARD_ROUTES,
  PUBLIC_ROUTES,
} from "@/constants/routes";

export function Page404() {
  const navigate = useNavigate();
  const location = useLocation();

  const isDashboardRoute = location.pathname.startsWith(DASHBOARD_PREFIX);

  return (
    <Flex
      boxSize="full"
      minH={{
        base: `full`,
        md: isDashboardRoute
          ? "100vh"
          : `calc(100vh - ${(HEADER_HEIGHT + DESKTOP_FOOTER_HEIGHT) * 4}px)`,
      }}
      px={{ base: 0, md: 20 }}
      py={{ base: 0, md: 10 }}
      direction={{ base: "column", md: "row-reverse" }}
      gap={{ base: 0, md: 8 }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Image
        flex={1}
        src="/assets/images/404.jpg"
        alt="404 image"
        w="full"
        maxH={{ base: "200px", sm: "400px", md: "720px" }}
        px={{ base: 4, md: 8 }}
        objectFit="cover"
        objectPosition="bottom"
        overflow="hidden"
      />
      <Flex
        flex={1}
        w="full"
        px={{ base: 4, md: 8 }}
        py={{ base: 6, md: 0 }}
        pt={{ base: 0, md: 6 }}
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={{ base: 8, md: 12 }}
      >
        <Flex
          w="full"
          direction="column"
          justifyContent="center"
          alignItems="start"
          gap={3}
        >
          <Text fontSize="16px" fontWeight="bold" color="#619D1F">
            404 error
          </Text>
          <Text
            fontSize={{ base: "36px", md: "50px" }}
            fontWeight="bold"
            color="gray.900"
          >
            Page not found
          </Text>
          <Text
            fontSize={{ base: "18px", md: "20px" }}
            fontWeight="normal"
            color="gray.600"
            mt={3}
          >
            Sorry, the page you are looking for doesn&apos;t exist. Here are
            some helpful links:
          </Text>
        </Flex>
        <Flex
          w="full"
          direction={{ base: "column", md: "row-reverse" }}
          justifyContent="center"
          alignItems="center"
          gap={3}
        >
          <Button
            w="full"
            h={12}
            as={Link}
            to={isDashboardRoute ? DASHBOARD_ROUTES.HOME : PUBLIC_ROUTES.HOME}
            colorScheme="brand"
          >
            Take me home
          </Button>
          <Button
            w="full"
            h={12}
            variant="outline"
            onClick={() => navigate(-1)}
            leftIcon={<Icon as={ArrowLeft} size={5} />}
          >
            Go back
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
