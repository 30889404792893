import { ElementRef, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  useColorModeValue,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { SearchNormal } from "iconsax-react";

import { EmptyState, LoadingState } from "@/components";
import { MentorshipCard } from "@/components/mentorship-card";
import { failedToast, SharedAPI, useAuthStore } from "@/services";
import {
  MentorshipActionStatusEnum,
  MentorshipActionStatusTypes,
  MentorshipType,
} from "@/types";
import { filterByName } from "@/utils/filter-by-name";
import { useSyncFormWithQueryParams } from "@/utils/sync-params";

import { AuthorDetails } from "./author-details";
import { CancelMentorshipModal } from "./cancel-mentorship-modal";
import { FinishMentorshipModal } from "./finish-mentorship-modal";
import { getMentorshipsQuery } from "./loading";
import { NextMentorshipModal } from "./next-mentorship-modal";
import { QuitMentorshipModal } from "./quit-mentorship-modal";

export function MentorshipPage() {
  const [queryParams, setQueryParams] =
    useSyncFormWithQueryParams<Partial<SharedAPI.GetMentorshipsFilters>>();

  const { data: initialData, isFetching } =
    useQuery<SharedAPI.GetMentorshipsResponse>({
      ...(getMentorshipsQuery({
        archived: queryParams.archived || false,
      }) as UseQueryOptions<SharedAPI.GetMentorshipsResponse>),
      refetchOnWindowFocus: true,
    });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef<ElementRef<"input">>(null);

  const [mode, setMode] = useState<
    MentorshipActionStatusTypes | "details" | undefined
  >();
  const [selected, setSelected] = useState<MentorshipType | undefined>();

  const role = useAuthStore((state) => state.user?.role);
  const isMentee = role === "mentee";

  const { register, watch } = useForm<{ query: string }>({
    defaultValues: { query: "" },
  });

  const filteredMentorships = initialData?.filter(
    (mentorship) =>
      filterByName(
        isMentee
          ? mentorship.mentor?.first_name || ""
          : mentorship.mentee?.first_name || "",
        watch("query"),
      ) ||
      filterByName(
        isMentee
          ? mentorship.mentor?.last_name || ""
          : mentorship.mentee?.last_name || "",
        watch("query"),
      ),
  );

  const handleShowDetails = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode("details");
    onOpen();
  };

  const handleFinishMentorship = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode(MentorshipActionStatusEnum.COMPLETED);
    onOpen();
  };

  const handleNextMentorship = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode(MentorshipActionStatusEnum.NEXT);
    onOpen();
  };

  const handleQuitMentorship = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode(MentorshipActionStatusEnum.QUITTED);
    onOpen();
  };

  const handleCancelMentorship = (selectedMentorship: MentorshipType) => {
    setSelected(selectedMentorship);
    setMode(MentorshipActionStatusEnum.CANCELED);
    onOpen();
  };

  const handleCloseModal = () => {
    setSelected(undefined);
    setMode(undefined);
    onClose();
  };

  const queryClient = useQueryClient();
  const { mutateAsync, isPending: isSubmitting } = useMutation({
    mutationKey: [`book-mentorship`, selected?.id],
    mutationFn: (
      mentorship_id: Parameters<typeof SharedAPI.bookMentorship>[0],
    ) => SharedAPI.bookMentorship(mentorship_id),
    onSuccess: async (res) => {
      if (res?.data?.booking_url) {
        window.open(res.data.booking_url, "_blank")?.focus();
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["mentorships"],
        });
      }
    },
    onError: (err) => {
      if (isAxiosError(err) && err.response) {
        failedToast({
          title: err.response.data.message,
        });
      } else {
        failedToast({
          title: err.message,
        });
      }
      queryClient.invalidateQueries({
        queryKey: ["mentorships"],
      });
    },
  });

  const handleBookMentorship = async (selectedMentorship: MentorshipType) => {
    try {
      setSelected(selectedMentorship);
      await mutateAsync(selectedMentorship.id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Flex
      boxSize="full"
      px={{ base: 0, md: 8 }}
      py={{ base: 0, md: 10 }}
      direction="column"
      align="flex-start"
      justify="center"
      bg={useColorModeValue("white", "gray.900")}
      color={useColorModeValue("black", "gray.200")}
    >
      <Flex
        w="full"
        px={{ base: 2, md: 0 }}
        py={{ base: 4, md: 0 }}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        gap={6}
      >
        <Heading fontSize="3xl" display={{ base: "none", md: "block" }}>
          Mentorships
        </Heading>
        <Stack w={{ base: "full", md: "auto" }} direction="row">
          <InputGroup w="full" gap={5}>
            <Input
              ps={10}
              placeholder="Search"
              borderRadius="lg"
              borderColor="gray.400"
              color="gray.500"
              _placeholder={{
                color: "gray.500",
              }}
              _focus={{
                bg: "whiteAlpha.300",
              }}
              {...register("query")}
            />
            <InputLeftElement>
              <Icon as={SearchNormal} boxSize={5} color="gray.500" />
            </InputLeftElement>
          </InputGroup>
        </Stack>
      </Flex>
      <Tabs
        defaultIndex={queryParams.archived ? 1 : 0}
        onChange={(index) => setQueryParams({ archived: Boolean(index) })}
        boxSize="full"
        px={{ base: 2, md: 0 }}
        py={{ base: 3, md: 6 }}
      >
        <TabList fontWeight="bold" color="#717680" gap={5}>
          <Tab _selected={{ color: "brand.500" }} px={2}>
            New
          </Tab>
          <Tab _selected={{ color: "brand.500" }} px={2}>
            Archived
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="brand.500"
          borderRadius="1px"
        />
        <TabPanels boxSize="full">
          <Flex direction="column" gap={6}>
            {isFetching ? (
              <LoadingState />
            ) : filteredMentorships?.length && role ? (
              <Wrap
                shouldWrapChildren
                direction={{ base: "column", md: "row" }}
                spacing={6}
                py={10}
              >
                {filteredMentorships.map((mentorship) => (
                  <MentorshipCard
                    key={mentorship.id}
                    w="full"
                    role={role}
                    mentorship={mentorship}
                    onDetail={handleShowDetails}
                    onQuit={handleQuitMentorship}
                    onCancel={handleCancelMentorship}
                    onNext={handleNextMentorship}
                    onFinish={handleFinishMentorship}
                    onBook={handleBookMentorship}
                    bookingLoading={
                      mentorship.id === selected?.id && isSubmitting
                    }
                  />
                ))}
              </Wrap>
            ) : (
              <EmptyState name="active mentorship" />
            )}
          </Flex>
        </TabPanels>
      </Tabs>
      {selected &&
        (mode === "details" ? (
          <AuthorDetails
            isMentee={isMentee}
            mentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === MentorshipActionStatusEnum.COMPLETED ? (
          <FinishMentorshipModal
            key={+isOpen}
            initialRef={initialRef}
            selectedMentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === MentorshipActionStatusEnum.NEXT ? (
          <NextMentorshipModal
            key={+isOpen}
            initialRef={initialRef}
            selectedMentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === MentorshipActionStatusEnum.QUITTED ? (
          <QuitMentorshipModal
            key={+isOpen}
            initialRef={initialRef}
            selectedMentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : mode === MentorshipActionStatusEnum.CANCELED ? (
          <CancelMentorshipModal
            key={+isOpen}
            initialRef={initialRef}
            selectedMentorship={selected}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        ) : (
          <></>
        ))}
    </Flex>
  );
}
