import { Button, Flex, FlexProps, Text } from "@chakra-ui/react";

interface ProfileDeleteProps extends FlexProps {}

export function ProfileDelete({ ...rest }: ProfileDeleteProps) {
  return (
    <Flex
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Delete account
        </Text>
        <Text fontSize="14px" color="red.600">
          Danger zone! This action is irreversible.
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        <Flex justifyContent="end" gap={4}>
          <Button isDisabled colorScheme="red">
            Delete Account
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
