import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { failedToast, UserAPI } from "@/services";

type FormType = {
  verified_stripe: boolean;
};
interface ProfilePaymentProps extends FlexProps {
  verifiedStripe?: boolean;
}

export function ProfilePayment({
  verifiedStripe = false,
  ...rest
}: ProfilePaymentProps) {
  const queryClient = useQueryClient();

  const { mutateAsync: connectToStripe, isPending: isSubmitting } = useMutation(
    {
      mutationKey: [`connect-stripe`],
      mutationFn: () => UserAPI.connectToStripe(),
      onSuccess: async (res) => {
        if (res?.data?.url) {
          window.open(res.data.url, "_blank");
        } else if (res?.data?.success) {
          await queryClient.invalidateQueries({
            queryKey: ["profile"],
          });
        }
      },
      onError: () => {
        failedToast({
          title: "Account connection failed",
        });
      },
    },
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormType>({
    defaultValues: {
      verified_stripe: verifiedStripe,
    },
  });

  const handleSubmitEdit = handleSubmit(async (values) =>
    values.verified_stripe
      ? await connectToStripe()
      : await (() => {
          // TODO: implement disconnect stripe
          console.log("[DEBUG]: Disconnect stripe");
        })(),
  );

  const isEditMode = true;

  return (
    <Flex
      {...(isEditMode
        ? {
            as: "form",
            onSubmit: handleSubmitEdit,
          }
        : {})}
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Payment
        </Text>
        <Text fontSize="14px" color="gray.600">
          Connect your account to Stripe such that your mentees can pay you
          securely
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        <FormControl
          id="verified_stripe"
          isInvalid={Boolean(errors.verified_stripe)}
          display="flex"
          alignItems="start"
          gap={2}
        >
          <Switch
            id="verified_stripe"
            isDisabled={isSubmitting}
            colorScheme="brand"
            {...register("verified_stripe")}
          />
          <Flex direction="column" gap={1}>
            <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
              <Text fontSize="14px" fontWeight="medium" color="gray.700">
                {verifiedStripe ? "Connected" : "Connect"} to Stripe
              </Text>
              <Text fontSize="14px" fontWeight="normal" color="gray.600">
                Connect your account to Stripe for easy payments
              </Text>
            </FormLabel>
            {errors.verified_stripe && (
              <FormErrorMessage>
                {errors.verified_stripe.message}
              </FormErrorMessage>
            )}
          </Flex>
        </FormControl>
        {isDirty && (
          <Flex justifyContent="end" gap={4}>
            <Button variant="outline" colorScheme="red">
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting} colorScheme="brand">
              Save changes
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
