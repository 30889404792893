import { ElementRef, useRef } from "react";
import { Flex, FlexProps, Text, useDisclosure } from "@chakra-ui/react";

import { Education } from "@/pages/dashboard/background/education";
import { EditEducationModal } from "@/pages/dashboard/background/education/edit-education-modal";
import { BackgroundType } from "@/types";

interface ProfileEducationProps extends Omit<FlexProps, "background"> {
  background: BackgroundType;
  type?: "own" | "mentor" | "mentee";
}

export function ProfileEducation({
  background,
  type = "own",
  ...rest
}: ProfileEducationProps) {
  // const educationsList = [
  //   {
  //     level: background.education.highest_education_level,
  //     field: background.education.field_of_study,
  //     graduation: background.education.graduation_year,
  //     institute: background.education.graduation_institute,
  //   },
  // ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef<ElementRef<"input">>(null);

  const isOwnProfile = type === "own";

  return (
    <Flex
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          Education
        </Text>
        <Text fontSize="14px" color="gray.600">
          {isOwnProfile
            ? "Update your education history"
            : `See the ${type}’s education history`}
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        <Education
          background={background}
          onClick={isOwnProfile ? onOpen : undefined}
          showTitle={false}
          border="none"
          p={0}
        />
        {/* <Flex alignItems="center" justify="space-between">
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Level / Field
          </FormLabel>
          <IconButton
            aria-label="1"
            icon={<FaPlus />}
            colorScheme="brand"
            borderRadius="full"
          />
        </Flex>
        <Flex direction="column" gap={4}>
          {educationsList.length ? (
            educationsList.map((education) => (
              <>
                <Flex alignItems="flex-start" justify="space-between">
                  <Flex direction="column" align="start" gap={0.5}>
                    <Text fontSize="14px" fontWeight="medium" color="gray.900">
                      {education.level}
                    </Text>
                    <Text fontSize="14px" fontWeight="normal" color="gray.600">
                      {education.field}
                    </Text>
                  </Flex>
                  <IconButton
                    aria-label="1"
                    icon={<FaEllipsisV />}
                    colorScheme="gray"
                    bgColor="white"
                    color="gray.300"
                  />
                </Flex>
              </>
            ))
          ) : (
            <Text fontSize="14px" color="gray.500">
              No education history
            </Text>
          )}
        </Flex> */}
      </Flex>
      <EditEducationModal
        key={+isOpen}
        initialRef={initialRef}
        background={background}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
}
