import { useState } from "react";
import { Link } from "react-router";
import { StarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  Image,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Heart, Tag } from "iconsax-react";

import { countryMapping } from "@/constants/mappings";
import { DASHBOARD_ROUTES, PUBLIC_ROUTES } from "@/constants/routes";
import { MentorType } from "@/types";

interface MentorCardProps extends FlexProps {
  mentor?: MentorType;
  isOwn?: boolean;

  isAuthenticated?: boolean;
  onApply?: (selected: MentorType) => void;
}

export function MentorCard({
  mentor,
  isOwn,
  isAuthenticated = false,
  onApply,
  ...rest
}: MentorCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleToggleExpand = () => {
    if (isMobile) {
      setIsExpanded((prev) => !prev);
    }
  };

  const country = "us";
  const languages = ["us", "ir", "es"] as const;

  const showFullContent = isExpanded || !isMobile;
  const fullName = `${mentor?.first_name} ${mentor?.last_name}`;

  const mentorServicesCount = mentor?.services.length || 0;
  const mentorServicesLowestPrice =
    mentor?.services.reduce(
      (acc, service) => Math.min(acc, service.price),
      Infinity,
    ) || 0;

  return (
    <Flex
      minW={{ base: "300px", md: "375px" }}
      w="full"
      maxW={{ base: "full", md: "375px" }}
      p={{ base: 3, md: 5 }}
      bg={useColorModeValue("white", "gray.900")}
      rounded="lg"
      direction={showFullContent ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      gap={6}
      borderWidth="1px"
      borderRadius="lg"
      cursor={isMobile ? "pointer" : "default"}
      onClick={handleToggleExpand}
      transition="all 0.3s ease"
      {...rest}
    >
      <Box
        w={showFullContent ? "full" : "auto"}
        position="relative"
        borderRadius="lg"
        boxShadow="0px 6px 20px -4px rgba(55, 74, 89, 0.36)"
        transition="all 0.3s ease"
      >
        <Image
          w={showFullContent ? "full" : "98px"}
          h={showFullContent ? "232px" : "138px"}
          objectFit="cover"
          borderRadius="lg"
          src={mentor?.avatar}
          alt={fullName}
        />
        {/* Icons overlay */}
        {!isOwn && (
          <Flex
            w="full"
            px={2}
            position="absolute"
            top={2}
            zIndex={1}
            alignItems="start"
            justifyContent="space-between"
            gap={2}
            transition="all 0.3s ease"
          >
            <Image
              src={countryMapping[country].flag}
              boxSize={showFullContent ? 10 : 6}
              alt={countryMapping[country].name}
            />
            <Icon as={Heart} color="white" boxSize={showFullContent ? 6 : 4} />
          </Flex>
        )}
        {!isOwn && (
          <Flex
            w="full"
            px={2}
            position="absolute"
            bottom={2}
            zIndex={1}
            alignItems="end"
            justifyContent="space-between"
            gap={2}
            transition="all 0.3s ease"
          >
            <Icon
              as={Tag}
              color="brand.400"
              variant="Bold"
              boxSize={showFullContent ? 6 : 4}
            />
            <Flex direction="row-reverse" gap={1}>
              {languages.map((lang) => (
                <Image
                  key={lang}
                  src={countryMapping[lang].flag}
                  boxSize={showFullContent ? 6 : 4}
                  alt="United States"
                />
              ))}
            </Flex>
          </Flex>
        )}
      </Box>
      <Flex
        flex={1}
        w="full"
        direction="column"
        justifyContent="space-between"
        gap={showFullContent ? 4 : 1}
        transition="all 0.3s ease"
      >
        <Flex w="full" direction="column" order={showFullContent ? 1 : 0}>
          <Text
            fontSize={["14px", "20px", "24px"]}
            fontWeight="bold"
            noOfLines={1}
            transition="all 0.3s ease"
          >
            {fullName}
          </Text>
          <Text
            fontSize={["10px", "14px", "16px"]}
            fontWeight="normal"
            color="#374A59"
          >
            {mentor?.tagline || "Mentor at O'Mentors"}
          </Text>
        </Flex>
        <Flex alignItems="center" gap={2} order={showFullContent ? 0 : 1}>
          <Flex>
            {Array.from({ length: 5 }).map((_, index) => (
              <Icon
                key={index}
                as={StarIcon}
                boxSize={[2, 3, 4]}
                color={index < 4 ? "brand.400" : "gray.200"}
              />
            ))}
          </Flex>
          <Text
            fontSize={["10px", "12px", "14px"]}
            fontWeight="normal"
            color="#6E798C"
          >
            {mentor?.rating || "N/A"} ({mentor?.number_of_reviews || "no"}{" "}
            reviews)
          </Text>
        </Flex>
        <Box
          display={showFullContent ? "block" : "none"}
          order={2}
          opacity={showFullContent ? 1 : 0}
          transition="opacity 0.3s ease"
          style={{
            visibility: showFullContent ? "visible" : "hidden",
            height: showFullContent ? "auto" : 0,
          }}
        >
          <Text
            fontSize={["12px", "14px", "16px"]}
            fontWeight="normal"
            color="#374A59"
            noOfLines={3}
          >
            {mentor?.bio}
          </Text>
        </Box>
        <Text
          fontSize={["10px", "14px", "18px"]}
          fontWeight="normal"
          color="#374A59"
          whiteSpace="nowrap"
          order={showFullContent ? 3 : 2}
        >
          <Text as="span" display={showFullContent ? "inline" : "none"}>
            {mentorServicesCount} Services
          </Text>{" "}
          from{" "}
          <Text as="span" fontSize={["16px", "18px", "24px"]} fontWeight="bold">
            {Intl.NumberFormat("en-US", {
              currency: "USD",
              style: "currency",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(mentorServicesLowestPrice)}
            /
          </Text>
          Session
        </Text>
        <Flex
          w="full"
          direction="row-reverse"
          justifyContent={showFullContent ? "start" : "end"}
          gap={showFullContent ? 2 : 0}
          order={showFullContent ? 2 : 3}
          transition="all 0.3s ease"
        >
          <Tooltip
            label="Verified Mentor"
            aria-label="Verified Mentor Tooltip"
            placement="top"
          >
            <Image
              src="/assets/images/icons/verified.svg"
              borderRadius="lg"
              boxSize={showFullContent ? "48px" : "32px"}
              bgColor={showFullContent ? "brand.50" : "transparent"}
              transition="all 0.3s ease"
            />
          </Tooltip>

          <Tooltip
            label="Top Mentor"
            aria-label="Top Mentor Tooltip"
            placement="top"
          >
            <Image
              src="/assets/images/icons/cup.svg"
              borderRadius="lg"
              boxSize={showFullContent ? "48px" : "32px"}
              bgColor={showFullContent ? "brand.50" : "transparent"}
              transition="all 0.3s ease"
            />
          </Tooltip>

          <Tooltip
            label={
              mentor?.is_experienced_mentor
                ? "Experienced Mentor"
                : "New Mentor"
            }
            aria-label={
              mentor?.is_experienced_mentor
                ? "Experienced Mentor Tooltip"
                : "New Mentor Tooltip"
            }
            placement="top"
          >
            <Image
              src={
                mentor?.is_experienced_mentor
                  ? "/assets/images/icons/gem.svg"
                  : "/assets/images/icons/stars.svg"
              }
              borderRadius="lg"
              boxSize={showFullContent ? "48px" : "32px"}
              bgColor={showFullContent ? "brand.50" : "transparent"}
              transition="all 0.3s ease"
            />
          </Tooltip>
        </Flex>
        {isOwn ? (
          <Button
            as={Link}
            to={DASHBOARD_ROUTES.SETTINGS}
            display={showFullContent ? "flex" : "none"}
            w="full"
            mt={4}
            colorScheme="brand"
            order={4}
          >
            Edit
          </Button>
        ) : (
          <Button
            as={Link}
            to={`${PUBLIC_ROUTES.MENTOR_PROFILE}/${mentor?.id}`}
            display={showFullContent ? "flex" : "none"}
            w="full"
            mt={4}
            colorScheme="brand"
            order={4}
          >
            Full Profile
          </Button>
          // <Button
          //   as={Link}
          //   to={`${PUBLIC_ROUTES.MENTOR_PROFILE}/${mentor?.slug}`}
          //   display={showFullContent ? "flex" : "none"}
          //   w="full"
          //   mt={4}
          //   colorScheme="brand"
          //   order={4}
          // >
          //   View Profile
          // </Button>
        )}
      </Flex>
    </Flex>
  );
}
