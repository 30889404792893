import { LoaderFunctionArgs } from "react-router";
import { QueryClient, QueryOptions } from "@tanstack/react-query";

import { protectedLoader } from "@/pages/protected/loading";
import { SharedAPI } from "@/services";
import { GetMentorshipsFilters } from "@/services/api/shared-api";
import { captureNetworkError } from "@/services/error-handler";

export const getMentorshipsQuery = (
  filters?: GetMentorshipsFilters,
  options?: QueryOptions,
) => ({
  queryKey: ["mentorships", filters],
  queryFn: () => SharedAPI.getMentorships(filters),
  ...options,
});

export const mentorshipsLoader =
  (queryClient: QueryClient) => async (args: LoaderFunctionArgs) => {
    try {
      await protectedLoader(args);

      const query = getMentorshipsQuery({
        archived: false,
      });

      return (
        queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query))
      );
    } catch (error) {
      captureNetworkError(error);
    }
  };
