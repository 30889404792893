import Axios, { AxiosError, AxiosResponse } from "axios";

import { config } from "@/constants";
import { AUTH_ROUTES } from "@/constants/routes";
import { queryClient } from "@/index";
import { router } from "@/router";

import { useAuthStore } from "../store";

export type ServerResponse<T = unknown> = T;
export type ErrorServerResponse = {
  message: string;
  success: false;
};

export type SuccessResponseType<T> = AxiosResponse<T>;
export type FailedResponseType<T> = AxiosError<Extract<T, { detail: string }>>;

export type {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
  ResponseType,
} from "axios";

function getCurrentAccessToken() {
  // this is how you access the zustand store outside of React.
  return useAuthStore.getState().accessToken;
}

const errorHandler = (error: FailedResponseType<ServerResponse>) => {
  switch (error.code) {
    case "ERR_BAD_REQUEST":
      if (error.response?.status === 401) {
        useAuthStore.getState().clearTokens();
        router.navigate(AUTH_ROUTES.LOGIN);
        queryClient.cancelQueries();
        queryClient.clear();
        return;
      } else if (error.response?.status === 403) {
        if (error.config?.url === "register") {
          const data = JSON.parse(error.config?.data);
          if (data?.role === "mentor" || data?.role === "mentee") {
            window.location.replace(
              `https://olabs.ck.page/closed_${data.role}s`,
            );
            queryClient.cancelQueries();
            queryClient.clear();
          }
          return;
        }
        throw error;
      } else {
        throw error;
      }
    default:
      throw error;
  }
};

const axios = Axios.create({
  baseURL: config.BACKEND_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use((config) => {
  const accessToken = getCurrentAccessToken();
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

axios.interceptors.response.use((res) => res, errorHandler);

export default axios;
