import {
  ApplicationStatusEnum,
  ApplicationStatusTypes,
  MentorshipStatusEnum,
  MentorshipStatusTypes,
  NotificationType,
  NotificationTypes,
} from "@/types";

import { DASHBOARD_ROUTES } from "./routes";

export const notificationTypeMapping = (
  notifier?: NotificationType["notifier"],
): Record<
  NotificationTypes,
  { label: string; link: string; icon: string }
> => ({
  incomplete_user: {
    label: "Please complete your profile!",
    link: DASHBOARD_ROUTES.SETTINGS,
    icon: "👤",
  },
  new_user: {
    label: "Welcome to O'Mentors!",
    link: DASHBOARD_ROUTES.HOME,
    icon: "🎉",
  },
  new_application: {
    label: notifier
      ? `${notifier} has applied to you!`
      : "You just received a new application!",
    link: DASHBOARD_ROUTES.APPLICATIONS,
    icon: "📋",
  },
  respond_application: {
    label: notifier
      ? `${notifier} has responded to you!`
      : "A mentor has responded to your application! ",
    link: DASHBOARD_ROUTES.APPLICATIONS,
    icon: "📋",
  },
  start_mentorship: {
    label: notifier
      ? `The ${notifier} mentorship has just started!`
      : "Your new mentorship has just started!",
    link: DASHBOARD_ROUTES.MENTORSHIPS,
    icon: "🎓",
  },
  update_mentorship: {
    label: notifier
      ? `The ${notifier} mentorship has updated!`
      : "Your mentorship status is updated!",
    link: DASHBOARD_ROUTES.MENTORSHIPS,
    icon: "🎓",
  },
});

export const countryMapping = {
  us: {
    name: "United States",
    code: "us",
    flag: "/assets/images/countries/united-states.svg",
  },
  ca: {
    name: "Canada",
    code: "ca",
    flag: "/assets/images/countries/canada.svg",
  },
  es: {
    name: "Spain",
    code: "es",
    flag: "/assets/images/countries/spain.svg",
  },
  ir: {
    name: "Iran",
    code: "ir",
    flag: "/assets/images/countries/iran.svg",
  },
  in: {
    name: "India",
    code: "in",
    flag: "/assets/images/countries/india.svg",
  },
} as const;

export const applicationStatusColorMapping: Record<
  ApplicationStatusTypes,
  string
> = {
  [ApplicationStatusEnum.PENDING]: "yellow",
  [ApplicationStatusEnum.ACCEPTED]: "blue",
  [ApplicationStatusEnum.DECLINED]: "red",
  [ApplicationStatusEnum.WITHDRAWN]: "orange",
  [ApplicationStatusEnum.DONE]: "green",
  [ApplicationStatusEnum.EXPIRED]: "gray",
};

export const mentorshipStatusColorMapping: Record<
  MentorshipStatusTypes,
  string
> = {
  [MentorshipStatusEnum.PAID]: "blue",
  [MentorshipStatusEnum.BOOKED]: "yellow",
  [MentorshipStatusEnum.COMPLETED]: "green",
  [MentorshipStatusEnum.QUITTED]: "red",
  [MentorshipStatusEnum.CANCELED]: "red",
  [MentorshipStatusEnum.EXPIRED]: "gray",
};
