import { useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ArrowDown2 } from "iconsax-react";

import { FormSelect } from "@/components/ui/form-select";
import { failedToast, successToast, useAuthStore, UserAPI } from "@/services";
import { getTimeZones } from "@/utils/get-timezones";

type FormType = {
  external_link: string;
  tagline: string;
  country: string;
};

interface ProfileDetailsInfoProps extends FlexProps {
  externalLink: string;
  tagline?: string;
  country: string;
  type?: "own" | "mentor" | "mentee";
}

export function ProfileDetailsInfo({
  externalLink,
  tagline,
  country,
  type = "own",
  ...rest
}: ProfileDetailsInfoProps) {
  const { user, setUser } = useAuthStore();
  const queryClient = useQueryClient();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormType>({
    defaultValues: {
      external_link: externalLink,
      tagline,
      country,
    },
  });

  const { mutateAsync: updateUser, isPending: isSubmitting } = useMutation({
    mutationKey: ["update-user"],
    mutationFn: async (data: FormType) => UserAPI.updateUser(data),
    onSuccess: async (data) => {
      successToast({
        title: "Profile updated successfully",
      });

      queryClient.setQueryData(["profile"], (prev) =>
        prev ? { ...prev, ...data } : data,
      );
      setUser({
        ...user,
        ...data,
        full_name: `${data.first_name} ${data.last_name}`,
      });
    },
    onError: () => {
      failedToast({
        title: "Profile update failed",
      });
    },
  });

  const handleCancel = () => {
    reset({
      external_link: externalLink,
      tagline,
      country,
    });
  };

  const timezones = useRef(getTimeZones());

  const handleSubmitEdit = handleSubmit(
    async (values) => await updateUser({ ...user, ...values }),
  );

  const isOwnProfile = type === "own";

  return (
    <Flex
      {...(isOwnProfile
        ? {
            as: "form",
            onSubmit: handleSubmitEdit,
          }
        : {})}
      w="full"
      direction={{ base: "column", md: "row" }}
      alignContent="start"
      gap={5}
      {...rest}
    >
      <Flex
        w={{ base: "full", md: "280px" }}
        direction="column"
        alignItems="start"
      >
        <Text fontSize={{ base: "18px", md: "14px" }} fontWeight="bold">
          {isOwnProfile ? "Profile" : "Personal information"}
        </Text>
        <Text fontSize="14px" color="gray.600">
          {isOwnProfile
            ? "Update your portfolio and bio."
            : `See ${type}’s information`}
        </Text>
      </Flex>
      <Flex
        flex={1}
        direction="column"
        gap={6}
        px={4}
        py={5}
        bgColor="white"
        borderRadius="lg"
        boxShadow="0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px 0px rgba(10, 13, 18, 0.06)"
      >
        {isOwnProfile && (
          <FormControl
            isDisabled={isOwnProfile}
            display="flex"
            alignItems="start"
            gap={2}
          >
            <Switch defaultChecked colorScheme="brand" />
            <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
              <Text fontSize="14px" fontWeight="medium" color="gray.700">
                Visible account
              </Text>
              <Text fontSize="14px" fontWeight="normal" color="gray.600">
                I’m available for accepting new mentees
              </Text>
            </FormLabel>
          </FormControl>
        )}
        {isOwnProfile && (
          <FormControl
            id="external_link"
            isInvalid={Boolean(errors.external_link)}
          >
            <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
              Website
            </FormLabel>
            <InputGroup size="sm">
              <InputLeftAddon bgColor="white" color="gray.500">
                https://
              </InputLeftAddon>
              <Input
                type="url"
                isDisabled={!isOwnProfile || isSubmitting}
                {...(isOwnProfile
                  ? {
                      placeholder: "www.example.com",
                      ...register("external_link", {
                        minLength: {
                          value: 20,
                          message: "Tagline should be at least 20 characters",
                        },
                      }),
                    }
                  : {
                      value: externalLink || "",
                    })}
              />
              {errors.external_link && (
                <FormErrorMessage>
                  {errors.external_link.message}
                </FormErrorMessage>
              )}
            </InputGroup>
          </FormControl>
        )}
        <FormControl
          isDisabled
          // isDisabled={isOwnProfile}
          isReadOnly={!isOwnProfile}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Mentorship Category
          </FormLabel>
          <Select
            defaultValue="Data Science"
            iconColor="gray.500"
            icon={<ArrowDown2 />}
            isDisabled
            // isDisabled={!isOwnProfile || isSubmitting}
            isReadOnly={!isOwnProfile}
          >
            <option value="Data Science">Data Science</option>
            <option value="Software Engineering">Software Engineering</option>
            <option value="Product Management">Product Management</option>
            <option value="UX Design">UX Design</option>
          </Select>
        </FormControl>
        <FormControl
          id="tagline"
          isInvalid={Boolean(errors.tagline)}
          isReadOnly={!isOwnProfile}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Tagline
          </FormLabel>
          <Input
            type="text"
            isDisabled={!isOwnProfile || isSubmitting}
            {...(isOwnProfile
              ? {
                  placeholder: "Sr. Data Scientist at Google",
                  ...register("tagline", {
                    minLength: {
                      value: 20,
                      message: "Tagline should be at least 20 characters",
                    },
                  }),
                }
              : {
                  value: tagline || "",
                })}
          />
          {errors.tagline && (
            <FormErrorMessage>{errors.tagline.message}</FormErrorMessage>
          )}
          {/* <FormHelperText>20 characters left</FormHelperText> */}
        </FormControl>
        <FormControl
          isDisabled
          isReadOnly
          // isDisabled={isOwnProfile}
          // isReadOnly={!isOwnProfile}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Bio
          </FormLabel>
          <Textarea rows={3} fontSize="14px" defaultValue="" />
          {/* {isOwnProfile && <FormHelperText>200 characters left</FormHelperText>} */}
        </FormControl>
        <FormSelect
          isReadOnly={!isOwnProfile}
          isRequired={isOwnProfile}
          label="Country"
          isInvalid={Boolean(errors.country)}
          helperText={errors.country?.message}
          placeholder="Select your country"
          icon={<ArrowDown2 />}
          iconColor="gray.500"
          className="emoji"
          isDisabled={!isOwnProfile || isSubmitting}
          defaultValue={country}
          {...(isOwnProfile
            ? register("country", {
                required: {
                  value: true,
                  message: "Please select your country",
                },
                validate: (value) => {
                  if (!value.match(/^[a-z]{2}$/)) {
                    return "Invalid country code";
                  }
                  return true;
                },
              })
            : {})}
        >
          <option value="us">🇺🇸 United States</option>
          <option value="ca">🇨🇦 Canada</option>
          <option value="gb">🇬🇧 United Kingdom</option>
          <option value="au">🇦🇺 Australia</option>
          <option value="de">🇩🇪 Germany</option>
          <option value="fr">🇫🇷 France</option>
          <option value="jp">🇯🇵 Japan</option>
        </FormSelect>
        {/* <FormControl
          id="country"
          isRequired
          isInvalid={Boolean(errors.country)}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Country
          </FormLabel>
          <Select
            placeholder="Select your country"
            value={country || ""}
            icon={<ArrowDown2 />}
            iconColor="gray.500"
            className="emoji"
            isDisabled={!isOwnProfile || isSubmitting}
          ></Select>
          {errors.country && (
            <FormErrorMessage>{errors.country.message}</FormErrorMessage>
          )}
        </FormControl> */}
        <FormControl
          isDisabled
          // isDisabled={isOwnProfile}
          isReadOnly={!isOwnProfile}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Speaking Languages
          </FormLabel>
          <Select
            icon={<ArrowDown2 />}
            iconColor="gray.500"
            className="emoji"
            isDisabled
            // isDisabled={!isOwnProfile || isSubmitting}
            isReadOnly={!isOwnProfile}
            defaultValue="en-US"
          >
            <option value="en-US">🇺🇸 English (US)</option>
            <option value="en-GB">🇬🇧 English (UK)</option>
            <option value="es">🇪🇸 Spanish</option>
            <option value="fr">🇫🇷 French</option>
            <option value="de">🇩🇪 German</option>
            <option value="zh">🇨🇳 Chinese</option>
            <option value="ja">🇯🇵 Japanese</option>
          </Select>
        </FormControl>
        <FormControl
          isDisabled
          // isDisabled={isOwnProfile}
          isReadOnly={!isOwnProfile}
        >
          <FormLabel fontSize="14px" fontWeight="medium" color="gray.700">
            Timezone
          </FormLabel>
          <Select
            icon={<ArrowDown2 />}
            iconColor="gray.500"
            isDisabled
            // isDisabled={!isOwnProfile || isSubmitting}
            isReadOnly={!isOwnProfile}
            defaultValue="America/New_York"
          >
            {timezones.current?.map(({ value, emoji, name, offset }) => (
              <option key={value} value={value}>
                {emoji} {name} {offset}
              </option>
            ))}
          </Select>
        </FormControl>
        {isDirty && (
          <Flex justifyContent="end" gap={4}>
            <Button onClick={handleCancel} variant="outline" colorScheme="red">
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting} colorScheme="brand">
              Save changes
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
